import React, { useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import { CompanyContext } from "../../templates/context/CompanyContext";

highchartsMore(Highcharts);

export default function AreaRangeChart({ xLabels, ranges, lines, colors, unit, lastHistoricMonthLabel, legendOrderLeft }) {
  const { userInfo } = useContext(CompanyContext);

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash) {
      const sectionId = urlHash.substring(1);

      setTimeout(() => {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, []);

  const integerRanges = ranges.map(range => {
    return range.map(r => {
      if (typeof r === "number") return Math.ceil(r);
      else return r;
    });
  });

  Highcharts.setOptions({
    colors: colors,
  });

  const linesData = lines.map(line => {
    return {
      name: line.label,
      data: line.values,
      zIndex: 1,
      lineColor: line.color,
      dashStyle: line.lineStyle,
      visible: line.visible,
      marker: {
        enabled: line.marker ? true : false,
        fillColor: "white",
        lineWidth: 2,
        lineColor: line.color,
      },
    };
  });

  function formatYAxis() {
    var max = this.axis.max;
    var value = this.value;

    if (value === 0) {
      return 0;
    } else if (max > 1000000000) {
      return value / 1000000000 + "bi";
    } else if (max > 1000000) {
      return value / 1000000 + "M";
    } else if (max > 1000) {
      return value / 1000 + "k";
    } else {
      return value;
    }
  }

  function formatTooltip(tooltip) {
    const month = userInfo.company.id === 4 ? "Jan/24" : lastHistoricMonthLabel;
    if (this.points[0].x === month) {
      const seriesSentences = this.points.map(function (point) {
        if (point.series.name === `Demanda do Cliente`) {
          return (
            "<br/>" +
            `<span style=\"color:${point.color}"\>●</span> ` +
            point.series.name +
            ": <b>" +
            `${unit === "items" ? "" : "R$"}` +
            point.point.options.low +
            "</b> - <b>" +
            `${unit === "items" ? "" : "R$"}` +
            point.point.options.high +
            "</b><br/>"
          );
        } else if (point.series.name === "Plano Atual" || point.series.name === "Forecast IA") {
          return "";
        }
        return (
          "<br/>" +
          `<span style=\"color:${point.color}"\>●</span> ` +
          point.series.name +
          ": <b>" +
          `${unit === "items" ? "" : "R$"}` +
          point.y +
          "</b>"
        );
      });
      return ["<br/>" + this.points[0].x].concat(seriesSentences);
    } else {
      return tooltip.defaultFormatter.call(this, tooltip);
    }
  }

  const options = {
    chart: {
      height: "70%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xLabels,
    },
    legend: legendOrderLeft
      ? {
          align: "left",
          itemDistance: 5,
          padding: 0,
          margins: 0,
        }
      : {},
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: formatYAxis,
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valuePrefix: unit === "items" ? "" : "R$",
      formatter: formatTooltip,
    },
    scrollbar: {
      enabled: true,
    },
    series: [
      {
        name: "Demanda do Cliente",
        data: integerRanges,
        type: "arearange",
        lineWidth: 0,
        color: "#0fd17c65",
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        visible: true,
      },
      ...linesData,
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
