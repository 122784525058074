import React, { useContext, useRef } from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../../templates/context/CompanyContext";

import { capitalizeFirst } from "../../../../utils/utils";

function HierarchyFilterBox({ productIds, level, selectedProductIds, selectedLevels, setSelectedLevels }) {
  const { hierarchy, allProducts } = useContext(CompanyContext);
  const scrollableDivRef = useRef(null);

  const hierarchyName = hierarchy.labels[level];

  const products = allProducts.filter(product => productIds.includes(product.productId));

  const optionNames = [...new Set(products.map(product => product[level]))];

  const options = optionNames.map(option => {
    const productsWithThatLevel = products.filter(product => product[level] === option);
    const ids = productsWithThatLevel.map(product => product.productId);
    return {
      name: option,
      ids: ids,
    };
  });

  const alphabeticallyOrderedOptions = options.sort((a, b) => a.name.localeCompare(b.name));

  const possibleOptions = alphabeticallyOrderedOptions.filter(option => isOptionPossible(option.name));

  const disabledOptions = alphabeticallyOrderedOptions.filter(option => !isOptionPossible(option.name));

  const orderedOptions = possibleOptions.concat(disabledOptions);

  function isOptionSelected(optionName) {
    return selectedLevels[level] && selectedLevels[level].includes(optionName);
  }

  function onClick(option) {
    let newSelectedLevel = selectedLevels[level] ? [...selectedLevels[level]] : [];

    if (isOptionSelected(option.name)) {
      const indexOfSelectedHierarchy = newSelectedLevel.indexOf(option.name);
      newSelectedLevel.splice(indexOfSelectedHierarchy, 1);
    } else newSelectedLevel.push(option.name);

    setSelectedLevels({
      ...selectedLevels,
      [level]: newSelectedLevel,
    });

    scrollToTop();
  }

  function isOptionPossible(optionName) {
    const selectedProductsIdsNames = allProducts.filter(product => selectedProductIds.includes(product.productId));
    return selectedProductsIdsNames.some(product => product[level] === optionName);
  }

  function scrollToTop() {
    scrollableDivRef.current.scrollTo({ top: 0 });
  }

  return (
    <div className="d-flex flex-column justify-content-between width-fit-content border-radius-8px box-shadow-black-2-0-11-0 mb-3 me-2 px-3 pb-2">
      <Text weight="bold">{hierarchyName}</Text>
      <div className="d-flex justify-content-center pb-1">
        <div className="width-180px border-top-light-grey-solid-1px me-2"></div>
      </div>
      <div className="height-175px width-200px overflow-scroll pe-2" ref={scrollableDivRef}>
        {orderedOptions.map((option, index) => (
          <Button
            label={capitalizeFirst(option.name)}
            onClick={() => onClick(option)}
            type={isOptionSelected(option.name) ? "outlined" : "ghost"}
            className="box-shadow-unset box-shadow-unset-on-button text-align-start-on-button text-align-start"
            disabled={!isOptionPossible(option.name)}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default HierarchyFilterBox;
