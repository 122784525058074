import React, { memo, useContext, useState } from "react";
import { Heading, Text, Select, SelectItem, Button } from "@aidron/aidron-ds";
import { i18n } from "../../../../translate/i18n";
import { getMonthName } from "../../../../utils/utils";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import SeasonsTable from "./SeasonsTable/SeasonsTable";
import SeasonImport from "./SeasonImport/SeasonImport";

function SeasonRegistration({
  newSeasons,
  setNewSeasons,
  createBlankNewSeason,
  onImportModalClick,
  onSaveSeasons,
}) {
  const { allSeasons } = useContext(CompanyContext);
  const [showAllSeasons, setShowAllSeasons] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  function editNewSeasons(index, nameOrMonths, value) {
    const modifiedNewSeasons = [...newSeasons];
    modifiedNewSeasons[index] = {
      ...modifiedNewSeasons[index],
      [nameOrMonths]: value,
    };
    setNewSeasons(modifiedNewSeasons);
  }

  function onRemoveClick(index) {
    let modifiedNewSeasons;
    if (newSeasons.length === 1) modifiedNewSeasons = [createBlankNewSeason()];
    else {
      modifiedNewSeasons = [...newSeasons];
      modifiedNewSeasons.splice(index, 1);
    }
    setNewSeasons(modifiedNewSeasons);
  }

  return (
    <>
      <Heading size="sm" className="mt-5">
        {i18n.t("seasonsRegistration")}
      </Heading>
      <div className="ms-3 mt-2">
        {newSeasons.map((newSeason, index) => (
          <div className="d-flex p-3" key={index}>
            <div className="d-flex flex-column ms-2 me-2">
              <Text weight="bold" className="font-size-14px">
                {i18n.t("season")}
              </Text>
              <input
                type="text"
                value={newSeasons[index].name}
                onChange={(e) => editNewSeasons(index, "name", e.target.value)}
                className="textfield-box-shadow-on-hover height-30px gray-border border-radius-5px font-weight-bold width-150px"
              />
            </div>
            <div
              className={`height-30px-on-select-component margin-top-11px-on-select-component margin-top-32px ms-2 ${
                newSeasons[index].name && !newSeasons[index].months.length
                  ? "red-border-on-select-component"
                  : ""
              }`}
            >
              <Select
                multiselect
                readOnly
                label={`${i18n.t("months")}:`}
                handleChange={(e) => editNewSeasons(index, "months", e)}
                selected={newSeasons[index].months}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (monthNumber, index) => (
                    <SelectItem value={monthNumber} key={index}>
                      {getMonthName(monthNumber)}
                    </SelectItem>
                  )
                )}
              </Select>
              {newSeasons[index].name && !newSeasons[index].months.length ? (
                <Text className="text-danger" size="xs">
                  {i18n.t("selectAtLeastOne")}
                </Text>
              ) : (
                <></>
              )}
            </div>
            <div className="ms-3 margin-top-28px">
              <Button
                label={i18n.t("remove")}
                onClick={() => onRemoveClick(index)}
                type="outlined"
              />
            </div>
          </div>
        ))}
        <Button
          label={i18n.t("addAnotherFemale")}
          onClick={() => {
            setNewSeasons([...newSeasons, createBlankNewSeason()]);
          }}
          type="outlined"
          className="mt-2 mb-3"
        />
        <Button
          label={i18n.t("bulkImport") + "..."}
          type="outlined"
          onClick={() => onImportModalClick(setShowImportModal)}
          className="mt-2 mb-3"
        />
        <Button
          label={
            showAllSeasons
              ? i18n.t("hideSeasons")
              : i18n.t("showRegisteredSeasons")
          }
          onClick={() => setShowAllSeasons(!showAllSeasons)}
          type="outlined"
          className="my-2"
        />
        {showAllSeasons && <SeasonsTable seasons={allSeasons} />}
      </div>
      {showImportModal && (
        <SeasonImport
          setShowImportModal={setShowImportModal}
          onSaveSeasons={onSaveSeasons}
        />
      )}
    </>
  );
}

export default memo(SeasonRegistration);
