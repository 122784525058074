import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const SETTINGS_URL = `${API_URL}/users`;

export async function getSettings(token) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(SETTINGS_URL, { headers });
  return response.data;
}

export async function updateSettings(users, token) {
  const headers = { authorization: token };
  const response = await axios.patch(SETTINGS_URL, users, { headers });
  return response.data;
}
