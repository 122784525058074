import styled from "styled-components";

import { Heading } from "@aidron/aidron-ds";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Legend = styled.div`
  display: flex;
`;

const OrangeHeading = styled(Heading)`
  color: #f26f21;
  margin-right: ${({ marginRight }) => (marginRight ? "8px !important" : "0")};
`;

export { Wrapper, Legend, OrangeHeading };
