import React from "react";
import { Progress } from "@aidron/aidron-ds";

export default function LoadingProgress({ condition, children }) {
  return condition ? (
    children
  ) : (
    <div className="d-flex justify-content-center align-items-center width-40px-on-progress">
      <Progress circular />
    </div>
  );
}
