import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-content: center;
  border-bottom: ${({ active }) => (active ? "0.3rem solid #fafafa !important" : "")};

  .menu ul.dropdown {
    transform: unset !important;
  }

  .menu > ul.dropdown.bottom {
    left: 20% !important;
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: white;
  margin: 0 1.5rem;

  p {
    font-size: 16px;
  }
`;

const MenuItemWrapper = styled.div`
  .menu-item .menu-item-button {
    cursor: default !important;
    color: gray !important;
  }

  .menu-item .menu-item-button:hover p {
    color: white !important;
  }
`;

const InnerMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export { Container, Button, MenuItemWrapper, InnerMenuItem, TagWrapper };
