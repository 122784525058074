import React from "react";
import { Text, Card } from "@aidron/aidron-ds";
import { getMonthName } from "../../utils/utils";

import * as S from "./ConsolidatedTable.style";

import PercentBodyCell from "../PercentBodyCell/PercentBodyCell";
import BodyCell from "../../components/BodyCell/BodyCell";
import CardHeading from "../../pages/PlanVsForecast/ConsolidatedView/CardHeading/CardHeading";

export default function ConsolidatedTable({ predictions, columns, title }) {
  function getValue(column, indexName) {
    const targetMonthYearObject = predictions.find(
      prediction => prediction.month === column.month && prediction.year === column.year
    );
    return targetMonthYearObject[indexName];
  }

  return (
    <Card>
      {title && <CardHeading heading={title} />}

      <S.OuterContainer>
        <S.ScrollContainer>
          <S.Table className="table table-striped table-hover table-inner-div">
            <thead>
              <tr>
                <th>
                  <Text>Indicador</Text>
                </th>
                {predictions.map((column, index) => (
                  <S.Th key={index}>
                    <Text>{`${getMonthName(column.month.toString())} ${column.year}`}</Text>
                  </S.Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {columns.map((column, index) => (
                <tr key={index}>
                  <td>
                    <Text>{column.label}</Text>
                  </td>
                  {predictions.map((prediction, index) => (
                    <S.Td key={index}>
                      {column.type === "prediction" && (
                        <BodyCell prediction={getValue(prediction, column.name)} format={prediction.unit} />
                      )}
                      {column.type === "dif%" && <PercentBodyCell prediction={getValue(prediction, column.name)} showFlag />}
                    </S.Td>
                  ))}
                </tr>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollContainer>
      </S.OuterContainer>
    </Card>
  );
}
