import React, { useState } from "react";
import { Card, Text, Button } from "@aidron/aidron-ds";

import { toNDecimalsString } from "../../../utils/utils";
import { updateOpportunities } from "../../../services/OpportunityService";

import * as S from "../CheckoutSummary/CheckoutSummary.style";

import ProductHierarchyHeading from "../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import TextAndLabelView from "../../Opportunity/ProductListItems/ListItem/TextAndLabelView/TextAndLabelView";
import ModalTemplate from "../../../components/ModalTemplate/ModalTemplate";
import OpportunityChart from "../../Opportunity/ProductListItems/ListItem/OpportunityAccordion/OpportunityChart/OpportunityChart";
import CustomHeading from "../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";

export default function ProductSummary({ opportunity, fetchData }) {
  const { id, productId, percent, text, value } = opportunity;

  const [openModal, setOpenModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  async function onConcludedClick() {
    const token = localStorage.getItem("token");
    const updatedOpportunity = { id: id, status: "finished", finishedDate: new Date() };
    const result = await updateOpportunities(token, [updatedOpportunity]);

    if (result.status === 200) {
      setConfirmationMessage("Oportunidade finalizada!");
    } else {
      setConfirmationMessage("Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.");
    }
  }

  function onConfirmationModalClose() {
    setConfirmationMessage("");
    setOpenModal(false);
    fetchData();
  }

  return (
    <>
      <Card style={{ backgroundColor: "#fad6bf" }}>
        <S.Wrapper smallGap>
          <S.Section border>
            <S.CustomHeading size="md" color="grey">
              RESUMO DO CHECKOUT
            </S.CustomHeading>
          </S.Section>

          <S.Section noPaddingBottom>
            <div className="d-flex align-items-center justify-content-center">
              <ProductHierarchyHeading productId={productId} useMockedStores />
            </div>
          </S.Section>

          <S.Section border>
            <TextAndLabelView
              label="Aumento de Vendas da Loja"
              labelSize="sm"
              heading={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
            />
          </S.Section>

          <S.Section>
            <S.ChartContainer>
              <OpportunityChart opportunity={opportunity} hideProductHierarchy />
            </S.ChartContainer>
          </S.Section>

          <S.Section>
            <div className="d-flex align-items-center justify-content-center text-center">
              <Text weight="bold">{text}</Text>
            </div>
          </S.Section>

          <div className="d-flex align-items-center justify-content-center">
            <S.CustomHeading size="md" color="grey" margin>
              TOTAL
            </S.CustomHeading>
            <S.CustomHeading size="lg" color="orange">{`R$ ${toNDecimalsString(value, 0)}`}</S.CustomHeading>
          </div>

          <S.Section>
            <div className="d-flex justify-content-end">
              <Button onClick={() => setOpenModal(true)} label="Finalizar Ação" size="lg" />
            </div>
          </S.Section>
        </S.Wrapper>
      </Card>

      {openModal && (
        <ModalTemplate width="200px" closingFunction={() => setOpenModal(false)} heading="Finalizar Oportunidade?">
          <CustomText textAlign="center">
            Ao clicar em Ok, esta oportunidade será finalizada e marcada como Aplicada no Planejamento atual da empresa!
          </CustomText>
          <div className="d-flex justify-content-end mt-4">
            <Button onClick={() => setOpenModal(false)} label="Cancelar" type="outlined" className="" />
            <Button onClick={onConcludedClick} label="OK" className="mx-3" />
          </div>
        </ModalTemplate>
      )}

      {confirmationMessage && (
        <ModalTemplate width="300px" closingFunction={onConfirmationModalClose} heading=" ">
          <CustomHeading size="sm" margin="1.5rem 0 0 0" textAlign="center">
            {confirmationMessage}
          </CustomHeading>
          <S.ConfirmationButtonContainer>
            <Button label="Ok" onClick={onConfirmationModalClose} />
          </S.ConfirmationButtonContainer>
        </ModalTemplate>
      )}
    </>
  );
}
