import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const ScrollContainer = styled.div`
  overflow: scroll;
`;

const Table = styled.table`
  th,
  td {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }
`;

const Th = styled.th`
  text-align: center;
`;

const Td = styled.td`
  text-align: center;
`;

export { OuterContainer, ScrollContainer, Table, Th, Td };
