import React, { useContext, useState, useEffect } from "react";
import { CompanyContext } from "../../templates/context/CompanyContext";

import { getPredictions } from "../../services/StorePredictionsService";
import { getForecasts } from "../../services/ForecastService";

import ChannelCurrentPlan from "./ChannelCurrentPlan/ChannelCurrentPlan";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import ChannelTable from "./ChannelTable/ChannelTable";
import Default from "../../components/Default/Default";
import WeekColumnFilter from "./WeekColumnFilter/WeekColumnFilter";
import TableOptions from "../../components/TableOptions/TableOptions";
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function DemandVsTarget() {
  const { hierarchy } = useContext(CompanyContext);
  const [selectedForecast, setSelectedForecast] = useState({
    day: 0,
    month: 0,
    year: 0,
  });
  const [allForecasts, setAllForecasts] = useState([]);
  const [historicPredictions, setHistoricPredictions] = useState([]);
  const [predictionData, setPredictionData] = useState([]);
  const [freezeColumns, setFreezeColumns] = useState(true);
  const [selectedHierarchyIndexes, setSelectedHierarchyIndexes] = useState(hierarchy.levels.map((level, index) => index));
  const [selectedHistoricWeekIds, setSelectedHistoricWeekIds] = useState([]);
  const [sortBy, setSortBy] = useState({});
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [storesResults, setStoresResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const resultsPerPage = 10;

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchPredictionData();
  }, [selectedForecast, currentPage, sortBy]);

  useEffect(() => {
    fetchHistoricPredictions();
  }, [selectedHistoricWeekIds, currentPage, sortBy]);

  async function fetchInitialData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "storePredictions");
    const latestForecast = forecasts[forecasts.length - 1];
    const allHistoricForecasts = forecasts.slice(0, -1);

    const initialHistoricForecasts = allHistoricForecasts.slice(-2);

    const latestHistoricForecast = initialHistoricForecasts[initialHistoricForecasts.length - 1];

    const initialSortBy = {
      orderForecastId: latestHistoricForecast.forecastId,
      columnLabel: "realizedItems",
      weeks: [latestHistoricForecast.week],
      year: latestHistoricForecast.year,
      order: "descending",
    };

    setSortBy(initialSortBy);

    setSelectedForecast(latestForecast);
    setAllForecasts(forecasts);

    const initialHistoricForecastIds = initialHistoricForecasts.map(forecast => forecast.forecastId);

    setSelectedHistoricWeekIds(initialHistoricForecastIds);
  }

  async function fetchPredictionData() {
    if (selectedForecast.forecastId && sortBy.orderForecastId) {
      const token = localStorage.getItem("token");

      const result = await getPredictions(
        token,
        [selectedForecast.forecastId],
        selectedProductIds,
        sortBy,
        currentPage,
        resultsPerPage
      );

      setPredictionData(result.results);
      if (productIds.length === 0) {
        setProductIds(result.productIds);
        setSelectedProductIds(result.productIds);
      }
      if (!pagination.lastPage) {
        setPagination({ lastPage: result.lastPage });
      }
    }
  }

  async function fetchHistoricPredictions() {
    if (selectedHistoricWeekIds.length > 0 && sortBy.orderForecastId) {
      const token = localStorage.getItem("token");

      const result = await getPredictions(
        token,
        selectedHistoricWeekIds,
        selectedProductIds,
        sortBy,
        currentPage,
        resultsPerPage
      );

      setStoresResults(result.storeIndexes);
      setHistoricPredictions(result.results);
    }
  }

  return (
    <Default>
      <PageHeading>Demanda x Meta Canais</PageHeading>
      <ChannelCurrentPlan sortBy={sortBy} />
      <SectionHeader
        heading="Quebra de Produto por Loja"
        subtitles={[`Último forecast gerado em: ${selectedForecast.day}/${selectedForecast.month}/${selectedForecast.year}`]}
      />
      <TableOptions
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
        freezeColumns={freezeColumns}
        setFreezeColumns={setFreezeColumns}
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        renderHistoricFilter={({ closeModal }) => (
          <WeekColumnFilter
            allForecasts={allForecasts}
            onConcludedClick={closeModal}
            setSelectedHistoricWeekIds={setSelectedHistoricWeekIds}
            selectedHistoricWeekIds={selectedHistoricWeekIds}
          />
        )}
      />
      <ChannelTable
        freezeColumns={freezeColumns}
        sortRowsBy={sortBy}
        setSortRowsBy={setSortBy}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        selectedHistoricWeekIds={selectedHistoricWeekIds}
        predictionData={predictionData}
        historicPredictions={historicPredictions}
        selectedForecast={selectedForecast}
        selectedProductIds={selectedProductIds}
        currentPage={currentPage}
        allForecasts={allForecasts}
        storesResults={storesResults}
      />
      <PaginationButtons currentPage={currentPage} setCurrentPage={setCurrentPage} pagination={pagination} />
    </Default>
  );
}
