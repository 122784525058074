import React, { useState, useEffect, useContext } from "react";

import { ForecastContext } from "../../../../../templates/context/ForecastContext";
import { getPredictionsByProduct } from "../../../../../services/PredictionsService";

import ButtonWithModal from "../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductAccuracyChart from "../../../../../components/ProductAccuracyChart/ProductAccuracyChart";

export default function AccuracyChartButton({ productId }) {
  const { selectedForecast } = useContext(ForecastContext);
  const [productPredictions, setProductPredictions] = useState([]);

  const unit = "items";

  useEffect(() => {
    const token = localStorage.getItem("token");
    getPredictionsByProduct(token, [productId]).then(res => {
      setProductPredictions(res);
    });
  }, []);

  return (
    <ButtonWithModal
      label="Análise de Assertividade"
      icon="arrow-up-right"
      type="outlined"
      modalHeading="Análise de Assertividade"
      modalWidth="700px"
      renderModalContent={() => (
        <div className="mx-5">
          <ProductAccuracyChart
            productId={productId}
            unit={unit}
            currentMonth={selectedForecast}
            productPredictions={productPredictions}
          />
        </div>
      )}
    />
  );
}
