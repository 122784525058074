import React, { useState, useEffect, useContext } from "react";
import { Card } from "@aidron/aidron-ds";

import { ForecastContext } from "../../../templates/context/ForecastContext";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getConsolidatedPredictions, getPredictions, getProductIds } from "../../../services/PredictionsService";
import { capitalizeFirst, defineMonthColumns } from "../../../utils/utils";
import { consolidatedForecastColumns } from "../../../utils/tableColumns";

import * as S from "./ConsolidatedView.style";

import ConsolidatedViewHeader from "../../../components/ConsolidatedViewHeader/ConsolidatedViewHeader";
import SortSelect from "../../../components/SortSelect/SortSelect";
import ConsolidatedChart from "./ConsolidatedChart/ConsolidatedChart";
import ConsolidatedTable from "../../../components/ConsolidatedTable/ConsolidatedTable";
import IndexesCard from "./IndexesCard/IndexesCard";
import SecondaryChartsSection from "./SecondaryChartsSection/SecondaryChartsSection";
import ButtonWithModal from "../../../components/ButtonWithModal/ButtonWithModal";
import LevelDriversModal from "./LevelDriversModal/LevelDriversModal";

export default function ConsolidatedView({ defineProductFilter }) {
  const { selectedForecast } = useContext(ForecastContext);
  const { allProducts } = useContext(CompanyContext);

  const [consolidatedPredictions, setConsolidatedPredictions] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedValue, setSelectedValue] = useState(1);

  const selected = defineFilterOptions().find(option => option.value === selectedValue);

  const monthColumns = defineMonthColumns(consolidatedPredictions);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchConsolidatedData();
    fetchPredictions();
  }, []);

  async function fetchConsolidatedData() {
    const result = await getConsolidatedPredictions(token, [selectedForecast.forecastId]);
    setConsolidatedPredictions(result[0]);
  }

  async function fetchPredictions() {
    const token = localStorage.getItem("token");
    const forecastId = selectedForecast.forecastId;
    const sortBy = {
      orderForecastId: forecastId,
      order: "any",
      months: [selectedForecast.month],
    };

    const [predictions, productIds] = await Promise.all([
      getPredictions(token, [forecastId]),
      getProductIds(token, selectedForecast.forecastId, sortBy),
    ]);

    setPredictions(predictions.predictions);

    const predictionProductIds = productIds.productIds;
    const products = allProducts.filter(product => predictionProductIds.includes(product.productId));
    setProducts(products);
  }

  function defineFilterOptions() {
    const level0ProductIds = products.map(product => product.productId);
    const filterOptions = [{ displayName: "Empresa", value: 1, level: "level0", productIds: level0ProductIds }];

    const allLevel1Options = products.map(product => product.level1);
    const level1Options = [...new Set(allLevel1Options)];
    level1Options.forEach((option, index) => {
      const levelProductIds = products.filter(product => product.level1 === option).map(product => product.productId);
      filterOptions.push({
        displayName: capitalizeFirst(option),
        value: index + 2,
        level: "level1",
        productIds: levelProductIds,
      });
    });

    return filterOptions;
  }

  return (
    <S.OuterContainer>
      <ConsolidatedViewHeader />
      <Card>
        <S.GroupingContainer>
          <SortSelect
            label="Agrupamento Principal:"
            selected={selected.value}
            options={defineFilterOptions()}
            onChange={setSelectedValue}
          />
          <ButtonWithModal
            label="Impacto na Venda"
            renderModalContent={() => <LevelDriversModal productIds={selected?.productIds} selected={selected} />}
            type="outlined"
            icon="arrow-up-right"
            modalHeading={`Fatores que Impactam a Venda: agrupamento ${capitalizeFirst(selected.displayName)}`}
            modalWidth="700px"
          />
        </S.GroupingContainer>
      </Card>

      <S.CardsWrapper>
        <ConsolidatedChart predictions={consolidatedPredictions} monthColumns={monthColumns} heading="PLANO x FORECAST" />
        <ConsolidatedTable
          predictions={consolidatedPredictions}
          columns={consolidatedForecastColumns()}
          selectedForecast={selectedForecast}
          title="PLANO x FORECAST - DETALHAMENTO MENSAL"
        />
        <IndexesCard selected={selected} products={products} />
      </S.CardsWrapper>

      <SecondaryChartsSection
        selected={selected}
        predictions={predictions}
        products={products}
        defineProductFilter={defineProductFilter}
      />
    </S.OuterContainer>
  );
}
