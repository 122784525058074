import React from "react";
import { Button } from "@aidron/aidron-ds";

function TableScrollButtons({
  scrollToSide,
  isScrollingDivAtBeginning,
  isScrollingDivAtEnd,
}) {
  return (
    <div className="d-flex justify-content-between mb-3">
      <Button
        label=""
        type="outlined"
        icon="arrow-left"
        onClick={() => scrollToSide("left")}
        disabled={!isScrollingDivAtBeginning()}
        className="ms-2"
      />
      <Button
        label=""
        type="outlined"
        icon="arrow-right"
        onClick={() => scrollToSide("right")}
        disabled={isScrollingDivAtEnd()}
        className="me-3"
      />
    </div>
  );
}

export default TableScrollButtons;
