import React from "react";
import { Card } from "@aidron/aidron-ds";

import * as S from "./InnerCard.style";

import LabelAndNumberView from "./LabelAndNumberView/LabelAndNumberView";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function InnerCard({ title, data }) {
  return (
    <S.Wrapper>
      <CustomHeading size="sm" color="mediumGrey">
        {title}
      </CustomHeading>
      <Card style={{ padding: "25px 20px", height: "100%" }}>
        <S.InnerBoxWrapper>
          {data.map((d, index) => (
            <LabelAndNumberView
              heading={d.heading}
              number={d.number}
              numberText={d.numberText}
              text={d.text}
              link={d.link}
              key={index}
            />
          ))}
        </S.InnerBoxWrapper>
      </Card>
    </S.Wrapper>
  );
}
