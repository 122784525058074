import React, { memo, useEffect } from "react";
import * as XLSX from "xlsx";
import { Button, Text } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";
import ModalTemplate from "../../../components/ModalTemplate/ModalTemplate";

function ImportModal({
  setShowImportModal,
  onSave,
  downloadTemplate,
  readImportFile,
  handleFileError,
  newDataToImport,
  setNewDataToImport,
  uploadedFile,
  setUploadedFile,
  showErrorModal,
  setShowErrorModal,
  children,
}) {
  useEffect(() => {
    setNewDataToImport([]);
    if (uploadedFile) handleFileChange(uploadedFile);
  }, [uploadedFile]);

  function handleUpload(e) {
    setUploadedFile(e.target.files[0]);
  }

  function handleFileChange(file) {
    const reader = new FileReader();

    if (file) {
      reader.onload = (e) => {
        const fileContent = e.target.result;
        const workbook = XLSX.read(fileContent, { type: "binary" });

        const importedData = readImportFile(workbook);

        if (importedData?.length) setNewDataToImport(importedData);
        else handleFileError();
      };

      reader.readAsBinaryString(file);
    }
  }

  return (
    <>
      <ModalTemplate
        width="700px"
        heading={i18n.t("seasonsBulkImport")}
        closingFunction={() => setShowImportModal(false)}
      >
        <div className="mb-4 mt-4">
          <Text weight="bold">{i18n.t("step")} 1</Text>
          <Text>{i18n.t("importModal.step1Instruction")}</Text>
          <Button
            label="Baixar template"
            type="outlined"
            onClick={downloadTemplate}
          />
        </div>
        <div className="mb-4">
          <Text weight="bold">{i18n.t("step")} 2</Text>
          <Text>{i18n.t("importModal.step2Instruction")}</Text>
          <div className="d-flex">
            <label htmlFor="file-upload" className="aidron-ds-button-styles">
              {i18n.t("choseFile")}
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={handleUpload}
              className="d-none"
              key={uploadedFile}
            />
            {newDataToImport?.length ? (
              <Text className="ms-3 green-color">{i18n.t("fileUploaded")}</Text>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Text
          weight="bold"
          className={newDataToImport?.length ? "" : "light-gray-text"}
        >
          {i18n.t("step")} 3
        </Text>
        <Text className={newDataToImport?.length ? "" : "light-gray-text"}>
          {i18n.t("importModal.step3Instruction")}
        </Text>
        {newDataToImport?.length ? children : <></>}
        <div className="d-flex justify-content-end">
          <Button
            label={i18n.t("confirm")}
            disabled={newDataToImport?.length ? false : true}
            size="lg"
            onClick={() => onSave(newDataToImport)}
          />
        </div>
      </ModalTemplate>
      {showErrorModal && (
        <ModalTemplate
          width="500px"
          heading={i18n.t("errorModal.heading")}
          closingFunction={() => setShowErrorModal(false)}
        >
          <Text>{i18n.t("importModal.error")}</Text>
        </ModalTemplate>
      )}
    </>
  );
}

export default memo(ImportModal);
