import React from "react";
import { Text, Card, Heading } from "@aidron/aidron-ds";
import { getMonthName } from "../../utils/utils";

import PercentBodyCell from "../PercentBodyCell/PercentBodyCell";
import BodyCell from "../../components/BodyCell/BodyCell";

export default function ConsolidatedPredictionsTable({ consolidatedPredictions, columns, title }) {
  function getValue(column, indexName) {
    const targetMonthYearObject = consolidatedPredictions.find(
      prediction => prediction.month === column.month && prediction.year === column.year
    );
    return targetMonthYearObject[indexName];
  }

  return (
    <Card style={{ justifyContent: "center", width: "60%" }}>
      <div className="d-flex justify-content-center mb-2">{title ? <Heading size="md">{title}</Heading> : <></>}</div>
      <div className="d-flex justify-content-center">
        <div className="max-height-600px overflow-x-scroll max-width-100percent narrow-scrollbar-container">
          <table className="table table-striped table-hover table-inner-div padding-040rem-on-th-and-td">
            <thead>
              <tr>
                <th>
                  <Text>Indicador</Text>
                </th>
                {consolidatedPredictions.map((column, index) => (
                  <th key={index} className="text-center">
                    <Text>{`${getMonthName(column.month.toString())} ${column.year}`}</Text>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {consolidatedPredictions.length ? (
                columns.map((column, index) => (
                  <tr key={index}>
                    <td>
                      <Text>{column.label}</Text>
                    </td>
                    {consolidatedPredictions.map((prediction, i) => (
                      <td key={i} className="text-center">
                        {column.type === "prediction" && (
                          <BodyCell prediction={getValue(prediction, column.name)} format={prediction.unit} />
                        )}
                        {column.type === "dif%" && <PercentBodyCell prediction={getValue(prediction, column.name)} showFlag />}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}
