import React, { memo, useContext, useState } from "react";
import { Heading, Text, Select, SelectItem, Button } from "@aidron/aidron-ds";
import { i18n } from "../../../../translate/i18n";
import { CompanyContext } from "../../../../templates/context/CompanyContext";

import { capitalizeFirst } from "../../../../utils/utils";

import ProductsTable from "./ProductsTable/ProductsTable";
import ProductsImport from "./ProductsImport/ProductsImport";

function ProductRegistration({
  newProducts,
  setNewProducts,
  createBlankNewProduct,
  onImportModalClick,
  onSaveProducts,
}) {
  const { allProducts, hierarchy, allSeasons } = useContext(CompanyContext);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  function editNewProducts(id, key, inputtedText) {
    const modifiedNewProducts = [...newProducts];
    modifiedNewProducts[id] = {
      ...modifiedNewProducts[id],
      [key]: inputtedText,
    };
    setNewProducts(modifiedNewProducts);
  }

  function onRemoveClick(index) {
    let modifiedNewProducts;
    if (newProducts.length === 1)
      modifiedNewProducts = [createBlankNewProduct()];
    else {
      modifiedNewProducts = [...newProducts];
      modifiedNewProducts.splice(index, 1);
    }
    setNewProducts(modifiedNewProducts);
  }

  function defineRedBorder(index, level) {
    const newProductObject = newProducts[index];
    const isAnyFieldFilled = Object.values(newProductObject).some(
      (inputtedText) => inputtedText !== ""
    );

    if (isAnyFieldFilled && newProductObject[level] === "") return true;
    else return false;
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <Heading size="sm" className="mt-5">
        {i18n.t("productRegistration")}
      </Heading>
      <div className="ms-3 mt-2">
        {newProducts.map((number, index) => (
          <div
            className="d-flex p-3"
            key={index}
            style={{ overflowX: "scroll", overflowY: "visible" }}
          >
            {hierarchy.levels.map((level, i) => (
              <div className="d-flex flex-column ms-2 me-2" key={i}>
                <Text weight="bold" className="font-size-14px">
                  {hierarchy.labels[level]}
                </Text>
                <input
                  type="text"
                  value={newProducts[index][level]}
                  onChange={(e) =>
                    editNewProducts(index, level, e.target.value)
                  }
                  className={`textfield-box-shadow-on-hover height-30px gray-border border-radius-5px font-weight-bold width-150px ${
                    defineRedBorder(index, level) ? "red-border" : ""
                  }`}
                />
                {defineRedBorder(index, level) ? (
                  <Text className="text-danger mb-0" size="xs">
                    {i18n.t("typeValidName")}
                  </Text>
                ) : (
                  <></>
                )}
              </div>
            ))}
            <div className="height-30px-on-select-component margin-top-11px-on-select-component margin-top-32px ms-2">
              <Select
                readOnly
                label={`${i18n.t("season")}:`}
                selected={newProducts[index].seasonId}
                handleChange={(e) => editNewProducts(index, "seasonId", e)}
              >
                {allSeasons.map((season, index) => (
                  <SelectItem value={season.id} key={index}>
                    {capitalizeFirst(season.name)}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="ms-3 margin-top-28px">
              <Button
                label={i18n.t("remove")}
                onClick={() => onRemoveClick(index)}
                type="outlined"
              />
            </div>
          </div>
        ))}
        <Button
          label={i18n.t("addAnotherMale")}
          onClick={() => {
            setNewProducts([...newProducts, createBlankNewProduct()]);
          }}
          type="outlined"
          className="mt-2 mb-3"
        />
        <Button
          label={i18n.t("bulkImport") + "..."}
          type="outlined"
          onClick={() => onImportModalClick(setShowImportModal)}
          className="mt-2 mb-3"
        />
        <Button
          label={
            showAllProducts
              ? i18n.t("hideProducts")
              : i18n.t("showRegisteredProducts")
          }
          onClick={() => setShowAllProducts(!showAllProducts)}
          type="outlined"
          className="my-2"
        />
        {showAllProducts ? (
          <ProductsTable productsToDisplay={allProducts} showSeasons={true} />
        ) : (
          <></>
        )}
      </div>
      {showImportModal && (
        <ProductsImport
          setShowImportModal={setShowImportModal}
          onSaveProducts={onSaveProducts}
        />
      )}
    </div>
  );
}

export default memo(ProductRegistration);
