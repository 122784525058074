import styled from "styled-components";

const OuterChartContainer = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
`;

const InnerChartContainer = styled.div`
  max-width: 500px;
  margin-top: 1.5rem;
`;

export { OuterChartContainer, InnerChartContainer };
