import React from "react";
import { Button } from "@aidron/aidron-ds";

import * as S from "./ReactivateModal.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function ReactivateModal({ closeModal, reactivateOpportunity }) {
  function onConcludedClick() {
    reactivateOpportunity();
    closeModal();
  }

  return (
    <>
      <CustomText margin="0 0 1.5rem 0">
        Ela voltará a ser listada como Oportunidade e seu valor contará no total ativo.
      </CustomText>
      <S.ButtonsContainer>
        <Button label="Cancelar" type="outlined" onClick={closeModal} />
        <Button label="Ok" onClick={onConcludedClick} />
      </S.ButtonsContainer>
    </>
  );
}
