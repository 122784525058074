import React from "react";

import { getMonthAbbreviation, getProductPrediction } from "../../utils/utils";

import AreaRangeChart from "../AreaRangeChart/AreaRangeChart";

export default function PredictionChart({
  productId,
  historicMonthsToDisplay,
  monthColumns,
  predictionData,
  unit,
  allHistoricPredictions,
  defineFutureLines,
  zeroPredictionIfNull,
}) {
  const lastHistoricMonth = historicMonthsToDisplay[historicMonthsToDisplay.length - 1];

  const lastHistoricMonthLabel = createMonthLabelString(lastHistoricMonth);

  function createMonthLabelString(monthObj) {
    return `${getMonthAbbreviation(monthObj.month)}/${monthObj.year.toString().slice(-2)}`;
  }

  function defineXLabels() {
    const futureMonthsLabels = monthColumns.map(monthColumn => createMonthLabelString(monthColumn));
    const historicMonthsLabels = historicMonthsToDisplay.map(monthColumn => createMonthLabelString(monthColumn));
    return historicMonthsLabels.concat(futureMonthsLabels);
  }

  function defineAllRanges() {
    const pastRanges = definePastRanges();
    const values = monthColumns.map(month => {
      const monthPrediction = predictionData.find(
        prediction => prediction.month === month.month && prediction.year === month.year && prediction.productId === productId
      );
      return [Number(monthPrediction?.confidenceIntervalMin), Number(monthPrediction?.confidenceIntervalMax)];
    });
    return pastRanges.concat(values);
  }

  function definePastRanges() {
    const pastPredictions = historicMonthsToDisplay.map(month => [
      Number(getProductPrediction(month, productId, allHistoricPredictions)?.confidenceIntervalMin),
      Number(getProductPrediction(month, productId, allHistoricPredictions)?.confidenceIntervalMax),
    ]);
    return pastPredictions;
  }

  function getRealizedValue(month) {
    const prediction = getProductPrediction(month, productId, allHistoricPredictions)?.realized;
    if (zeroPredictionIfNull) {
      return prediction !== null ? prediction : 0;
    } else {
      return prediction;
    }
  }

  function defineLines() {
    const realizedValues = historicMonthsToDisplay.map(month => [getRealizedValue(month)]);
    const realized = {
      label: "Histórico de Venda",
      color: "#00BECB",
      values: realizedValues,
      marker: true,
    };
    const lastRealizedValue = realizedValues[realizedValues.length - 1][0];
    const futureMonthsData = defineFutureMonthsData(lastRealizedValue);
    return [realized].concat(futureMonthsData);
  }

  function defineFutureMonthsData(lastRealizedValue) {
    const columnLabels = defineFutureLines();
    const columnColors = ["#BFBFBF", "#f58b4d"];
    return columnLabels.map((columnLabel, i) => {
      let newZeroHistoricMonths = historicMonthsToDisplay.map(month => null);
      let values = monthColumns.map(month => {
        const monthPrediction = predictionData.find(
          prediction => prediction.month === month.month && prediction.year === month.year && prediction.productId === productId
        );
        let columnLabelName = columnLabel.name;
        const value = monthPrediction?.[columnLabelName];
        if (zeroPredictionIfNull) {
          return value ? value : 0;
        } else {
          return value ? value : null;
        }
      });
      values = [
        {
          y: lastRealizedValue,
          marker: { enabled: false, hover: { enabled: false } },
        },
        ...values,
      ];
      newZeroHistoricMonths.pop();
      return {
        label: columnLabel.label,
        color: columnColors[i],
        lineStyle: columnLabel.lineStyle,
        values: newZeroHistoricMonths.concat(values),
        marker: true,
      };
    });
  }

  return (
    <div className="d-flex flex-column">
      <AreaRangeChart
        xLabels={defineXLabels()}
        ranges={defineAllRanges()}
        lines={defineLines()}
        style={{ width: "80%" }}
        colors={["#00BECB", "#BFBFBF", "#f58b4d", "#00BECB"]}
        unit={unit}
        lastHistoricMonthLabel={lastHistoricMonthLabel}
        legendOrderLeft="full"
      />
    </div>
  );
}
