import styled from "styled-components";

const ChartContainer = styled.div`
  margin: 10px 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export { ChartContainer, ButtonContainer };
