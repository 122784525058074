import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./ListItem.style";
import * as OpportunityStyled from "../../../../pages/Opportunity/ProductListItems/ListItem/ListItem.style";

import ProductHierarchyHeading from "../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import TextAndLabelView from "../../../Opportunity/ProductListItems/ListItem/TextAndLabelView/TextAndLabelView";
import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import ActionModal from "../../../Opportunity/ProductListItems/ListItem/ActionModal/ActionModal";
import ModalTemplate from "../../../../components/ModalTemplate/ModalTemplate";

export default function ListItem({ opportunity, onItemClick, selectedId, fetchData }) {
  const { id, productId, value, type1, status } = opportunity;

  const [openModal, setOpenModal] = useState(false);

  const typeDetails = new Map([
    [
      "sale",
      {
        title: "Venda Adicional",
        icon: "trending-up",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro esta abaixo do centro da demanda do cliente (a demanda do cliente por este produto pode ser observada em verde no gráfico). Também traremos casos em que seu histórico recente de vendas ficou abaixo ou próximo do limite mínimo da demanda por este produto.",
        percentLabel: "Aumento Esperado da Venda",
        icon: <OpportunityStyled.SaleIcon />,
      },
    ],
    [
      "stock",
      {
        title: "Otimização de Estoque",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro está acima do limite máximo da demanda por este produto. No gráfico, este limite da demanda pode ser observado em verde.",
        percentLabel: "Redução da Compra Futura",
        icon: <OpportunityStyled.StockIcon />,
      },
    ],
  ]);

  const opportunityDetails = typeDetails.get(type1);

  function onButtonClick(e) {
    e.stopPropagation();
    setOpenModal(true);
  }

  return (
    <>
      <CustomCard
        onClick={() => onItemClick(id)}
        cursor="pointer"
        margin="0 0 20px 0"
        backgroundColor={id === selectedId ? "#fad6bf" : ""}
        greyBackgroundOnHover
      >
        <S.Container>
          <S.ItemImage border>
            {opportunityDetails.icon}
            <ProductHierarchyHeading productId={productId} useMockedStores />
          </S.ItemImage>

          <S.Item textAlign border>
            <TextAndLabelView label="Oportunidade" text={opportunityDetails.title} />
          </S.Item>

          <S.Item textAlign>
            <TextAndLabelView label="Tamanho da Oportunidade" heading={`R$ ${toNDecimalsString(value, 0)}`} />
          </S.Item>

          <S.Item alignItems="end" cursor="default">
            <Button icon="x" type="outlined" onClick={e => onButtonClick(e)} />
          </S.Item>
        </S.Container>
      </CustomCard>

      {openModal && (
        <ModalTemplate width="650px" closingFunction={() => setOpenModal(false)} heading=" ">
          <ActionModal onClose={() => setOpenModal(false)} opportunityId={id} fetchData={fetchData} status={status} />
        </ModalTemplate>
      )}
    </>
  );
}
