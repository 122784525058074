import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
`;

const ConfirmationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

export { ButtonWrapper, ConfirmationButtonContainer };
