import { capitalizeFirst, sumColumn } from "./utils";

export const forecastColumns = () => {
  return [
    {
      label: "Part %",
      name: "participationInAidron",
      fullLabel: "Participação %",
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: "Plano Atual",
      name: "clientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "IA",
      name: "aidronPrediction",
      fullLabel: "Forecast IA",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Dif IA x Plano Atual",
      name: "differenceAidronAndClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif",
      calculate: values => calculateDiff(values),
      colorReferenceFrom: "percentAidronVsClient",
    },
    {
      label: "Dif% IA x Plano Atual",
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];
};

export const consolidatedForecastColumns = () => {
  return [
    {
      label: "Plano Atual",
      name: "clientPrediction",
      type: "prediction",
    },
    {
      label: "IA",
      name: "aidronPrediction",
      fullLabel: "Forecast IA",
      type: "prediction",
    },
    {
      label: "Pot Máx",
      name: "confidenceIntervalMax",
      fullLabel: "Potencial Máximo",
      type: "prediction",
    },
    {
      label: "Pot Mín",
      name: "confidenceIntervalMin",
      fullLabel: "Potencial Mínimo",
      type: "prediction",
    },
    {
      label: "Dif% IA x Plano Atual",
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
    },
  ];
};

export const accuracyColumnsCurrentMonth = ({ companyName }) => {
  const allColumns = [
    {
      label: "Plano Atual",
      name: "officialClientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "IA",
      name: "officialAidronPrediction",
      fullLabel: "Forecast IA",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Projeção",
      name: "projection",
      fullLabel: "Potencial Máximo",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Realizado",
      name: "realized",
      fullLabel: "Venda Realizada",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Lim Superior",
      name: "confidenceIntervalMax",
      fullLabel: "Limite Superior",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Lim Inferior",
      name: "confidenceIntervalMin",
      fullLabel: "Limite Inferior",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Part %",
      name: "participationInProjection",
      fullLabel: "Participação % na Projeção",
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: "Dif% IA x Projeção",
      name: "percentOfficialAidronVsProjection",
      indexOne: "officialAidronPrediction",
      indexTwo: "projection",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
    {
      label: `Dif% Plano Atual x Projeção`,
      name: "percentOfficialClientVsProjection",
      indexOne: "officialClientPrediction",
      indexTwo: "projection",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];

  if (companyName === "Caedu") {
    return allColumns;
  } else {
    const columnsToRemove = ["projection", "percentOfficialAidronVsProjection", "percentOfficialClientVsProjection"];
    return allColumns.filter(column => !columnsToRemove.includes(column.name));
  }
};

export const accuracyColumnsPast = () => {
  return [
    {
      label: "Plano Atual",
      name: "officialClientPrediction",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "IA",
      name: "officialAidronPrediction",
      fullLabel: "Forecast IA",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Realizado",
      name: "realized",
      fullLabel: "Venda Realizada",
      type: "prediction",
      calculate: values => sumColumn(values.selectedPredictions, values.name),
    },
    {
      label: "Part %",
      name: "participationInRealized",
      fullLabel: "Participação % no Realizado",
      type: "%",
      calculate: values => calculateParticipation(values),
    },
    {
      label: "Dif% IA x Realizado",
      name: "percentOfficialAidronVsRealized",
      indexOne: "officialAidronPrediction",
      indexTwo: "realized",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
    {
      label: `Dif% Plano Atual x Realizado`,
      name: "percentOfficialClientVsRealized",
      indexOne: "officialClientPrediction",
      indexTwo: "realized",
      type: "dif%",
      calculate: values => calculateDiffPercent(values),
    },
  ];
};

export const historicColumns = ({ unit }) => {
  return [
    {
      label: "Realizado",
      name: unit ? `realized${capitalizeFirst(unit)}` : "realized",
      fullLabel: "Venda Realizada",
      type: "prediction",
    },
    {
      label: "Part %",
      name: unit ? `participationInRealized${capitalizeFirst(unit)}` : "realized",
      fullLabel: "Participação % no Realizado",
      type: "%",
    },
  ];
};

export const storesFutureColumns = ({ predictionByUnit }) => {
  return [
    {
      label: "Previsto",
      name: predictionByUnit,
      type: "prediction",
    },
  ];
};

export const storesConsolidatedColumns = () => {
  return [
    {
      label: "Plano Atual",
      name: "clientPrediction",
      type: "prediction",
    },
    {
      label: "IA",
      name: "aidronPrediction",
      fullLabel: "Forecast IA",
      type: "prediction",
    },
    {
      label: "Pot Máx",
      name: "confidenceIntervalMax",
      fullLabel: "Potencial Máximo",
      type: "prediction",
    },
    {
      label: "Pot Mín",
      name: "confidenceIntervalMin",
      fullLabel: "Potencial Mínimo",
      type: "prediction",
    },
    {
      label: "Dif% IA x Plano Loja",
      name: "percentAidronVsClient",
      indexOne: "aidronPrediction",
      indexTwo: "clientPrediction",
      type: "dif%",
    },
  ];
};

export const channelAccuracyColumns = ({ unit }) => {
  const unitSuffix = capitalizeFirst(unit);
  return [
    {
      label: "IA",
      name: `officialPrediction${unitSuffix}`,
      fullLabel: "Forecast IA",
      type: "prediction",
    },
    {
      label: "Realizado",
      name: `realized${unitSuffix}`,
      fullLabel: "Venda Realizada",
      type: "prediction",
    },
    {
      label: "Part %",
      name: `realized${unitSuffix}`,
      fullLabel: "Participação % no Realizado",
      type: "%",
    },
    {
      label: "Dif% IA x Realizado",
      name: `percentOfficialVsRealized${unitSuffix}`,
      indexOne: `officialPrediction${unitSuffix}`,
      indexTwo: `realized${unitSuffix}`,
      type: "dif%",
    },
  ];
};

export const channelAccuracyProductColumns = ({ unit }) => {
  const unitSuffix = capitalizeFirst(unit);
  return [
    {
      label: "IA",
      name: `sumOfficialPrediction${unitSuffix}`,
      fullLabel: "Forecast IA",
      type: "prediction",
    },
    {
      label: "Realizado",
      name: `sumRealized${unitSuffix}`,
      fullLabel: "Venda Realizada",
      type: "prediction",
    },
    {
      label: "Part %",
      name: `sumParticipationInRealized${unitSuffix}`,
      fullLabel: "Participação % no Realizado",
      type: "%",
    },
    {
      label: "Dif% IA x Realizado",
      name: `percentOfficialVsRealized${unitSuffix}`,
      indexOne: `officialPrediction${unitSuffix}`,
      indexTwo: `realized${unitSuffix}`,
      type: "dif%",
    },
  ];
};

function calculateParticipation(values) {
  const allPredictions = sumColumn(values.allPredictions, values.name);
  const selectedPredictions = sumColumn(values.selectedPredictions, values.name);
  return (selectedPredictions / allPredictions) * 100;
}

function calculateDiff(values) {
  const sumIndexOne = sumColumn(values.selectedPredictions, values.indexOne);
  const sumIndexTwo = sumColumn(values.selectedPredictions, values.indexTwo);
  return sumIndexOne - sumIndexTwo;
}

function calculateDiffPercent(values) {
  const sumIndexOne = sumColumn(values.selectedPredictions, values.indexOne);
  const sumIndexTwo = sumColumn(values.selectedPredictions, values.indexTwo);

  if (sumIndexOne && sumIndexTwo) {
    return ((sumIndexOne - sumIndexTwo) / sumIndexTwo) * 100;
  } else {
    return null;
  }
}
