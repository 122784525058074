import React from "react";
import { Text } from "@aidron/aidron-ds";

import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function TextAndLabelView({ label, labelSize, text, textColor, heading, contentSize }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <Text size={labelSize}>{label}</Text>
      {text && (
        <CustomText weight="bold" color={textColor}>
          {text}
        </CustomText>
      )}
      {heading && <CustomHeading size={contentSize ? contentSize : "md"}>{heading}</CustomHeading>}
    </div>
  );
}
