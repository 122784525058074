import React, { useState, useEffect } from "react";
import { Text } from "@aidron/aidron-ds";

import { getProductDrivers } from "../../../../../../services/ProductDriver";
import { getForecasts } from "../../../../../../services/ForecastService";

import * as S from "./ProductDriversChart.style";

import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import ChartSeries from "./ChartSeries/ChartSeries";
import ContentLoader from "../../../../../../components/ContentLoader/ContentLoader";

export default function ProductDriversChart({ productId }) {
  const [productDrivers, setProductDrivers] = useState([]);

  const orderedProductDrivers = productDrivers?.sort((a, b) => b.value - a.value) || [];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "productDrivers");

    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const drivers = await getProductDrivers(token, latestForecastId, [productId]);

    const result = drivers.length ? drivers : undefined;

    setProductDrivers(result);
  }

  return (
    <ContentLoader
      resultCondition={productDrivers}
      contentCondition={productDrivers?.length}
      renderError={() => <Text>Esta funcionalidade estará disponivel em breve.</Text>}
    >
      <Text>
        Esta análise identifica o percentual de impacto de cada fator na venda deste produto. Esta análise é importante para
        direcionar ações que irão aumentar sua venda.
      </Text>
      <Text className="mt-2 mb-4">
        Abaixo, a relação dos fatores que impactam na venda deste produto e o percentual de impacto de cada um:
      </Text>
      <ProductHierarchyHeading productId={productId} />
      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <ChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>
    </ContentLoader>
  );
}
