import axios from "./BaseService";

const PRODUCTDRIVERS_URL = `${process.env.REACT_APP_API_URL}/productDrivers` || "https://teste.aidron.ai/opportunities";

export async function getProductDrivers(token, forecastId, productIds) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(`${PRODUCTDRIVERS_URL}/?forecastId=${forecastId}&productIds=${productIds.join(",")}`, {
    headers,
  });
  return response.data;
}

export async function getAverageProductDrivers(token, forecastId, productIds) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(`${PRODUCTDRIVERS_URL}/average/?forecastId=${forecastId}&productIds=${productIds.join(",")}`, {
    headers,
  });
  return response.data;
}
