import styled from "styled-components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CustomLink = styled(Link)`
  color: #f26f21;
  text-decoration: underline;

  &:hover {
    color: #f26f21;
    text-decoration: underline;
  }
`;

export { CustomLink };
