import React from "react";
import { Select, SelectItem } from "@aidron/aidron-ds";

import * as S from "./SortSelect.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";

export default function SortSelect({ label, selected, options, onChange }) {
  return (
    <S.Wrapper>
      <CustomText weight="bold" margin="0 1rem 0 0">
        {label}
      </CustomText>
      <Select readOnly label="" selected={selected} handleChange={e => onChange(e)}>
        {options.map(option => (
          <SelectItem value={option.value} key={option.value}>
            {option.displayName}
          </SelectItem>
        ))}
      </Select>
    </S.Wrapper>
  );
}
