import React from "react";
import { Text } from "@aidron/aidron-ds";

import * as S from "./MockTable.style";

export default function MockTable({ whiteBackground }) {
  const body = [
    {
      product: "Bota Coturno Moleca Feminino",
      value: "R$ 120.874",
    },
    {
      product: "Jaqueta Masculino",
      value: "R$ 100.728",
    },
    {
      product: "Moletom Vels Masculino",
      value: "R$ 60.437",
    },
    {
      product: "Blusa de Tricot Gola Alta",
      value: "R$ 40.291",
    },
  ];

  return (
    <div className="d-flex justify-content-center mt-3" style={{ backgroundColor: whiteBackground ? "white" : "" }}>
      <table className="table w-50">
        <thead>
          <tr className="table-active">
            <S.Cell border>
              <Text weight="bold">PRODUTO</Text>
            </S.Cell>
            <S.Cell border>
              <Text weight="bold">PRED. VENDA</Text>
            </S.Cell>
          </tr>
        </thead>
        <tbody>
          {body.map(b => (
            <tr>
              <S.Cell border>
                <Text>{b.product}</Text>
              </S.Cell>
              <S.Cell border>
                <Text>{b.value}</Text>
              </S.Cell>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
