import React, { useContext } from "react";
import { Heading, Text } from "@aidron/aidron-ds";
import { CompanyContext } from "../../templates/context/CompanyContext";

import { capitalizeFirst } from "../../utils/utils";

import MockedStoresForDemo from "../../services/products.json";

export default function ProductHierarchyHeading({ productId, useMockedStores }) {
  const { hierarchy, allProducts: allProductsFromContext, userInfo } = useContext(CompanyContext);
  const companyId = userInfo.company.id;

  let allProducts = [];
  if (companyId === 4 && useMockedStores) {
    allProducts = MockedStoresForDemo;
  } else allProducts = allProductsFromContext;

  const product = getProductById(productId);

  function getProductById(id) {
    return allProducts.find(product => product.productId === id);
  }

  return (
    <div className="mb-2">
      <Heading size="sm">{product[hierarchy.levels[hierarchy.levels.length - 1]]}</Heading>
      <Text size="xs">
        {hierarchy.levels
          .slice(0, -1)
          .map(
            (level, index, arr) =>
              `${useMockedStores ? "" : hierarchy.labels[level]} ${capitalizeFirst(product[level])}${
                index !== arr.length - 1 ? " | " : ""
              }`
          )}
      </Text>
      {/* {product.season?.name && (
      <Text size="xs">{`${i18n.t("season")} ${capitalizeFirst(
        product.season.name
      )}`}</Text>
    )} */}
    </div>
  );
}
