import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

import * as S from "./MultiSlideCarousel.style";

import SideButton from "./SideButton/SideButton";

export default function MultiSlideCarousel({ children, slideCount }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <S.Container>
      <Carousel
        responsive={responsive}
        customLeftArrow={<SideButton side="left" />}
        customRightArrow={<SideButton side="right" />}
        showDots
        renderDotsOutside
        className={`${slideCount <= 3 ? "justify-content-center" : ""}`}
      >
        {children}
      </Carousel>
    </S.Container>
  );
}
