import React from "react";
import { Button } from "@aidron/aidron-ds";

export default function WeekSortByButton({
  column,
  weekObj,
  sortRowsBy,
  setSortRowsBy,
}) {
  function isAlreadySortedByThisColumn(newSortBy) {
    if (!sortRowsBy.columnLabel || !sortRowsBy.weeks) return false;

    return sortRowsBy.columnLabel === newSortBy.columnLabel &&
      sortRowsBy.weeks[0] === newSortBy.weeks[0] &&
      sortRowsBy.year === newSortBy.year
      ? true
      : false;
  }

  function updateSortBy() {
    const newSortBy = {
      orderForecastId: weekObj.forecastId,
      columnLabel: column.name,
      weeks: weekObj.weeks,
      year: weekObj.year,
    };

    if (isAlreadySortedByThisColumn(newSortBy)) {
      const newOrder =
        sortRowsBy.order === "ascending" ? "descending" : "ascending";
      setSortRowsBy({ ...sortRowsBy, order: newOrder });
    } else {
      setSortRowsBy({
        ...newSortBy,
        order:
          newSortBy.columnLabel.substring(0, 7) === "percent"
            ? "ascending"
            : "descending",
      });
    }
  }

  function determineColumnIcon(columnLabel, weekObj) {
    if (
      sortRowsBy.columnLabel === columnLabel &&
      sortRowsBy.weeks &&
      sortRowsBy.weeks[0] === weekObj.weeks[0] &&
      sortRowsBy.year === weekObj.year
    ) {
      return sortRowsBy.order === "ascending" ? "arrow-down" : "arrow-up";
    } else return "chevron-down";
  }

  return (
    <Button
      label=""
      onClick={updateSortBy}
      type="ghost"
      icon={determineColumnIcon(column.name, weekObj)}
      className="ms-1"
    />
  );
}
