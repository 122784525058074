import styled from "styled-components";

const ArticleFatorys = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  img { 
    width: 100%;
  }
  .grid {
    display: grid;
    gap: 20px;
    grid-template-columns:  auto auto;
  }
`;

const ArticleCuration = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  img {
    width: 100%;
  }
  .grid {
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
`;

export { ArticleFatorys, ArticleCuration };
