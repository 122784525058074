import styled from "styled-components";
import { Text } from "@aidron/aidron-ds";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
`;

const IndexesSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 1.5rem;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`;

const ExplanationText = styled(Text)`
  white-space: pre-line;
  text-align: center;
`;

const OuterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export { OuterContainer, IndexesSection, TextWrapper, ExplanationText, OuterButtonContainer, InnerButtonContainer };
