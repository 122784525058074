import React from "react";
import { Button, Text } from "@aidron/aidron-ds";

export default function WeekColumnFilter({
  allForecasts,
  onConcludedClick,
  setSelectedHistoricWeekIds,
  selectedHistoricWeekIds,
}) {
  const allForecastsCopy = [...allForecasts];
  allForecastsCopy.pop();

  const orderedForecasts = allForecastsCopy.reverse();

  const years = [...new Set(orderedForecasts.map((forecast) => forecast.year))];

  function getWeeksFromYear(year) {
    return orderedForecasts.filter((forecast) => forecast.year === year);
  }

  function onWeekClick(forecastId) {
    const updatedIds = selectedHistoricWeekIds.includes(forecastId)
      ? selectedHistoricWeekIds.filter((id) => id !== forecastId)
      : [...selectedHistoricWeekIds, forecastId];
    setSelectedHistoricWeekIds(updatedIds);
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="max-height-600px overflow-scroll width-fit-content max-width-100percent mt-4">
          <table className="table table-inner-div">
            <tbody>
              {years.map((year, index) => (
                <tr key={index}>
                  <td className="vertical-align-middle position-sticky left-0 background-color-white">
                    <Text weight="bold" className="font-size-17px">
                      {year}
                    </Text>
                  </td>
                  {getWeeksFromYear(year).map((week, i) => (
                    <td className="padding-sides-030rem" key={i}>
                      <Button
                        label={week.week?.toString()}
                        onClick={() => onWeekClick(week.forecastId)}
                        type={
                          selectedHistoricWeekIds.some(
                            (selectedWeek) => selectedWeek === week.forecastId
                          )
                            ? "outlined"
                            : "ghost"
                        }
                        className="box-shadow-unset box-shadow-unset-on-button"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button label="Concluído" onClick={onConcludedClick} />
      </div>
    </>
  );
}
