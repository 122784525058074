import React, { useState, useRef } from "react";

import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Default from "../../components/Default/Default";
import ConsolidatedView from "./ConsolidatedView/ConsolidatedView";
import ProductView from "./ProductView/ProductView";

export default function PlanVsForecast() {
  const [selectedLevels, setSelectedLevels] = useState({});
  const [inputtedExpressions, setInputtedExpressions] = useState([]);

  const productViewRef = useRef(null);

  const subtitle = "Análise das principais divergências entre o Plano oficial da empresa X Forecast gerado pela Aidron";

  function defineProductFilter(filterBy) {
    scrollToProductView();
    setInputtedExpressions([]);
    setSelectedLevels(filterBy);
  }

  function scrollToProductView() {
    if (productViewRef.current) {
      productViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <Default>
      <SectionHeader heading="Análise do Plano Atual x Forecast" headingSize="lg" pageHeading subtitles={[subtitle]} />

      <ConsolidatedView defineProductFilter={defineProductFilter} />

      <div ref={productViewRef}>
        <ProductView
          selectedLevels={selectedLevels}
          setSelectedLevels={setSelectedLevels}
          inputtedExpressions={inputtedExpressions}
          setInputtedExpressions={setInputtedExpressions}
        />
      </div>
    </Default>
  );
}
