import React from "react";

import * as S from "./OpportunityCarousel.style";

import Carousel from "../../../../../../../components/Carousel/Carousel";
import OpportunityAccordion from "../../OpportunityAccordion";

export default function OpportunityCarousel({ otherOpportunities, getOpportunityDetails }) {
  return (
    <Carousel slideNumber={otherOpportunities.length}>
      {otherOpportunities.map((opportunity, index) => (
        <S.OuterContainer className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
          <S.InnerContainer>
            <OpportunityAccordion opportunity={opportunity} getOpportunityDetails={getOpportunityDetails} />
          </S.InnerContainer>
        </S.OuterContainer>
      ))}
    </Carousel>
  );
}
