import React from "react";
import { Text } from "@aidron/aidron-ds";

import * as S from "./Error.style";

export default function Error() {
  return (
    <Text weight="bold">
      Nenhuma oportunidade foi enviada para Checkout. Para isso, vá para a{" "}
      <S.CustomLink to="/oportunidades/produtos">página de Oportunidades.</S.CustomLink>
    </Text>
  );
}
