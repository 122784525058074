import React from "react";
import { getMonthName } from "../../../utils/utils";

import SectionHeader from "../../../components/SectionHeader/SectionHeader";

function ChannelSectionHeader({
  selectedForecast,
  lastForecastOfPreviousMonth,
}) {
  const day = selectedForecast.day;
  const month = selectedForecast.month;
  const year = selectedForecast.year;

  const firstMonthOfPrediction = getMonthName(selectedForecast.month);

  const pastDay = lastForecastOfPreviousMonth.day;
  const pastMonth = lastForecastOfPreviousMonth.month;
  const pastYear = lastForecastOfPreviousMonth.year;

  const subtitles = [
    `Forecast de ${firstMonthOfPrediction} gerado em: ${pastDay}/${pastMonth}/${pastYear}`,
    `Forecast dos demais meses gerado em: ${day}/${month}/${year}`,
  ];

  return (
    <SectionHeader heading="Visão do Canal de Lojas" subtitles={subtitles} />
  );
}

export default ChannelSectionHeader;
