import React from "react";

import * as S from "./ProductButtons.style";

import ProductDriversButton from "./ProductDriversButton/ProductDriversButton";
import AccuracyChartButton from "./AccuracyChartButton/AccuracyChartButton";
import OpportunityButton from "./OpportunityButton/OpportunityButton";

export default function ProductButtons({ productId }) {
  return (
    <S.ButtonsContainer>
      <ProductDriversButton productId={productId} />
      <AccuracyChartButton productId={productId} />
      <OpportunityButton productId={productId} />
    </S.ButtonsContainer>
  );
}
