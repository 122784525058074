import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2%;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 5rem;
`;

export { OuterContainer, InnerContainer };
