import React from "react";
import { Progress } from "@aidron/aidron-ds";

export default function ContentLoader({ resultCondition, contentCondition, children, renderError }) {
  return resultCondition ? (
    contentCondition ? (
      children
    ) : (
      <div className="d-flex justify-content-center align-items-center width-40px-on-progress">
        <Progress circular />
      </div>
    )
  ) : (
    renderError()
  );
}
