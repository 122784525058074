import React from "react";
import { Card } from "@aidron/aidron-ds";

import { sumColumn, toNDecimalsString } from "../../../utils/utils";

import * as S from "./CheckoutSummary.style";
import * as OpportunityStyled from "../../../pages/Opportunity/ProductListItems/ListItem/ListItem.style";

import LabelAndNumberView from "../../Home/UpperCardModel/InnerCard/LabelAndNumberView/LabelAndNumberView";

export default function CheckoutSummary({ opportunities }) {
  const sum = sumColumn(opportunities, "value");

  return (
    <Card>
      <S.Wrapper>
        <S.Section border>
          <S.CustomHeading size="md" color="grey">
            RESUMO DO CHECKOUT
          </S.CustomHeading>
        </S.Section>

        <S.Section>
          <div className="d-flex align-items-center justify-content-center">
            <S.CustomHeading size="sm" color="grey">
              TOTAL DE OPORTUNIDADES
            </S.CustomHeading>
            <S.CustomHeading size="lg" color="orange">
              {opportunities.length ? opportunities.length : "0"}
            </S.CustomHeading>
          </div>
        </S.Section>

        <S.Section border>
          <S.ItemsWrapper>
            <S.Item>
              <OpportunityStyled.SaleIcon />
              <LabelAndNumberView
                heading="PRODUTOS"
                number={`R$ ${toNDecimalsString(sum, 0)}`}
                text={`${opportunities.length} oportunidades`}
              />
            </S.Item>
          </S.ItemsWrapper>
        </S.Section>

        <div className="d-flex align-items-center justify-content-center">
          <S.CustomHeading size="md" color="grey" margin>
            TOTAL
          </S.CustomHeading>
          <S.CustomHeading size="lg" color="orange">{`R$ ${toNDecimalsString(sum, 0)}`}</S.CustomHeading>
        </div>
      </S.Wrapper>
    </Card>
  );
}
