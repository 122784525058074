import styled from "styled-components";

const OuterContainer = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.5rem;
`;

const OptionContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const FinishButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ConfirmationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

export { OuterContainer, OptionContainer, FinishButtonContainer, ConfirmationButtonContainer };
