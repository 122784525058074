import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-bottom: 15px;
`;

const Link = styled.div`
  cursor: ${({ cursor }) => (cursor ? cursor : "")};
`;

export { OuterContainer, Link };
