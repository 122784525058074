import styled from "styled-components";
import { Text } from "@aidron/aidron-ds";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1600px) {
    min-width: 240px;
  }
`;

const LinkText = styled(Text)`
  display: inline;
`;

export { Wrapper, LinkText };
