import axios from "./BaseService";

const FORECASTS_URL =
  `${process.env.REACT_APP_API_URL}/forecasts` ||
  "https://teste.aidron.ai/forecasts";

export async function getForecasts(token, relatedTable) {
  const forecastsUrl = `${FORECASTS_URL}/?relatedTable=${relatedTable}`;
  const headers = {
    authorization: token,
  };
  const response = await axios.get(forecastsUrl, { headers });
  return response.data;
}

export async function saveForecasts(id, newForecast, token) {
  const headers = { authorization: token };
  let response;
  if (id)
    response = await axios.patch(`${FORECASTS_URL}/${id}`, newForecast, {
      headers,
    });
  else response = await axios.post(FORECASTS_URL, newForecast, { headers });
  return response.data;
}

export async function deleteForecasts(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${FORECASTS_URL}/${id}`, { headers });
  return response.data;
}
