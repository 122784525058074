const messages = {
  en: {
    translations: {
      headings: {
        dashboard: "Dashboard",
        forecast: {
          title: "Forecast",
          downloads: "Downloads",
          analysis: "Analysis",
        },
        automations: "Automations",
        settings: "Settings",
      },
      sideBar: {
        editAvatarHeading: "Edit your photo",
        addAvatar: "Add photo",
        deleteAvatar: "Delete photo",
      },
      languageSelector: "Language",
      company: "Company",
      login: {
        title: "Sign in to our platform",
        emailLabel: "Your email",
        emailPlaceholder: "example@company.com",
        passwordLabel: "Your password",
        passwordPlaceholder: "mypassword",
        signIn: "Sign In",
        error: "Invalid user and/or password!",
        logOut: "Log Out",
      },
      settings: "Settings",
      myAccount: "My account",
      users: "Users",
      organization: "Organization",
      logout: "Log out",
      profilePicture: "Profile picture",
      chosePicture: "Chose picture",
      permissions: "Permissions",
      user: "User",
      role: "Role",
      productHierarchy: "Product hierarchy",
      numberOfLevels: "Number of levels",
      level: "Level",
      levelName: "Level name",
      channelRegistration: "Channel registration",
      // FORECAST SECTION:
      year: "Year",
      month: "Month",
      weeks: "Weeks",
      months: "Months",
      filterBy: "Filter by",
      generatedOn: "Forecast generated on",
      generationDate: "Generation date",
      forecastDate: "Forecast date",
      commercialWeek: "Commercial week",
      commercialWeeks: "Commercial weeks",
      monthlyForecast: "Forecast months",
      showFilter: "Filters",
      hideFilter: "Hide filters",
      removeFilters: "Remove filters",
      products: "Products",
      fileError: "The selected file is unavailable. Select different dates",
      summary: "Download summary",
      singleFile: "Single file (.xlsx)",
      singleFileCaption: "A sheet per week",
      multipleFiles: "Multiple files (.zip)",
      multipleFilesCaption: "A file per week",
      exportMultiple: "Export multiple",
      advancedExport: "Advanced export",
      forecasts: "Forecasts",
      selectCommWeek: "Forecasts generated in the following commercial weeks:",
      next: "Next",
      changeWeekModal: {
        body: "If you change the selected commercial weeks, the applied product filters will be reset.",
      },
      attention: "Warning!",
      options: "Options",
      downloadSummary: "Download summary",
      addAnotherMale: "Add another",
      addAnotherFemale: "Add another",
      addPeriod: "Add period",
      commercialCalendar: "Commercial Calendar",
      insertYear: "Insert a year between 1000 and 9999",
      sort: "Sort",
      graphicHeading: "Graphic Analysis",
      descriptiveHeading: "Descriptive Analysis",
      classicHeading: "Classic Analysis",
      productFilter: "Filter by product",
      brand: "Collection",
      griffe: "Griffe",
      grife: "Grife",
      line: "Line",
      group: "Group",
      subgroup: "Subgroup",
      colecao: "Collection",
      linha: "Line",
      marca: "Brand",
      grupo: "Group",
      subgrupo: "Subgrupo",
      subgrupos: "Subgroups",
      graphType: "Graph style",
      lines: "Lines",
      columns: "Columns",
      showing: "Showing",
      product: "product",
      estimated: "Estimated",
      realized: "Realized",
      percentDiff: "Diff %",
      diff: "Difference %",
      wape: "WAPE",
      selectOneOption: "Select one option",
      selectAtLeastOne: "Select at least one option",
      sortBy: "Sort by",
      highest: "Highest",
      lowest: "Lowest",
      // Página "Análise Descritiva"
      aidronView: "Aidron view",
      classicView: "Classic view",
      resultsSubtitle: "Demand forecast (in clothing pieces)",
      week: "Week",
      analyseGraph: "Analyse graph",
      all: "All",
      choseProducts: "Chose products",
      checkAll: "Select all",
      uncheckAll: "Deselect all",
      analyse: "Analyse",
      close: "Close",
      estActDif: "Est  |  Act  |  Diff%",
      centerMonth: "Center month",
      centerWeek: "Center week",
      expand: "Expand data",
      shrink: "Shrink data",
      selectedProducts: "Selected products",
      // Página "Visualização Clássica"
      freezeColumns: "Freeze columns",
      hideColumns: "Hide columns",
      unhideColumns: "Unhide columns",
      filterByDate: "Filter by date",
      filterByName: "Filter by name",
      searchLabel: "Search product...",
      searchError:
        "Your search did not return any results. Try changing the applied filters, the commercial week or the year!",
      exportData: "Export data",
      aidronForecast: "AidronForecast",
      changeCommWeek: "Change commercial week",
      editEstimated: "Edit estimations",
      finishRevision: "Finish revision",
      revision: "Revision",
      revised: "Revised",
      send: "Send",
      reviewAndSend: "Review and send",
      typeYear: "Type a year..",
      forecastPeriod: "Forecast period",
      showHistory: "Show history",
      analyseAndIntegratePredictions: "Analyse and integrate forecasts",
      analysePredictions: "Analyse predictions",
      saveRevision: "Save revision",
      closeRevision: "Close revision",
      cancel: "Cancel",
      dontSave: "Discard changes",
      forecastRevision: "Forecast revision",
      unsavedChangesModal: {
        heading: "Save changes?",
        body: "There are unsaved values in the forecast revision. Do you wish to save these changes?",
        promptBody:
          "There are unsaved values in the forecast revision. Are you sure you want to leave?",
      },
      dataSent: "Data was successfully sent!",
      sent: "Sent",
      submittedOn: "Submitted on",
      sendingPreviewModal: {
        heading: "Submit summary",
        alreadySentProducst:
          "You selected items that were already sent, so these were withdrawn from the list above.",
        body: "Review the data that will be sent. To edit, go back to the previous page.",
      },
      noSendingIntegrationModal: {
        heading: "There is no integration with your planning system!",
        body: "You have no integration with your planning system. We generated an export file with this version of the forecast.",
        downloadFile: "Download file",
      },
      back: "Back",
      formerWeekModal: {
        heading: "Confirm the commercial week",
        bodyEditing:
          "The forecast you are analysing was generated in the commercial week number ",
        bodySending:
          "The forecast you are about to send to your system was generated in the commercial week number ,",
        body2: " of ",
        body3: ", which is not the latest forecast issued.",
        question: "How do you wish to continue?",
        stay: "Stay in week ",
        goToCurrent: "Go to latest commercial week",
      },
      savedUnsent: "Saved and unsent",
      seeSendingStatus: "See sending status",
      sendingStatus: "Sending status",
      productsAmount: "No of products",
      seeDetails: "See details",
      hideDetails: "Hide details",
      noCheckedProductsModal: {
        heading: "There are no selected products!",
        body: "In order to continue, select one or more products from the table.",
      },
      productRegistration: "Product registration",
      remove: "Remove",
      hideProducts: "Hide products",
      showRegisteredProducts: "Show registered products",
      saveModifications: "Save changes",
      errorModal: {
        heading: "Oops! Something went wrong",
        checkFields: "Check the highlighted fields and try again.",
        changesNotSaved:
          "Changes were not saved. Please contact the system administrator.",
      },
      typeValidName: "Type a valid name",
      season: "Season",
      seasonRegistration: "Seasons registration",
      hideSeasons: "Hide seasons",
      showRegisteredSeasons: "Show registered seasons",
      and: "and",
      filterBySeason: "Filter by season",
      predictions: "Predictions",
      showSeasons: "Show seasons",
      bulkImport: "Bulk import",
      seasonsBulkImport: "Seasons bulk import",
      step: "Step",
      importModal: {
        step1Instruction:
          "Download the template and fill it according to the example inside it.",
        step2Instruction:
          "Upload the template containing the data to be registered.",
        step3Instruction:
          "Verify the data to be imported and confirm the import.",
        error:
          "There was an error on importing data. Verify the inputted data in the file and, if the problem persists, please contact the system administrator.",
      },
      choseFile: "Chose file",
      fileUploaded: "The file was uploaded!",
      confirm: "Confirm",
      seasonNumber: "Season number",
      seasonNumbers: "Season numbers",
      seasonName: "Season name",
      seasonNames: "Season names",
      seasonMonths: "Season months",
      importSeasons: "Import seasons",
      importProducts: "Import products",
      percentDifference: "Percent difference",
      productNumber: "Product number",
      example: "Example",
      optional: "Optional",
      consultOnSeasonsSheet: 'Consult number in the "Season Numbers" sheet',
      new: "New",
      seasons: "Seasons",
      wereSaved: "wereSaved",
      wereNotSaved: "were NOT saved",
      verifyData:
        "Verify the inputted data and try again. If the problem persists, please contact the system administrator.",
    },
  },
};

export { messages };
