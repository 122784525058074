import styled from "styled-components";
import { Button } from "@aidron/aidron-ds";

const TitleContainer = styled.div`
  display: flex;
`;

const AccordionButton = styled(Button)`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
`;

export { TitleContainer, AccordionButton };
