import React, { useState } from "react";
import { Icon } from "@aidron/aidron-ds";

import * as S from "./HelpButton.style";

import ModalTemplate from "../../../../../components/ModalTemplate/ModalTemplate";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function HelpButton({ indexes }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <S.HelpButton onClick={() => setOpenModal(true)}>
        <Icon icon="help-circle" size={15} />
      </S.HelpButton>
      {openModal && (
        <ModalTemplate width="700px" heading="Ajuda: índices para apoio na análise" closingFunction={() => setOpenModal(false)}>
          {indexes.map((index, i) => (
            <S.IndexContainer key={i}>
              <CustomText weight="bold">{index.label}:</CustomText>
              <CustomText margin="0 0 0 10px" textAlign="justify">
                {index.text}
              </CustomText>
            </S.IndexContainer>
          ))}
        </ModalTemplate>
      )}
    </>
  );
}
