import React from "react";

import * as S from "./RemovalReason.style";

import CustomText from "../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function RemovalReason({ removalReason, setRemovalReason }) {
  const removalReasonMaxLength = 2000;

  function onRemovalReasonChange(e) {
    const inputText = e.target.value;
    if (inputText.length <= removalReasonMaxLength) {
      setRemovalReason(inputText);
    }
  }

  return (
    <S.TextAreaOuterContainer>
      <CustomText weight="bold">Descreva brevemente o motivo:</CustomText>
      <S.TextAreaInnerContainer>
        <S.TextArea
          id="paragraph"
          name="paragraph"
          rows="5"
          cols="50"
          onChange={onRemovalReasonChange}
          maxLength={removalReasonMaxLength}
          value={removalReason}
        />
        <CustomText textAlign="end" size="xs">
          {2000 - removalReason.length} caracteres restantes
        </CustomText>
      </S.TextAreaInnerContainer>
    </S.TextAreaOuterContainer>
  );
}
