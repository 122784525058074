import React from "react";
import { Button } from "@aidron/aidron-ds";

export default function SideButton({ side, onClick }) {
  return (
    <Button
      className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--${side} custom-carousel-arrow-button`}
      icon={`chevron-${side}`}
      type="outlined"
      position="left"
      onClick={onClick}
      label=""
    />
  );
}
