import React from "react";
import { Heading, Card } from "@aidron/aidron-ds";
import Default from "../../components/Default/Default";
import * as S from "./Insights.style";

import graphSpending from "./assests/image/graphSpending.png";
import graphParticipation from "./assests/image/graphParticipation.png";
import graphGrowth from "./assests/image/graphGrowth.png";
import newBig from "./assests/image/newBig.png";
import newSmallOne from "./assests/image/newSmallOne.png";
import newSmallTwo from "./assests/image/newSmallTwo.png";
import newSmallThree from "./assests/image/newSmallThree.png";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function Insights() {
  return (
    <Default>
      <PageHeading>Insights de Mercado</PageHeading>
      <Card>
        <Heading size="md" className="mb-4">
          Fatores que impactaram as predições
        </Heading>
        <S.ArticleFatorys>
          <div className="grid">
            <img src={graphSpending} alt="Grafico de Gastos" />
            <img src={graphParticipation} alt="Gratico de participação" />
          </div>
          <div>
            <img src={graphGrowth} alt="Grafico de crescimento" />
          </div>
        </S.ArticleFatorys>
      </Card>
      <div className="mb-5" />
      <Card>
        <Heading size="md" className="mb-4">
          Curadoria de conteúdo
        </Heading>
        <S.ArticleCuration>
          <div>
            <img src={newBig} alt="Noticia em destaque" />
          </div>
          <div className="grid">
            <img src={newSmallOne} alt="Noticia na linha 1" />

            <img src={newSmallTwo} alt="Noticia na linha 2" />

            <img src={newSmallThree} alt="Noticia na linha 3" />
          </div>
        </S.ArticleCuration>
      </Card>
    </Default>
  );
}
