import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: center;

  @media (min-width: 700px) {
    justify-content: space-between;
  }

  @media (min-width: 1600px) {
    padding: 0 5%;
  }
`;

export { Wrapper };
