import React, { useEffect, useState } from "react";
import { Text } from "@aidron/aidron-ds";

import { getPredictionsByProduct } from "../../../services/PredictionsService";

import Carousel from "../../../components/Carousel/Carousel";
import ProductAccuracyChart from "../../../components/ProductAccuracyChart/ProductAccuracyChart";
import ContentLoader from "../../../components/ContentLoader/ContentLoader";

export default function AccuracyChartCarousel({ checkedProducts, currentMonth, unit }) {
  const [productPredictions, setProductPredictions] = useState([]);

  useEffect(() => {
    fetchPredictions();
  }, []);

  async function fetchPredictions() {
    const token = localStorage.getItem("token");

    const result = await getPredictionsByProduct(token, checkedProducts);
    setProductPredictions(result);
  }

  return (
    <ContentLoader
      resultCondition={checkedProducts}
      contentCondition={productPredictions.length}
      renderError={() => <Text>Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.</Text>}
    >
      <Carousel slideNumber={checkedProducts.length}>
        {checkedProducts.map((id, index) => (
          <div className={`carousel-item ${index === 0 ? "active" : ""} justify-content-center`} key={index}>
            <div className="w-75">
              <ProductAccuracyChart
                productId={id}
                unit={unit}
                currentMonth={currentMonth}
                productPredictions={productPredictions}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </ContentLoader>
  );
}
