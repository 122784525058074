import styled from "styled-components";

const CardWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(10px, 1fr);
  column-gap: 30px;
`;

export { CardWrapper };
