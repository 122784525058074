import styled from "styled-components";

const OuterContainer = styled.div`
  margin-bottom: 2rem;
`;

const GroupingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1.5fr;
  gap: 10px;
  margin: 10px 0;
`;

export { OuterContainer, GroupingContainer, CardsWrapper };
