import React from "react";
import { Button } from "@aidron/aidron-ds";
import "./style.css";

function DisabledFilledButton({ label, type, onClick, className }) {
  return (
    <Button
      label={label}
      type={type}
      onClick={onClick}
      className={className + " disabled-filled-button"}
    />
  );
}

export default DisabledFilledButton;
