import styled from "styled-components";

const TextAreaOuterContainer = styled.div`
  margin-left: 2.5rem;
`;

const TextAreaInnerContainer = styled.div`
  max-width: 600px;
`;

const TextArea = styled.textarea`
  width: 100%;
`;

export { TextAreaOuterContainer, TextAreaInnerContainer, TextArea };
