import React from "react";
import { Button } from "@aidron/aidron-ds";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

function TableDownloadButton({ fileName, size }) {
  function exportToExcel() {
    const table = document.getElementById("download-table");

    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Forecast");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName}.xlsx`);
  }

  function handleDownloadClick() {
    exportToExcel(fileName);
  }

  return <Button label="" type="outlined" icon="download" size={size ? size : "sm"} onClick={handleDownloadClick} />;
}

export default TableDownloadButton;
