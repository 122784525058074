import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export { OuterContainer, InnerContainer };
