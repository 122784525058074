import React from "react";
import { Text } from "@aidron/aidron-ds";

export default function TableColumnTitle({ label, breakWords }) {
  return (
    <Text
      className={`text-center font-size-16px ${breakWords ? "white-space-break-spaces" : ""}`}
      style={{ whiteSpace: "pre-wrap" }}
    >
      {label}
    </Text>
  );
}
