import React from "react";
import * as S from "./ProductListItems.style";
import ListItem from "./ListItem/ListItem";

export default function ProductListItems({ opportunities, fetchData, accordionButton, reactivateButton, reactivateOpportunity }) {
  return (
    <S.wrapperList>
      {opportunities.map(opportunity => (
        <ListItem
          key={opportunity.id}
          opportunity={opportunity}
          fetchData={fetchData}
          accordionButton={accordionButton}
          reactivateButton={reactivateButton}
          reactivateOpportunity={reactivateOpportunity}
          opportunities={opportunities}
        />
      ))}
    </S.wrapperList>
  );
}
