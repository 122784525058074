import axios from "./BaseService";

const FORECASTS_URL = `${process.env.REACT_APP_API_URL}/opportunities` || "https://teste.aidron.ai/opportunities";

export async function getOpportunities(token, forecastId, productIds, order, types, status) {
  const params = new URLSearchParams();
  params.append("forecastId", forecastId);
  params.append("productIds", productIds.join(","));
  params.append("order", order);

  if (types) {
    params.append("type1", types.join(","));
  }

  if (status) {
    params.append("status", status.join(","));
  }

  const headers = {
    authorization: token,
  };
  const response = await axios.get(`${FORECASTS_URL}/?${params.toString()}`, { headers });
  return response.data;
}

export async function getOpportunitiesCount(token, forecastId, status) {
  const params = new URLSearchParams();
  params.append("forecastId", forecastId);

  if (status) {
    params.append("status", status.join(","));
  }

  const headers = {
    authorization: token,
  };
  const response = await axios.get(`${FORECASTS_URL}/counter?${params.toString()}`, { headers });
  return response.data;
}

export async function updateOpportunities(token, newOpportunities) {
  const headers = {
    authorization: token,
  };

  const response = await axios.patch(FORECASTS_URL, { opportunities: newOpportunities }, { headers });
  return response;
}
