import React, { useContext, useEffect, useState } from "react";
import { Card } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { to2DecimalsNumber } from "../../../../utils/utils";

import { getPredictions } from "../../../../services/PredictionsService";

import AreaRangeChart from "../../../../components/AreaRangeChart/AreaRangeChart";

export default function ChannelChart({ selectedForecast, sortBy, consolidatedPredictions }) {
  const { userInfo } = useContext(CompanyContext);
  const [currentMonthPredictions, setCurrentMonthPredictions] = useState([]);

  const currentMonth = selectedForecast;
  const amountOfDaysInMonth = new Date(currentMonth.year, currentMonth.month, 0).getDate();
  const xLabels = Array.from({ length: amountOfDaysInMonth }, (_, i) => i + 1);

  const ranges = dividirNumerosCrescenteMaxMin(
    consolidatedPredictions[0]?.confidenceIntervalMax,
    consolidatedPredictions[0]?.confidenceIntervalMin,
    amountOfDaysInMonth
  );

  const aidronPrediction = dividirNumerosCrescente(consolidatedPredictions[0]?.officialPrediction, amountOfDaysInMonth);

  const clientPrediction = dividirNumerosCrescente(consolidatedPredictions[0]?.clientPrediction, amountOfDaysInMonth);

  const sumRealizedValues = sumColumn(currentMonthPredictions, "realized");
  const currentDayOfMonth = currentMonthPredictions[0]?.forecast?.day;
  let realized = dividirNumerosCrescente(sumRealizedValues, currentDayOfMonth);

  if (userInfo.company.id === 4) {
    realized = dividirNumerosCrescente(15000000, 17);
  }

  const lines = [
    {
      label: "Forecast IA",
      values: aidronPrediction,
      marker: false,
      lineStyle: "shortDash",
    },
    {
      label: "Plano Atual",
      values: clientPrediction,
      marker: false,
      lineStyle: "shortDash",
    },
    {
      label: "Venda Real",
      values: realized,
      marker: false,
    },
  ];

  function sumColumn(predictions, columnName) {
    return predictions.reduce((acc, curr) => {
      if (curr[columnName]) return acc + curr[columnName];
      else return acc;
    }, 0);
  }

  function dividirNumerosCrescenteMaxMin(limiteMax, limiteMin, totalDias) {
    const mediaDiariaMax = limiteMax / totalDias;
    const mediaDiariaMin = limiteMin / totalDias;

    let numeroAtualMax = mediaDiariaMax;
    let numeroAtualMin = mediaDiariaMin;

    const valores = [];

    while (numeroAtualMax <= limiteMax - 1) {
      valores.push([numeroAtualMax, numeroAtualMin]);
      numeroAtualMax += mediaDiariaMax;
      numeroAtualMin += mediaDiariaMin;
    }
    valores.push([to2DecimalsNumber(limiteMax), to2DecimalsNumber(limiteMin)]);
    return valores;
  }

  function dividirNumerosCrescente(limite, totalDias) {
    const mediaDiaria = limite / totalDias;

    let numeroAtual = mediaDiaria;
    const valores = [];

    while (numeroAtual <= limite - 1) {
      valores.push(to2DecimalsNumber(numeroAtual));
      numeroAtual += mediaDiaria;
    }

    valores.push(limite);

    return valores;
  }

  function formatByUnit() {
    return "R$ " + this.value * 0.1 + "MM";
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  async function fetchInitialData() {
    const token = localStorage.getItem("token");

    const fetchSortBy = { ...sortBy };
    delete fetchSortBy.columnLabel;

    const predictions = await getPredictions(token, [selectedForecast.forecastId], {
      ...fetchSortBy,
      months: [selectedForecast.month],
    });

    const currentMonthPredictions = predictions.predictions.filter(
      prediction => prediction.month === selectedForecast.month && prediction.year === selectedForecast.year
    );
    setCurrentMonthPredictions(currentMonthPredictions);
  }

  return (
    <Card style={{ marginLeft: "20px", padding: "30px" }}>
      <AreaRangeChart
        xLabels={xLabels}
        lines={lines}
        ranges={ranges}
        colors={["#f58b4d", "#BFBFBF", "#00BECB"]}
        yAxisFormatter={formatByUnit}
      />
    </Card>
  );
}
