import styled from "styled-components";

const OuterWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 905px) {
    width: 68%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: 1258px) {
    justify-content: space-between;
  }
`;

export { OuterWrapper, InnerWrapper };
