import React, { useContext } from "react";
import { ForecastContext } from "../../../../templates/context/ForecastContext";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { getForecastPredictions, getProductPrediction } from "../../../../utils/utils";
import { accuracyColumnsCurrentMonth, accuracyColumnsPast } from "../../../../utils/tableColumns";

import CheckCell from "../../../../components/CheckCell/CheckCell";
import HierarchyBodyCells from "../../../../components/HierarchyBodyCells/HierarchyBodyCells";
import AccuracySubtotalRow from "../AccuracySubtotalRow/AccuracySubtotalRow";
import BodyCells from "../../../../components/BodyCells/BodyCells";

export default function AccuracyRows({
  selectedProductIds,
  freezeColumns,
  monthColumns,
  setCheckColumnWidth,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  checkedProducts,
  setCheckedProducts,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  selectedHierarchyIndexes,
  defineHierarchyToDisplay,
  headerHeight,
  currentMonth,
  unit,
  allHistoricPredictions,
  defineLeftCellBorder,
  predictionData,
}) {
  const { userInfo } = useContext(CompanyContext);
  const { historicForecasts } = useContext(ForecastContext);

  const companyName = userInfo.company.name;

  function getProductPredictionFromOfficialPastForecast(month, productId) {
    const officialPastForecast = historicForecasts.find(
      forecast => forecast.month === month.month && forecast.year === month.year
    );

    if (officialPastForecast) {
      const forecastPredictions = getForecastPredictions(officialPastForecast.forecastId, allHistoricPredictions);
      return getProductPrediction(month, productId, forecastPredictions);
    } else {
      return {};
    }
  }

  return (
    <>
      <AccuracySubtotalRow
        freezeColumns={freezeColumns}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        selectedProductIds={selectedProductIds}
        defineHierarchyToDisplay={defineHierarchyToDisplay}
        monthColumns={monthColumns}
        sumPreviousColumnsWidth={sumPreviousColumnsWidth}
        evaluateLastCategoryColumn={evaluateLastCategoryColumn}
        headerHeight={headerHeight}
        currentMonth={currentMonth}
        unit={unit}
        allHistoricPredictions={allHistoricPredictions}
        defineLeftCellBorder={defineLeftCellBorder}
        predictionData={predictionData}
      />
      {selectedProductIds.map((productId, index) => (
        <tr key={index}>
          <CheckCell
            individualProductCheck
            productId={productId}
            freezeColumns={freezeColumns}
            setCheckColumnWidth={setCheckColumnWidth}
            checkedProducts={checkedProducts}
            setCheckedProducts={setCheckedProducts}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
          />
          <HierarchyBodyCells
            freezeColumns={freezeColumns}
            productId={productId}
            sumPreviousColumnsWidth={sumPreviousColumnsWidth}
            setHierarchyColumnsWidth={setHierarchyColumnsWidth}
            showSeasonColumn={showSeasonColumn}
            evaluateLastCategoryColumn={evaluateLastCategoryColumn}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
            selectedHierarchyIndexes={selectedHierarchyIndexes}
            selectedProductIds={selectedProductIds}
          />
          {monthColumns.map((month, index) => (
            <BodyCells
              individualProduct
              columns={accuracyColumnsPast()}
              prediction={getProductPredictionFromOfficialPastForecast(month, productId)}
              productIds={selectedProductIds}
              monthIndex={index}
              unit={unit}
              key={index}
              month={month}
              defineLeftCellBorder={defineLeftCellBorder}
            />
          ))}
          <BodyCells
            individualProduct
            columns={accuracyColumnsCurrentMonth({ companyName })}
            prediction={getProductPrediction(currentMonth, productId, predictionData)}
            productIds={selectedProductIds}
            unit={unit}
            month={currentMonth}
            defineLeftCellBorder={defineLeftCellBorder}
          />
        </tr>
      ))}
    </>
  );
}
