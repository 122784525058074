import React, { memo } from "react";

function TableFades({
  freezeColumns,
  isScrollingDivAtBeginning,
  isScrollingDivAtEnd,
}) {
  return (
    <>
      {isScrollingDivAtBeginning() && !freezeColumns ? (
        <div className="table-fade-left"></div>
      ) : (
        <></>
      )}
      {isScrollingDivAtEnd() ? <></> : <div className="table-fade-right"></div>}
    </>
  );
}

export default memo(TableFades);
