import React, { useState, useContext } from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../../../templates/context/CompanyContext";
import { areObjectsEqual, capitalizeFirst } from "../../../../../utils/utils";
import { i18n } from "../../../../../translate/i18n";

function ProductsTable({ productsToDisplay, showSeasons }) {
  const { hierarchy } = useContext(CompanyContext);
  const [openProducts, setOpenProducts] = useState([]);
  const products = sortProductsAlphabetically();
  const columnLabels = showSeasons
    ? [...hierarchy.levels, "season"]
    : hierarchy.levels;

  function sortProductsAlphabetically() {
    return productsToDisplay.slice().sort((a, b) => {
      for (const level of hierarchy.levels) {
        if (a[level] < b[level]) return -1;
        if (a[level] > b[level]) return 1;
      }
      return 0;
    });
  }

  function isFirstProductOfThatLevel(productIndex, level, product) {
    return products[productIndex - 1]?.[level] !== product[level]
      ? true
      : false;
  }

  function isLastLevel(level) {
    return level === hierarchy.levels[hierarchy.levels.length - 1]
      ? true
      : false;
  }

  function isSeasonColumn(columnLabel) {
    return columnLabel === "season" ? true : false;
  }

  function hasAssociatedSeason(seasonObject) {
    return seasonObject?.name ? true : false;
  }

  function isAllowedToOpen(product, levelIndex) {
    if (levelIndex === 0) return true;
    else {
      const higherLevels = {};
      const levels = hierarchy.levels.slice(0, levelIndex);
      levels.forEach((level) => (higherLevels[level] = product[level]));
      return isProductInOpenProducts(higherLevels);
    }
  }

  function isProductInOpenProducts(productToCompare) {
    return openProducts.some((openProduct) =>
      areObjectsEqual(openProduct, productToCompare)
    );
  }

  function handleOpenProducts(product, levelIndex) {
    const newOpenProduct = createProductWithHigherAndSelectedLevels(
      product,
      levelIndex
    );

    const productsToRemove = [];
    openProducts.forEach((prod) => {
      if (isProductFromTheSameHierarchy(newOpenProduct, prod))
        productsToRemove.push(prod);
    });

    if (productsToRemove.length) removeFromOpenProducts(productsToRemove);
    else setOpenProducts([...openProducts, newOpenProduct]);
  }

  function isProductFromTheSameHierarchy(inputHierarchy, prod) {
    const keysFromInput = Object.keys(inputHierarchy);
    return keysFromInput.every(
      (key) => prod[key] && prod[key] === inputHierarchy[key]
    );
  }

  function removeFromOpenProducts(productsToRemove) {
    const newOpenProducts = [...openProducts];
    productsToRemove.forEach((productToRemove) => {
      const indexToRemove = newOpenProducts.findIndex((newOpenProduct) =>
        areObjectsEqual(newOpenProduct, productToRemove)
      );
      newOpenProducts.splice(indexToRemove, 1);
    });
    setOpenProducts(newOpenProducts);
  }

  function createProductWithHigherAndSelectedLevels(product, levelIndex) {
    const levels = hierarchy.levels.slice(0, levelIndex + 1);
    const newOpenProduct = {};
    levels.forEach((level) => (newOpenProduct[level] = product[level]));
    return newOpenProduct;
  }

  function determineIcon(product, index) {
    return isProductInOpenProducts(
      createProductWithHigherAndSelectedLevels(product, index)
    );
  }

  return (
    <div className="max-height-600px overflow-scroll width-fit-content max-width-100percent">
      <table className="table table-striped table-hover table-inner-div">
        <thead>
          <tr>
            {columnLabels.map((level, index) => (
              <th key={index}>
                <Text>
                  {hierarchy.labels[level]
                    ? hierarchy.labels[level]
                    : i18n.t("season")}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((product, productIndex) => {
            return hierarchy.levels.map((level, hierarchyLevelIndex) => {
              return isFirstProductOfThatLevel(productIndex, level, product) &&
                isAllowedToOpen(product, hierarchyLevelIndex) ? (
                <tr key={hierarchyLevelIndex}>
                  {columnLabels.map((columnLabel, columnIndex) => {
                    return level === columnLabel ? (
                      <td key={columnIndex}>
                        <div className="d-flex small-arrow-button">
                          {!isLastLevel(level) ? (
                            <Button
                              icon={
                                determineIcon(product, columnIndex)
                                  ? "chevron-down"
                                  : "chevron-right"
                              }
                              label=""
                              onClick={() =>
                                handleOpenProducts(product, columnIndex)
                              }
                              className="me-2"
                              type="ghost"
                            />
                          ) : (
                            <></>
                          )}
                          <Text>{capitalizeFirst(product[level])}</Text>
                        </div>
                      </td>
                    ) : (
                      <td key={columnIndex}>
                        {isLastLevel(level) &&
                        isSeasonColumn(columnLabel) &&
                        hasAssociatedSeason(product.season)
                          ? capitalizeFirst(product.season.name)
                          : ""}
                      </td>
                    );
                  })}
                </tr>
              ) : (
                <React.Fragment key={hierarchyLevelIndex} />
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ProductsTable;
