import React, { memo, useContext } from "react";
import { Heading, Text } from "@aidron/aidron-ds";
import { i18n } from "../../../../translate/i18n";
import { CompanyContext } from "../../../../templates/context/CompanyContext";

function HierarchyRegistration({ newLevelNames, setNewLevelNames }) {
  const { hierarchy } = useContext(CompanyContext);

  function editNewLevelNames(level, inputtedText) {
    const modifiedLevelNames = { ...newLevelNames, [level]: inputtedText };
    setNewLevelNames(modifiedLevelNames);
  }

  return (
    <>
      <Heading size="sm">{i18n.t("productHierarchy")}</Heading>
      <div className="ms-3 mt-3">
        <table className="table table-striped table-hover overflow-scroll max-height-400px width-fit-content table-inner-div">
          <thead>
            <tr>
              <th>
                <Text>{i18n.t("level")}</Text>
              </th>
              <th>
                <Text>{i18n.t("levelName")}</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {hierarchy.levels.map((level, index) => (
              <tr key={index}>
                <td className="vertical-align-middle pe-5">
                  <Text>{(index + 1).toString()}</Text>
                </td>
                <td>
                  <input
                    type="text"
                    value={newLevelNames[level]}
                    className={`textfield-box-shadow-on-hover height-30px gray-border border-radius-5px font-weight-bold ${
                      newLevelNames[level] === "" ? "red-border" : ""
                    }`}
                    onChange={(e) => editNewLevelNames(level, e.target.value)}
                  />
                  {newLevelNames[level] === "" ? (
                    <Text className="text-danger" size="xs">
                      {i18n.t("typeValidName")}
                    </Text>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default memo(HierarchyRegistration);
