import React, { useContext, useState } from "react";
import { NavBar, Icon } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import * as S from "./Header.style";

import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import Calendar from "../../../pages/Calendar/Calendar";
import AccountHeaderMenu from "../../AccountHeaderMenu/AccountHeaderMenu";

export default function Header() {
  const { userInfo } = useContext(CompanyContext);
  const { company } = userInfo;
  const [showCalendar, setShowCalendar] = useState(false);

  const menuList = [
    {
      menuName: "Home",
      route: "/home",
    },
    {
      menuName: "Oportunidades",
      route: "/oportunidades",
      pages: [
        {
          pageName: "Oportunidades de Produto",
          route: "/produtos",
        },
        {
          pageName: "Oportunidades de Canais",
          route: company.id === 4 ? "/canais" : "",
        },
        {
          pageName: "Insights de Mercado",
          route: company.id === 4 ? "/mercado" : "",
        },
      ],
    },
    {
      menuName: "Análise",
      route: "/analise",
      pages: [
        { pageName: "Produto: Plano x Forecast", route: "/plano" },
        {
          pageName: "Canais: Meta x Forecast",
          route: company.id === 4 ? "/canais" : "Aguardando dados",
        },
        { pageName: "Assertividade do Forecast", route: "/assertividadePlano" },
      ],
    },
    {
      menuName: "Ações",
      route: "/acoes",
      pages: [
        { pageName: "Checkout de Oportunidades", route: "/checkout" },
        { pageName: "Simular Ações", route: "" },
      ],
    },
  ];

  return (
    <NavBar home>
      <S.LogoContainer>
        <img src="/img/logo-aidron-branco.png" alt="Aidron" className="logo" />
      </S.LogoContainer>
      <S.MenuContainer>
        {menuList.map((item, index) => (
          <HeaderMenu item={item} key={index} />
        ))}
      </S.MenuContainer>
      <S.CalendarButton onClick={() => setShowCalendar(true)}>
        <Icon icon="calendar" size={30} />
      </S.CalendarButton>
      <AccountHeaderMenu />
      {showCalendar && <Calendar setShowCalendar={setShowCalendar} />}
    </NavBar>
  );
}
