import React, { useContext, useRef, useEffect } from "react";
import { Button, Text } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";
import { CompanyContext } from "../../templates/context/CompanyContext";

function HierarchyHeaderCells({
  rowSpan,
  freezeColumns,
  sumPreviousColumnsWidth,
  sortRowsBy,
  setSortRowsBy,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  monthHeaderCellHeight,
  defineHierarchyToDisplay,
  setHierarchyCellHeight,
  columns,
  storesTable,
}) {
  const { hierarchy } = useContext(CompanyContext);
  const hierarchyLabelCellRef = useRef(null);
  const indexOfSeasonColumn = hierarchy.levels.length;

  useEffect(() => {
    if (setHierarchyCellHeight) {
      setHierarchyCellHeight(
        hierarchyLabelCellRef.current?.getBoundingClientRect().height
      );
    }
  }, [columns]);

  function isAlreadySortedByThisColumn(newSortBy) {
    if (!sortRowsBy.columnLabel) return false;

    return sortRowsBy.columnLabel === newSortBy.columnLabel ? true : false;
  }

  function updateSortBy(newSortBy) {
    if (isAlreadySortedByThisColumn(newSortBy))
      setSortRowsBy({ ...sortRowsBy, ascending: !sortRowsBy.ascending });
    else setSortRowsBy({ ...newSortBy, ascending: true });
  }

  function determineColumnIcon(columnLabel) {
    if (sortRowsBy.columnLabel === columnLabel) {
      return sortRowsBy.ascending ? "arrow-up" : "arrow-down";
    } else return "chevron-down";
  }

  function cellRightBorder(index) {
    return !freezeColumns && index === defineHierarchyToDisplay().length - 1
      ? "cell-right-border"
      : "";
  }

  return (
    <>
      {defineHierarchyToDisplay().map((level, index) => (
        <th
          rowSpan={rowSpan}
          key={index}
          className={`category-th cell-bottom-border subtitle-cell z-3 background-color-white vertical-align-middle width-12px-on-icon height-12px-on-icon ${evaluateLastCategoryColumn(
            index
          )} ${cellRightBorder(index)}`}
          style={{
            position: freezeColumns ? "sticky" : "",
            top: `${monthHeaderCellHeight}px`,
            left: freezeColumns ? `${sumPreviousColumnsWidth(index)}px` : "",
          }}
          ref={hierarchyLabelCellRef}
        >
          <div className="row">
            <div
              className={`col d-flex align-items-center small-arrow-button ${
                storesTable && index === 0 ? "ps-4" : ""
              }`}
            >
              <Text>{i18n.t(`${hierarchy.labels[level]}`)}</Text>
              {/* {!storesTable ? (
                <Button
                  label=""
                  onClick={() => updateSortBy({ columnLabel: level })}
                  type="ghost"
                  icon={!storesTable ? determineColumnIcon(level) : ""}
                  className="ms-1"
                />
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </th>
      ))}
      {showSeasonColumn && (
        <th
          className={`category-th cell-bottom-border subtitle-cell z-3 background-color-white vertical-align-middle ${
            freezeColumns ? "right-border-fade" : ""
          }`}
          style={{
            position: freezeColumns ? "sticky" : "",
            top: `${monthHeaderCellHeight}px`,
            left: freezeColumns
              ? `${sumPreviousColumnsWidth(indexOfSeasonColumn)}px`
              : "",
          }}
        >
          <div className="row">
            <div className="col d-flex align-items-center small-arrow-button">
              <Text>{i18n.t("season")}</Text>
              <Button
                label=""
                onClick={() => updateSortBy({ columnLabel: "season" })}
                type="ghost"
                icon={determineColumnIcon("season")}
                className="ms-1"
              />
            </div>
          </div>
        </th>
      )}
    </>
  );
}

export default HierarchyHeaderCells;
