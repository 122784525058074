import React from "react";
import { Heading, Text, Card } from "@aidron/aidron-ds";

import { toNDecimalsString } from "../../../../../utils/utils";

import CardOutlined from "../../../../../components/CardOutlined/CardOutlined";
import TextAndLabelView from "../TextAndLabelView/TextAndLabelView";

import grafico from "./grafico-mock.png";

import * as S from "./OpportunityAccordion.style";
import ProductHierarchyHeading from "../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import MockTable from "./MockTable/MockTable";

export default function OpportunityAccordion({ opportunity, opportunityDetails }) {
  const { value, percent, text } = opportunity;
  const { percentLabel } = opportunityDetails;

  return (
    <CardOutlined>
      <header className="m-4">
        <Heading size="md" className="mb-2">
          {`Oportunidade de ${opportunityDetails.title}`}
        </Heading>
        <p className="mb-1">{opportunityDetails.summary}</p>
      </header>

      <S.Section>
        <div className="d-flex flex-column justify-content-around m-4">
          <div className="d-flex justify-content-around me-3">
            <TextAndLabelView
              label={percentLabel}
              labelSize="font-size-19px"
              text={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent))}%`}
              textSize="xl"
            />
            <TextAndLabelView
              label="Tamanho da Oportunidade"
              labelSize="font-size-19px"
              text={`R$ ${toNDecimalsString(value, 0)}`}
              textSize="xl"
            />
          </div>
          <div className="d-flex justify-content-center my-5 mx-4">
            <Text className="font-size-25px text-center">{text}</Text>
          </div>
          {/* <div className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <Text>Este produto possui outras oportunidades</Text>
              <ButtonWithModal
                label="Analisar Oportunidades"
                type="outlined"
                renderModalContent={() => {}}
              />
            </div>
          </div> */}
        </div>
        {/* <OpportunityChart opportunity={opportunity} /> */}
        <Card>
          <ProductHierarchyHeading productId={opportunity.productId} useMockedStores={true} />
          {opportunity.type1 === "Aumento da Meta" || opportunity.type1 === "Ajuste na Meta" ? <img src={grafico} /> : <MockTable />}
        </Card>
      </S.Section>
    </CardOutlined>
  );
}
