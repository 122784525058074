import styled from "styled-components";

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 11px 0px;
  min-width: fit-content;
  margin: 0 0.25rem 0.75rem 0.25rem;
  padding: 0 1rem 0.5rem 1rem;
`;

const TitleContainer = styled.div`
  width: 110px;
  text-align: center;
`;

const NumberContainer = styled.div`
  text-align: center;
`;

export { CustomContainer, TitleContainer, NumberContainer };
