import React, { useEffect, useState } from "react";
import { Text } from "@aidron/aidron-ds";

import { channelAccuracyProductColumns } from "../../../utils/tableColumns";
import { getProductIdsFromStore } from "../../../services/StorePredictionsService";

import HierarchyBodyCells from "../../../components/HierarchyBodyCells/HierarchyBodyCells";
import BodyCell from "../../../components/BodyCell/BodyCell";
import PercentBodyCell from "../../../components/PercentBodyCell/PercentBodyCell";
import PaginationButtons from "../../../components/PaginationButtons/PaginationButtons";

export default function ChannelAccuracyProductRows({
  store,
  freezeColumns,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  predictionData,
  defineHierarchyToDisplay,
  selectedHierarchyIndexes,
  storesTable,
  selectedUnit,
  columns,
  sortRowsBy,
  selectedProductIds,
}) {
  const [productIdsToDisplay, setProductIdsToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const productResultsPerPage = 10;

  const columnsSpan =
    defineHierarchyToDisplay().length +
    columns.length *
      channelAccuracyProductColumns({ unit: selectedUnit }).length;

  function getProductValue(store, productId, columnName, column) {
    const prediction = predictionData.find(
      (prediction) =>
        prediction.store === store &&
        prediction.productId === productId &&
        prediction.week === column.weeks[0]
    );

    return Number(prediction?.[columnName]);
  }

  useEffect(() => {
    fetchProductsData();
  }, [currentPage, sortRowsBy, selectedProductIds]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedProductIds]);

  async function fetchProductsData() {
    const token = localStorage.getItem("token");
    const onlyWeeklyPredictions = true;
    const result = await getProductIdsFromStore(
      token,
      store,
      selectedProductIds,
      sortRowsBy,
      currentPage,
      productResultsPerPage,
      onlyWeeklyPredictions
    );

    setProductIdsToDisplay(result.results);
    if (!pagination.lastPage) {
      setPagination({ lastPage: result.lastPage });
    }
  }

  return selectedProductIds.length > 0 && productIdsToDisplay.length === 0 ? (
    <tr>
      <td colSpan={columnsSpan.toString()}>
        <Text weight="bold" className="font-size-16px ps-3">
          Não há histórico dos produtos selecionados nessa loja. Tente alterar
          os filtros.
        </Text>
      </td>
    </tr>
  ) : (
    <>
      {productIdsToDisplay.map((productId, index) => (
        <tr key={index}>
          <HierarchyBodyCells
            freezeColumns={freezeColumns}
            productId={productId}
            sumPreviousColumnsWidth={sumPreviousColumnsWidth}
            setHierarchyColumnsWidth={setHierarchyColumnsWidth}
            showSeasonColumn={showSeasonColumn}
            evaluateLastCategoryColumn={evaluateLastCategoryColumn}
            productRows={predictionData}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
            selectedHierarchyIndexes={selectedHierarchyIndexes}
            storesTable={storesTable}
            unit={selectedUnit}
            selectedProductIds={productIdsToDisplay}
          />
          {columns.map((column, index) =>
            channelAccuracyProductColumns({ unit: selectedUnit }).map(
              (col, i, arr) => (
                <td
                  key={i}
                  className={`text-center ${
                    index === columns.length - 1
                      ? ""
                      : i === arr.length - 1
                      ? "cell-right-border"
                      : ""
                  }`}
                >
                  {col.type === "prediction" && (
                    <BodyCell
                      prediction={getProductValue(
                        store,
                        productId,
                        col.name,
                        column
                      )}
                      format={selectedUnit}
                    />
                  )}
                  {col.type !== "prediction" && (
                    <PercentBodyCell
                      prediction={getProductValue(
                        store,
                        productId,
                        col.name,
                        column
                      )}
                      showFlag={col.type === "dif%"}
                    />
                  )}
                </td>
              )
            )
          )}
        </tr>
      ))}
      <tr>
        <td colSpan={columnsSpan.toString()}>
          <div className="d-flex justify-content-end">
            <PaginationButtons
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pagination={pagination}
            />
          </div>
        </td>
      </tr>
    </>
  );
}
