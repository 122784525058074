import React, { useEffect, useCallback } from "react";
import { TextField, Icon, Text } from "@aidron/aidron-ds";

import * as S from "./SearchInput.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";

export default function SearchInput({ inputtedExpressions, setInputtedExpressions, typedExpression, setTypedExpression }) {
  function removeExpression(index) {
    inputtedExpressions.splice(index, 1);
    setInputtedExpressions([...inputtedExpressions]);
    setTypedExpression("");
  }

  function search() {
    setInputtedExpressions([typedExpression]);
  }

  function cutExpression(words) {
    const wordsArray = words.split("");
    const initialWords = wordsArray.slice(0, 21);
    return initialWords.join("") + "...";
  }

  const enterClick = useCallback(
    e => {
      if (e.keyCode === 13 && typedExpression) {
        search();
      }
    },
    [typedExpression]
  );

  useEffect(() => {
    document.addEventListener("keydown", enterClick);
    return () => {
      document.removeEventListener("keydown", enterClick);
    };
  }, [enterClick]);

  return (
    <>
      <S.OuterContainer>
        <Text size="sm" weight="bold">
          Buscar por hierarquia
        </Text>
        <S.InnerContainer>
          <S.StyledTextField>
            <TextField onChange={setTypedExpression} label="Digite..." placeholder="" value={typedExpression} />
          </S.StyledTextField>
          <S.SearchButton
            label=""
            onClick={() => {
              if (typedExpression) search();
            }}
            icon="search"
            type="outlined"
          />
        </S.InnerContainer>
        {inputtedExpressions.map((expression, index) => (
          <S.InputtedWordWrapper key={index}>
            <CustomText color="orange" weight="bold">
              {expression.length > 21 ? cutExpression(expression) : expression}
            </CustomText>
            <S.IconWrapper onClick={() => removeExpression(index)}>
              <Icon icon="x" size={16} color="color-primary-medium" />
            </S.IconWrapper>
          </S.InputtedWordWrapper>
        ))}
      </S.OuterContainer>
    </>
  );
}
