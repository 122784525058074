import React from "react";

import ListItem from "./ListItem/ListItem";

export default function OpportunityList({ opportunities, onItemClick, selectedId, fetchData }) {
  return (
    <>
      {opportunities.map((opportunity, index) => (
        <ListItem opportunity={opportunity} onItemClick={onItemClick} selectedId={selectedId} fetchData={fetchData} key={index} />
      ))}
    </>
  );
}
