import React from "react";

import * as S from "./PlanVsForecastChartCarousel.style";

import Carousel from "../../../../components/Carousel/Carousel";
import ProductAnalysisChart from "../../../../components/ProductAnalysisChart/ProductAnalysisChart";

export default function PlanVsForecastChartCarousel({
  checkedProducts,
  monthColumns,
  historicMonthsToDisplay,
  allHistoricPredictions,
  predictionData,
}) {
  return (
    <Carousel slideNumber={checkedProducts.length}>
      {checkedProducts.map((id, index) => (
        <S.OuterContainer className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
          <ProductAnalysisChart
            predictionData={predictionData}
            productId={id}
            historicMonthsToDisplay={historicMonthsToDisplay}
            monthColumns={monthColumns}
            allHistoricPredictions={allHistoricPredictions}
          />
        </S.OuterContainer>
      ))}
    </Carousel>
  );
}
