import React, { memo, useContext, useState } from "react";
import * as XLSX from "xlsx";
import { i18n } from "../../../../../translate/i18n";
import { getSeasonName } from "../../../../../utils/utils";
import { CompanyContext } from "../../../../../templates/context/CompanyContext";

import ImportModal from "../../../ImportModal/ImportModal";
import ProductsTable from "../ProductsTable/ProductsTable";

function ProductsImport({ setShowImportModal, onSaveProducts }) {
  const { hierarchy, allSeasons } = useContext(CompanyContext);
  const [newProducts, setNewProducts] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  function downloadTemplate() {
    const productsSheetData = [
      [
        ...hierarchyLabels(),
        `${i18n.t("seasonNumber")} (${i18n.t("optional").toLowerCase()})`,
      ],
      [...hierarchyLabelsExample(), `1 ${i18n.t("consultOnSeasonsSheet")}`],
      [...hierarchyLabelsExample()],
    ];

    const seasonsSheetData = [
      [i18n.t("seasonNumber"), i18n.t("seasonName")],
      ...seasonsArrays(),
    ];

    const productSheet = XLSX.utils.aoa_to_sheet(productsSheetData);
    const seasonsSheet = XLSX.utils.aoa_to_sheet(seasonsSheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, productSheet, i18n.t("products"));
    XLSX.utils.book_append_sheet(wb, seasonsSheet, i18n.t("seasonNumbers"));

    XLSX.writeFile(wb, i18n.t("importProducts") + ".xlsx");
  }

  function hierarchyLabels() {
    return Object.values(hierarchy.labels);
  }

  function hierarchyLabelsExample() {
    return hierarchy.levels.map((level) => i18n.t("example"));
  }

  function seasonsArrays() {
    return allSeasons.map((season) => {
      return [season.id, season.name];
    });
  }

  function readImportFile(workbook) {
    const productsSheet = workbook.Sheets[i18n.t("products")];
    const productsData = XLSX.utils.sheet_to_json(productsSheet);

    if (!productsData.length) handleFileError();
    else {
      const validatedNewProducts = validateNewProducts(productsData);

      if (validatedNewProducts) return formatNewProducts(productsData);
      else handleFileError();
    }
  }

  function handleFileError() {
    setShowErrorModal(true);
    setUploadedFile(0);
  }

  function validateNewProducts(productsData) {
    if (!areSeasonIdsValid(productsData)) return false;
    return true;
  }

  function areSeasonIdsValid(productsData) {
    const allSeasonIds = allSeasons.map((season) => season.id);
    return productsData.every(
      (product) =>
        !isNaN(product[i18n.t("seasonNumber")]) &&
        allSeasonIds.includes(product[i18n.t("seasonNumber")])
    );
  }

  function formatNewProducts(productsData) {
    return productsData.map((product) => {
      const buildingProduct = {};
      for (let key in product) {
        if (key !== i18n.t("seasonNumber")) {
          const index = Object.values(hierarchy.labels).indexOf(key);
          buildingProduct[hierarchy.levels[index]] = product[key];
        }
      }
      buildingProduct.season = {
        id: product[i18n.t("seasonNumber")],
        name: getSeasonName(allSeasons, product[i18n.t("seasonNumber")]),
      };
      return buildingProduct;
    });
  }

  return (
    <ImportModal
      setShowImportModal={setShowImportModal}
      onSave={onSaveProducts}
      downloadTemplate={downloadTemplate}
      readImportFile={readImportFile}
      handleFileError={handleFileError}
      newDataToImport={newProducts}
      setNewDataToImport={setNewProducts}
      uploadedFile={uploadedFile}
      setUploadedFile={setUploadedFile}
      showErrorModal={showErrorModal}
      setShowErrorModal={setShowErrorModal}
    >
      <ProductsTable productsToDisplay={newProducts} showSeasons={true} />
    </ImportModal>
  );
}

export default memo(ProductsImport);
