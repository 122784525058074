import React, { useState } from "react";
import { Button, Radio, RadioOption, Text } from "@aidron/aidron-ds";

import { updateOpportunities } from "../../../../../services/OpportunityService";

import * as S from "./ActionModal.style";

import ModalTemplate from "../../../../../components/ModalTemplate/ModalTemplate";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";
import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import RemovalReason from "./RemovalReason/RemovalReason";

export default function ActionModal({ onClose, opportunityId, fetchData, status }) {
  const [selectedId, setSelectedId] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [removalReason, setRemovalReason] = useState("");

  const token = localStorage.getItem("token");

  const analyseOption = {
    id: 1,
    title: "Oportunidade será analisada",
    text: 'Ao selecionar esta opção, a oportunidade receberá o status "Checkout".',
    status: "checkout",
    confirmationMessage: "A oportunidade foi enviada para Checkout de Ações!",
  };

  const removeOption = {
    id: 2,
    title: "Não é uma oportunidade",
    text: "Ao selecionar esta opção, você poderá descrever o motivo da exclusão, e a oportunidade será removida da lista de oportunidades.",
    status: "removed",
    confirmationMessage: "A oportunidade foi removida.",
  };

  const removeFromCheckoutOption = {
    id: 3,
    title: "Retirar do Checkout",
    text: 'Ao selecionar esta opção, a oportunidade permanecerá na lista de oportunidades com o status "Ativo".',
    status: "active",
    confirmationMessage: "A oportunidade foi retirada da lista de Checkout.",
  };

  const standardOptions = [analyseOption, removeOption];

  const checkoutOptions = [removeFromCheckoutOption, removeOption];

  const options = status === "checkout" ? checkoutOptions : standardOptions;

  const selected = options.find(option => option.id === selectedId);

  function onConcludedClick() {
    if (selectedId === 0) {
      onClose();
    } else {
      updateStatus();
    }
  }

  function onConfirmationModalClose() {
    setSelectedId(0);
    setConfirmationMessage("");
    onClose();
    fetchData();
  }

  async function updateStatus() {
    const { status, confirmationMessage } = selected;
    const updatedOpportunity = { id: opportunityId, status: status, [`${status}Date`]: new Date() };

    if (removalReason) {
      updatedOpportunity.removalReason = removalReason;
    }

    const result = await updateOpportunities(token, [updatedOpportunity]);

    if (result.status === 200) {
      setConfirmationMessage(confirmationMessage);
    } else {
      setConfirmationMessage("Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.");
    }
  }

  return (
    <>
      <S.OuterContainer>
        <Radio
          label=""
          selected={selectedId}
          handleChange={e => {
            setSelectedId(e);
          }}
          className="d-flex flex-column"
        >
          {options.map(option => (
            <S.OptionContainer>
              <RadioOption value={option.id} key={option.id}>
                <Text weight="bold">{option.title}</Text>
              </RadioOption>
              <CustomText margin="0 0 0 2.5rem" size="xs">
                {option.text}
              </CustomText>
            </S.OptionContainer>
          ))}
        </Radio>

        {selectedId === 2 && <RemovalReason removalReason={removalReason} setRemovalReason={setRemovalReason} />}
      </S.OuterContainer>

      <S.FinishButtonContainer>
        <Button label="Finalizar" onClick={onConcludedClick} />
      </S.FinishButtonContainer>

      {confirmationMessage && (
        <ModalTemplate width="300px" closingFunction={onConfirmationModalClose} heading=" ">
          <CustomHeading size="sm" margin="1.5rem 0 0 0" textAlign="center">
            {confirmationMessage}
          </CustomHeading>
          <S.ConfirmationButtonContainer>
            <Button label="Ok" onClick={onConfirmationModalClose} />
          </S.ConfirmationButtonContainer>
        </ModalTemplate>
      )}
    </>
  );
}
