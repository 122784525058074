import { createContext, useState, useEffect } from "react";
import { getProducts } from "../../services/ProductsService";
import { getSettings } from "../../services/SettingsService";

export const CompanyContext = createContext();

export default function CompanyProvider({ children }) {
  const [userInfo, setUserInfo] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [hierarchy, setHierarchy] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function getHierarchyNames(res) {
    const hierarchyNames = { ...res.company };

    for (let key in hierarchyNames) {
      if (!key.includes("level")) {
        delete hierarchyNames[key];
      }
    }

    return {
      levels: Object.keys(hierarchyNames).sort((a, b) => a.localeCompare(b)),
      labels: hierarchyNames,
    };
  }

  async function fetchData() {
    const token = localStorage.getItem("token");

    try {
      const promises = await Promise.all([getSettings(token), getProducts(token)]);

      setUserInfo(promises[0]);
      setAllProducts(promises[1]);
      setHierarchy(getHierarchyNames(promises[0]));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        userInfo,
        allProducts,
        hierarchy,
        setAllProducts,
        setUserInfo,
      }}
    >
      {!isLoading ? children : <></>}
    </CompanyContext.Provider>
  );
}
