import styled from "styled-components";
import { Icon } from "@aidron/aidron-ds";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Wrapper = styled.div`
  width: 1fr 1fr 1fr;
  min-width: 80%;

  @media (min-width: 639px) {
    min-width: 28%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
`;

const ChevronIcon = styled(Icon)`
  color: #e75d0d;
`;

export { Wrapper, InnerContainer, StyledLink, ChevronIcon };
