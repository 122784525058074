import React from "react";
import * as S from "./ProductListItems.style";
import ListItem from "./ListItem/ListItem";

export default function ProductListItems({ opportunities }) {
  return (
    <S.wrapperList>
      {opportunities.map((opportunity) => (
        <ListItem key={opportunity.id} opportunity={opportunity} />
      ))}
    </S.wrapperList>
  );
}
