import React, { useMemo, useState, useContext, useEffect } from "react";
import { Button, Text } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import { channelAccuracyColumns } from "../../../utils/tableColumns";
import { capitalizeFirst } from "../../../utils/utils";

import TableColumnTitle from "../../../components/TableColumnTitle/TableColumnTitle";
import PercentBodyCell from "../../../components/PercentBodyCell/PercentBodyCell";
import ColumnTitlesRow from "../../../components/ColumnTitlesRow/ColumnTitlesRow";
import TableControls from "../../../components/TableControls/TableControls";
import ChannelAccuracyProductRows from "../ChannelAccuracyProductRows/ChannelAccuracyProductRows";
import HierarchyHeaderCells from "../../../components/HierarchyHeaderCells/HierarchyHeaderCells";
import ColumnLabelCell from "../../../components/ColumnLabelCell/ColumnLabelCell";
import WeekBlockSortByButton from "../WeekBlockSortByButton/WeekBlockSortByButton";
import BodyCell from "../../../components/BodyCell/BodyCell";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";

export default function ChannelAccuracyTable({
  freezeColumns,
  sortRowsBy,
  setSortRowsBy,
  showSeasonColumn,
  selectedHierarchyIndexes,
  predictionData,
  selectedProductIds,
  currentPage,
  selectedHistoricWeekIds,
  weekBlocks,
  storesResults,
}) {
  const { hierarchy } = useContext(CompanyContext);
  const [hierarchyColumnsWidth, setHierarchyColumnsWidth] = useState([]);
  const [monthColumnsWidth, setMonthColumnsWidth] = useState([]);
  const [monthHeaderCellHeight, setMonthHeaderCellHeight] = useState(0);
  const [openStores, setOpenStores] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("items");

  const unitOptions = [
    { value: "items", displayName: "Em Peças" },
    { value: "currency", displayName: "Em R$" },
  ];

  const monthColumnsPosition = monthColumnsWidth.map((month, index) =>
    sumPreviousColumnsWidth(index, monthColumnsWidth)
  );

  const storesWithDuplicates = predictionData.map(
    (prediction) => prediction.store
  );
  const stores = [...new Set(storesWithDuplicates)];

  const weekColumns = useMemo(defineWeekColumns, [predictionData]);

  const initialScrollIndex = monthColumnsPosition[weekColumns.length - 1];

  function defineWeekColumns() {
    return weekBlocks.filter((weekBlock) =>
      selectedHistoricWeekIds.includes(weekBlock.forecastId)
    );
  }

  useEffect(() => {
    setOpenStores([]);
  }, [sortRowsBy, currentPage]);

  function getWeekPredictions(weeks, allPredictions, forecastId) {
    return allPredictions.filter(
      (prediction) =>
        weeks.includes(prediction.week) && forecastId === prediction.forecastId
    );
  }

  function getValue(column, columnName, store) {
    let weekPredictions = getWeekPredictions(
      column.weeks,
      predictionData,
      column.forecastId
    );
    if (store) {
      weekPredictions = weekPredictions.filter(
        (prediction) => store === prediction.store
      );
    }
    return weekPredictions.reduce((acc, curr) => {
      if (!isNaN(Number(curr[columnName])))
        return acc + Number(curr[columnName]);
      else return acc;
    }, 0);
  }

  function getStoreParticipation(column, store) {
    const unitSuffix = capitalizeFirst(selectedUnit);
    const indexName = `participationInRealized${unitSuffix}`;
    if (storesResults.length > 0) {
      const forecastIdObj = storesResults.find(
        (obj) => obj.forecastId === column.forecastId
      );
      const index = forecastIdObj?.storesIndexes.find(
        (s) => s.store === store
      )?.[indexName];
      if (index) {
        return index;
      } else return 0;
    } else return 0;
  }

  function getPercentDifference(column, columnLabel, store) {
    const firstIndexSum = getValue(column, columnLabel.indexOne, store);
    const secondIndexSum = getValue(column, columnLabel.indexTwo, store);
    return ((firstIndexSum - secondIndexSum) / secondIndexSum) * 100;
  }

  function evaluateLastCategoryColumn(index) {
    return freezeColumns &&
      !showSeasonColumn &&
      index === defineHierarchyToDisplay().length - 1
      ? "right-border-fade"
      : "";
  }

  function sumPreviousColumnsWidth(currentColumnIndex, columnsWidth) {
    const previousColumnsWidth = columnsWidth
      ? columnsWidth
      : [0].concat(hierarchyColumnsWidth);
    let sum = 0;
    for (let i = 0; i <= currentColumnIndex; i++) {
      sum += previousColumnsWidth[i];
    }
    return sum;
  }

  function defineHierarchyToDisplay() {
    return hierarchy.levels.filter((level, index) =>
      selectedHierarchyIndexes.includes(index)
    );
  }

  function defineLeftCellBorder(monthIndex, columnIndex) {
    if (columnIndex !== 0) return false;
    if (monthIndex === 0) {
      return false;
    } else return true;
  }

  function defineStoreNameCellSpan() {
    let columnsSpan = defineHierarchyToDisplay().length;
    if (showSeasonColumn) columnsSpan += 1;
    return columnsSpan;
  }

  function determineIcon(store) {
    return openStores.includes(store);
  }

  function handleOpenStores(store) {
    if (openStores.includes(store)) {
      const filteredOpenStores = openStores.filter(
        (openStore) => openStore !== store
      );
      setOpenStores(filteredOpenStores);
    } else {
      setOpenStores([...openStores, store]);
    }
  }

  function writeColumnTitle(weekObj) {
    const firstWeek = weekObj.weeks[0];
    const lastWeek = weekObj.weeks[weekObj.weeks.length - 1];
    const nextYear = weekObj.year + 1;
    const nextYearAbbreviation = nextYear.toString().slice(-2);
    const appendYear = lastWeek < firstWeek ? `/${nextYearAbbreviation}` : "";

    return `Semanas ${firstWeek} a ${lastWeek} de ${weekObj.year}${appendYear}`;
  }

  return (
    <TableWrapper
      resultCondition={() => selectedProductIds.length}
      loadingCondition={() => predictionData.length}
    >
      <TableControls
        freezeColumns={freezeColumns}
        columns={weekColumns}
        columnsWidth={monthColumnsWidth}
        columnsPosition={monthColumnsPosition}
        initialScrollIndex={initialScrollIndex}
        selectedUnit={selectedUnit}
        setSelectedUnit={setSelectedUnit}
        unitOptions={unitOptions}
      >
        <table
          id="download-table"
          className="table table-striped table-hover padding-070rem-on-th-and-td"
        >
          <thead>
            <ColumnTitlesRow
              freezeColumns={freezeColumns}
              showSeasonColumn={showSeasonColumn}
              monthColumns={weekColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              setMonthHeaderCellHeight={setMonthHeaderCellHeight}
              setMonthColumnsWidth={setMonthColumnsWidth}
              monthHeaderCellsSpan={
                channelAccuracyColumns({ unit: selectedUnit }).length
              }
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              defineLeftCellBorder={defineLeftCellBorder}
              renderColumnTitleLabel={({ month }) => (
                <TableColumnTitle label={writeColumnTitle(month)} />
              )}
              storesTable
              productCellRowSpan={openStores.length > 0 ? "1" : "3"}
              title="Produtos por Loja"
            />
            <tr>
              {openStores.length > 0 && (
                <HierarchyHeaderCells
                  rowSpan="2"
                  freezeColumns={freezeColumns}
                  sumPreviousColumnsWidth={sumPreviousColumnsWidth}
                  sortRowsBy={sortRowsBy}
                  setSortRowsBy={setSortRowsBy}
                  showSeasonColumn={showSeasonColumn}
                  evaluateLastCategoryColumn={evaluateLastCategoryColumn}
                  monthHeaderCellHeight={monthHeaderCellHeight}
                  defineHierarchyToDisplay={defineHierarchyToDisplay}
                  columns={weekColumns}
                  storesTable
                />
              )}
              {weekColumns.map((weekObj, index) =>
                channelAccuracyColumns({
                  unit: selectedUnit,
                }).map((column, i) => (
                  <ColumnLabelCell
                    linesSpan="2"
                    column={column}
                    upperCellsHeight={monthHeaderCellHeight}
                    cellLeftBorder={defineLeftCellBorder(index, i)}
                    cellBottomBorder
                    renderSortByButton={
                      column.type !== "dif%" ? (
                        <WeekBlockSortByButton
                          column={column}
                          weekObj={weekObj}
                          sortRowsBy={sortRowsBy}
                          setSortRowsBy={setSortRowsBy}
                        />
                      ) : (
                        <></>
                      )
                    }
                    key={i}
                  />
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {stores.map((store, storeIndex) => (
              <React.Fragment key={storeIndex}>
                <tr>
                  <td
                    colSpan={defineStoreNameCellSpan()}
                    className={
                      freezeColumns
                        ? "right-border-fade position-sticky left-0 background-color-white"
                        : "cell-right-border"
                    }
                  >
                    <div className="d-flex small-arrow-button">
                      <Button
                        icon={
                          determineIcon(store)
                            ? "chevron-down"
                            : "chevron-right"
                        }
                        label=""
                        onClick={() => handleOpenStores(store)}
                        className="me-2"
                        type="ghost"
                      />
                      <Text>{store}</Text>
                    </div>
                  </td>
                  {weekColumns.map((weekObj, index) =>
                    channelAccuracyColumns({ unit: selectedUnit }).map(
                      (col, i, arr) => (
                        <td
                          className={`text-center ${
                            index === weekColumns.length - 1
                              ? ""
                              : i === arr.length - 1
                              ? "cell-right-border"
                              : ""
                          }`}
                          key={i}
                        >
                          {col.type === "prediction" && (
                            <BodyCell
                              prediction={getValue(weekObj, col.name, store)}
                              format={selectedUnit}
                            />
                          )}
                          {col.type === "%" && (
                            <PercentBodyCell
                              prediction={getStoreParticipation(weekObj, store)}
                            />
                          )}
                          {col.type === "dif%" && (
                            <PercentBodyCell
                              prediction={getPercentDifference(
                                weekObj,
                                col,
                                store
                              )}
                              showFlag
                            />
                          )}
                        </td>
                      )
                    )
                  )}
                </tr>
                {openStores.includes(store) && (
                  <ChannelAccuracyProductRows
                    store={store}
                    freezeColumns={freezeColumns}
                    sumPreviousColumnsWidth={sumPreviousColumnsWidth}
                    setHierarchyColumnsWidth={setHierarchyColumnsWidth}
                    showSeasonColumn={showSeasonColumn}
                    evaluateLastCategoryColumn={evaluateLastCategoryColumn}
                    predictionData={predictionData}
                    defineHierarchyToDisplay={defineHierarchyToDisplay}
                    selectedHierarchyIndexes={selectedHierarchyIndexes}
                    storesTable
                    selectedUnit={selectedUnit}
                    columns={weekColumns}
                    sortRowsBy={sortRowsBy}
                    selectedProductIds={selectedProductIds}
                  />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableControls>
    </TableWrapper>
  );
}
