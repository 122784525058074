import React, { useState } from "react";
import { Card, Text, Icon } from "@aidron/aidron-ds";

import * as S from "./SecondaryChartsSection.style";

import SecondaryCharts from "./SecondaryCharts/SecondaryCharts";

export default function SecondaryChartsSection({ selected, predictions, products, defineProductFilter }) {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <>
      <Card style={{ marginBottom: "10px" }}>
        <Text weight="bold">Quebra do Agrupamento Principal</Text>
        <S.AccordionButton onClick={() => setOpenAccordion(!openAccordion)} label="">
          <Icon size="16" icon={openAccordion ? "chevron-up" : "chevron-down"} />
        </S.AccordionButton>
      </Card>
      {openAccordion && (
        <SecondaryCharts
          selected={selected}
          predictions={predictions}
          products={products}
          defineProductFilter={defineProductFilter}
        />
      )}
    </>
  );
}
