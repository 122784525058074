import styled from "styled-components";
import { Heading } from "@aidron/aidron-ds";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ smallGap }) => (smallGap ? "20px" : "40px")};
  padding: 20px 10px;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  padding-bottom: ${({ noPaddingBottom }) => (noPaddingBottom ? "0" : "40px")};
`;

const CustomHeading = styled(Heading)`
  color: ${({ color }) => (color === "grey" ? "#585656" : "#f26f21")};
  padding-right: 10px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ChartContainer = styled.div`
  width: 100%;
`;

const ConfirmationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

export { Wrapper, Section, CustomHeading, ItemsWrapper, Item, ChartContainer, ConfirmationButtonContainer };
