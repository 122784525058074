import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% repeat(2, 1fr) 0.5fr;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Item = styled.div`
  padding: 0 20px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  height: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
`;

const ItemImage = styled.div`
  padding: 0 20px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  align-items: center;
`;

export { Container, Item, ItemImage };
