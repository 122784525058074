import React from "react";
import { Progress } from "@aidron/aidron-ds";

export default function NewContentLoader({ loading, results, children, renderError }) {
  return loading ? (
    <div className="d-flex justify-content-center align-items-center width-40px-on-progress">
      <Progress circular />
    </div>
  ) : results ? (
    children
  ) : (
    renderError
  );
}
