import styled from "styled-components";

const OuterChartContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const InnerChartContainer = styled.div`
  min-width: 600px;
  margin-top: 2.5rem;
`;

export { OuterChartContainer, InnerChartContainer };
