import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const PREDICTIONS_URL = `${API_URL}/predictions`;

export async function getPredictions(token, forecastIds) {
  const predictionsUrl = `${PREDICTIONS_URL}/?forecastIds=${forecastIds.join(",")}`;

  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getProductIds(token, forecastId, sortBy) {
  const params = new URLSearchParams();
  params.append("forecastId", forecastId);
  params.append("orderForecastId", sortBy.orderForecastId);
  params.append("order", sortBy.order);
  params.append("months", sortBy.months);

  if (sortBy.columnLabel) {
    params.append("column", sortBy.columnLabel);
  }

  if (sortBy.indexOne) {
    params.append("indexOne", sortBy.indexOne);
    params.append("indexTwo", sortBy.indexTwo);
  }

  const predictionsUrl = `${PREDICTIONS_URL}/productIds/?${params.toString()}`;

  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getConsolidatedPredictions(token, forecastIds) {
  let predictionsUrl = PREDICTIONS_URL;

  if (forecastIds && forecastIds.length > 0) {
    const forecastIdsString = forecastIds.join(",");
    predictionsUrl += `/consolidated/?forecastIds=${forecastIdsString}`;
  }
  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getConsolidatedIndexes(token, forecastId, productIds) {
  const productIdsString = productIds.join(",");
  let predictionsUrl = `${PREDICTIONS_URL}/consolidatedIndexes/?forecastId=${forecastId}&productIds=${productIdsString}`;

  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getPredictionsByProduct(token, productIds) {
  let predictionsUrl = PREDICTIONS_URL;

  if (productIds && productIds.length > 0) {
    const productIdsString = productIds.join(",");
    predictionsUrl += `/byProducts/?productIds=${productIdsString}`;
  }
  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function updatePredictions(newPredictions, token) {
  const headers = { authorization: token };
  let response = await axios.patch(PREDICTIONS_URL, newPredictions, {
    headers,
  });
  return response.data;
}

export async function deletePredictions(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${PREDICTIONS_URL}/${id}`, { headers });
  return response.data;
}
