import React from "react";
import { Card, Heading } from "@aidron/aidron-ds";

export default function ResultError() {
  return (
    <Card>
      <Heading size="sm">Sua busca não retornou resultados.</Heading>
    </Card>
  );
}
