import React, { useEffect, useState } from "react";
import { Card } from "@aidron/aidron-ds";
import { getOpportunitiesCount } from "../../../services/OpportunityService.js";

import * as S from "./OpportunityCounter.style.js";

import CardContent from "./CardContent/CardContent.jsx";
import LoadingProgress from "../../../components/LoadingProgress/LoadingProgress.jsx";

export default function OpportunityCounter() {
  return (
    <>
      <Card>
        <CardContent label="Total de Oportunidades" number={12123421} legendNumber={101} />
      </Card>
      <Card>
        <S.CardWrapper>
          <CardContent label="AJUSTE DE SORTIMENTO" number={8952012} legendNumber={75} />
          <CardContent label="AJUSTE DE META" number={3171409} legendNumber={26} />
        </S.CardWrapper>
      </Card>
    </>
  );
}
