import React, { memo, useState } from "react";
import * as XLSX from "xlsx";
import { i18n } from "../../../../../translate/i18n";

import ImportModal from "../../../ImportModal/ImportModal";
import SeasonsTable from "../SeasonsTable/SeasonsTable";

function SeasonImport({ setShowImportModal, onSaveSeasons }) {
  const [newSeasons, setNewSeasons] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  function downloadTemplate() {
    const seasonNamesData = [
      [i18n.t("seasonNumber"), i18n.t("seasonName")],
      ["1", "Verão"],
      ["2", "Outono"],
      ["3", "Inverno"],
    ];

    const seasonMonthsData = [
      [i18n.t("seasonNumber"), "Mês"],
      ["1", "12"],
      ["1", "1"],
      ["1", "2"],
      ["2", "12"],
      ["2", "3"],
      ["3", "7"],
    ];

    const seasonSheet = XLSX.utils.aoa_to_sheet(seasonNamesData);
    const monthsSheet = XLSX.utils.aoa_to_sheet(seasonMonthsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, seasonSheet, i18n.t("seasonNames"));
    XLSX.utils.book_append_sheet(wb, monthsSheet, i18n.t("seasonMonths"));

    XLSX.writeFile(wb, i18n.t("importSeasons") + ".xlsx");
  }

  function readImportFile(workbook) {
    const seasonNamesSheet = workbook.Sheets[i18n.t("seasonNames")];
    const monthsSheet = workbook.Sheets[i18n.t("seasonMonths")];

    const seasonNamesData = XLSX.utils.sheet_to_json(seasonNamesSheet);
    const monthsData = XLSX.utils.sheet_to_json(monthsSheet);

    if (!seasonNamesData.length || !monthsData.length) handleFileError();
    else {
      const validatedNewSeason = validateNewSeason(seasonNamesData, monthsData);

      if (validatedNewSeason)
        return formatNewSeasons(seasonNamesData, monthsData);
      else {
        handleFileError();
        console.log("Error on validating inputted data");
      }
    }
  }

  function handleFileError() {
    setShowErrorModal(true);
    setUploadedFile(0);
  }

  function validateNewSeason(seasonNamesData, monthsData) {
    if (!areSeasonIdsUnique(seasonNamesData)) return false;
    if (!areSeasonNamesUnique(seasonNamesData)) return false;
    if (!areMonthNumbersValid(monthsData)) return false;
    return true;
  }

  function areSeasonIdsUnique(seasonNamesData) {
    const uniqueNumbers = new Set();

    return seasonNamesData.every((seasonName) => {
      const seasonNumber = seasonName[i18n.t("seasonNumber")];
      if (isNaN(seasonNumber) || uniqueNumbers.has(seasonNumber)) return false;
      else {
        uniqueNumbers.add(seasonNumber);
        return true;
      }
    });
  }

  function areSeasonNamesUnique(seasonNamesData) {
    const uniqueSeasonNames = new Set();

    return seasonNamesData.every((seasonName) => {
      const uniqueSeasonName = seasonName[i18n.t("seasonName")];
      if (
        typeof seasonName[i18n.t("seasonName")] !== "string" ||
        uniqueSeasonNames.has(uniqueSeasonName)
      )
        return false;
      else {
        uniqueSeasonNames.add(uniqueSeasonName);
        return true;
      }
    });
  }

  function areMonthNumbersValid(monthsData) {
    return monthsData.every(
      (month) =>
        !isNaN(Number(month[i18n.t("month")])) &&
        Number(month[i18n.t("month")]) < 13 &&
        Number(month[i18n.t("month")]) > 0 &&
        Number.isInteger(Number(month[i18n.t("month")]))
    );
  }

  function formatNewSeasons(seasonNamesData, monthsData) {
    return seasonNamesData.map((season) => {
      const months = getMonthsArrayFromSeasonId(
        monthsData,
        season[i18n.t("seasonNumber")]
      );
      const formattedSeasonName = season[i18n.t("seasonName")];
      return { name: formattedSeasonName, months: months };
    });
  }

  function getMonthsArrayFromSeasonId(monthsData, seasonId) {
    const monthsObject = monthsData.filter(
      (month) => month[i18n.t("seasonNumber")] === seasonId
    );
    return monthsObject.map((month) => Number(month[i18n.t("month")]));
  }

  return (
    <ImportModal
      setShowImportModal={setShowImportModal}
      onSave={onSaveSeasons}
      downloadTemplate={downloadTemplate}
      readImportFile={readImportFile}
      handleFileError={handleFileError}
      newDataToImport={newSeasons}
      setNewDataToImport={setNewSeasons}
      uploadedFile={uploadedFile}
      setUploadedFile={setUploadedFile}
      showErrorModal={showErrorModal}
      setShowErrorModal={setShowErrorModal}
    >
      <SeasonsTable seasons={newSeasons} />
    </ImportModal>
  );
}

export default memo(SeasonImport);
