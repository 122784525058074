import React, { useEffect, useState } from "react";

import { getOpportunities } from "../../../services/OpportunityService";

import * as S from "./RemovedButton.style";

import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";
import RemovedModal from "./RemovedModal/RemovedModal";

export default function RemovedButton({ fetchOpportunitiesPageData, forecastId }) {
  const [openModal, setOpenModal] = useState(false);
  const [opportunities, setOpportunities] = useState([]);

  const count = opportunities.length.toString();

  useEffect(() => {
    if (forecastId) {
      fetchOpportunities();
    }
  }, [forecastId]);

  async function fetchOpportunities() {
    const token = localStorage.getItem("token");
    const productIds = [];
    const order = "any";
    const selectedTypes = "";
    const status = ["removed"];
    const result = await getOpportunities(token, forecastId, productIds, order, selectedTypes, status);

    setOpportunities(result.opportunities);
  }

  function fetchBothPagesOpportunities() {
    fetchOpportunitiesPageData();
    fetchOpportunities();
  }

  return (
    <>
      <S.OuterContainer>
        <CustomText weight="bold" margin="0 0.5rem 0 0">
          Oportunidades Excluídas:
        </CustomText>
        <S.Link onClick={() => setOpenModal(true)} cursor={count !== "0" ? "pointer" : ""}>
          <CustomText
            weight="bold"
            color={count !== "0" ? "orange" : "mediumGrey"}
            textDecoration={count !== "0" ? "underline" : ""}
          >
            {count}
          </CustomText>
        </S.Link>
      </S.OuterContainer>

      {openModal && (
        <RemovedModal
          fetchData={fetchBothPagesOpportunities}
          closeModal={() => setOpenModal(false)}
          opportunities={opportunities}
        />
      )}
    </>
  );
}
