import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 5% 0 5% 0;
`;

export { OuterContainer };
