import React, { useContext } from "react";

import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { getProductPrediction } from "../../../../utils/utils";
import { forecastColumns } from "../../../../utils/tableColumns";

import HistoricBodyCells from "../../../../components/HistoricBodyCells/HistoricBodyCells";
import PredictionsSubtotalRow from "./PredictionsSubtotalRow/PredictionsSubtotalRow";
import CheckCell from "../../../../components/CheckCell/CheckCell";
import HierarchyBodyCells from "../../../../components/HierarchyBodyCells/HierarchyBodyCells";
import BodyCells from "../../../../components/BodyCells/BodyCells";

export default function PredictionRows({
  selectedProductIds,
  freezeColumns,
  monthColumns,
  setCheckColumnWidth,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  checkedProducts,
  setCheckedProducts,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  historicMonthsToDisplay,
  defineHierarchyToDisplay,
  selectedHierarchyIndexes,
  defineLeftCellBorder,
  headerHeight,
  allHistoricPredictions,
  predictionData,
}) {
  const { userInfo } = useContext(CompanyContext);
  const { unit } = userInfo.company;
  return (
    <>
      <PredictionsSubtotalRow
        freezeColumns={freezeColumns}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        selectedProductIds={selectedProductIds}
        defineHierarchyToDisplay={defineHierarchyToDisplay}
        historicMonthsToDisplay={historicMonthsToDisplay}
        monthColumns={monthColumns}
        defineLeftCellBorder={defineLeftCellBorder}
        sumPreviousColumnsWidth={sumPreviousColumnsWidth}
        evaluateLastCategoryColumn={evaluateLastCategoryColumn}
        headerHeight={headerHeight}
        unit={unit}
        allHistoricPredictions={allHistoricPredictions}
        predictionData={predictionData}
      />
      {selectedProductIds.map((productId, index) => (
        <tr key={index}>
          <CheckCell
            individualProductCheck
            productId={productId}
            freezeColumns={freezeColumns}
            setCheckColumnWidth={setCheckColumnWidth}
            checkedProducts={checkedProducts}
            setCheckedProducts={setCheckedProducts}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
          />
          <HierarchyBodyCells
            freezeColumns={freezeColumns}
            product={productId}
            sumPreviousColumnsWidth={sumPreviousColumnsWidth}
            setHierarchyColumnsWidth={setHierarchyColumnsWidth}
            showSeasonColumn={showSeasonColumn}
            evaluateLastCategoryColumn={evaluateLastCategoryColumn}
            productId={productId}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
            selectedHierarchyIndexes={selectedHierarchyIndexes}
            selectedProductIds={selectedProductIds}
          />
          {historicMonthsToDisplay.map((month, i) => (
            <HistoricBodyCells
              individualProduct
              month={month}
              productId={productId}
              key={i}
              cellLeftBorder={i !== 0}
              unit={unit}
              allHistoricPredictions={allHistoricPredictions}
              predictionData={predictionData}
            />
          ))}
          {monthColumns.map((month, index) => (
            <BodyCells
              individualProduct
              columns={forecastColumns()}
              prediction={getProductPrediction(month, productId, predictionData)}
              productIds={selectedProductIds}
              defineLeftCellBorder={defineLeftCellBorder}
              monthIndex={index}
              unit={unit}
              key={index}
              month={month}
            />
          ))}
        </tr>
      ))}
    </>
  );
}
