import React from "react";
import { Text, Card } from "@aidron/aidron-ds";

import * as S from "./OpportunityAccordion.style";

import OpportunityChart from "./OpportunityChart/OpportunityChart";
import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import InformationsSection from "./InformationsSection/InformationsSection";

export default function OpportunityAccordion({ opportunity, opportunities, getOpportunityDetails }) {
  const { type1 } = opportunity;
  const opportunityDetails = getOpportunityDetails(type1);

  return (
    <>
      <S.Header>
        <CustomHeading size="sm">{`Oportunidade de ${opportunityDetails.title}`}</CustomHeading>
        <Text size="xs">{opportunityDetails.summary}</Text>
      </S.Header>

      <S.Section>
        <InformationsSection
          opportunity={opportunity}
          opportunities={opportunities}
          getOpportunityDetails={getOpportunityDetails}
        />

        <S.ChartContainer>
          <Card style={{ width: "80%", height: "100%" }}>
            <OpportunityChart opportunity={opportunity} />
          </Card>
        </S.ChartContainer>
      </S.Section>
    </>
  );
}
