import React, { useContext } from "react";
import { ForecastContext } from "../../templates/context/ForecastContext";

import {
  getProductPrediction,
  getMonthAbbreviation,
  getForecastPredictions,
  isFutureMonth,
  sortByMonthAndYear,
} from "../../utils/utils";

import ProductHierarchyHeading from "../ProductHierarchyHeading/ProductHierarchyHeading";
import AreaRangeChart from "../AreaRangeChart/AreaRangeChart";

export default function ProductAccuracyChart({ productId, unit, currentMonth, productPredictions }) {
  const { historicForecasts, selectedForecast } = useContext(ForecastContext);

  const colors = [
    "#f58b4d",
    "#BFBFBF",
    "#12a9b3", //azul
    "#aeaa10", //amarelo
    "#9544d2", //roxo
    "#e52590", //rosa
    "#178751", //verde
  ];
  const lastTwelveMonths = historicForecasts.slice(-12);

  function defineXLabels() {
    return lastTwelveMonths.map(month => getMonthAbbreviation(month.month));
  }

  function defineRanges(productId) {
    const pastPredictions = lastTwelveMonths.map(month => {
      const minCI = getProductPredictionFromOfficialPastForecast(month, productId, productPredictions)?.confidenceIntervalMin;
      const maxCI = getProductPredictionFromOfficialPastForecast(month, productId, productPredictions)?.confidenceIntervalMax;
      return [!isNaN(minCI) ? Number(minCI) : null, !isNaN(maxCI) ? Number(maxCI) : null];
    });
    return pastPredictions;
  }

  function defineLines(productId) {
    const realizedLines = createRealizedLines(productId);
    const predictionLines = definePredictionLines(productId);
    return predictionLines.concat(realizedLines);
  }

  function createRealizedLines(productId) {
    const allMonths = getRealizedValues(productId);

    const currentMonthIndex = allMonths.findIndex(
      month => month.month === selectedForecast.month && month.year === selectedForecast.year
    );
    const pastMonths = allMonths.slice(0, currentMonthIndex);

    const remainingMonthsNumber = pastMonths.length % 12;
    const monthsToDisplay = pastMonths.slice(remainingMonthsNumber);
    const twelveMonthsArrays = cutInto12MonthsArrays(monthsToDisplay).reverse();

    return twelveMonthsArrays.map((currArr, index) => {
      const values = [];
      currArr.forEach(el => {
        if (!isFutureMonth(currentMonth, el)) {
          values.push(el.realized);
        }
      });
      return {
        label: `Realizado ${currArr[0].year} - ${currArr[currArr.length - 1].year}`,
        color: colors[index + 2],
        marker: false,
        values: values,
        visible: index === 0,
      };
    });
  }

  function getRealizedValues(productId) {
    const allHistoricMonths = defineHistoricMonths();
    const realizedSeries = allHistoricMonths.map(month => {
      const productPrediction = getProductPrediction(month, productId, productPredictions);
      const prediction = !isNaN(productPrediction?.realized) ? productPrediction.realized : null;
      return { ...month, realized: prediction };
    });
    return sortByMonthAndYear(realizedSeries);
  }

  function defineHistoricMonths() {
    const years = [...new Set(historicForecasts.map(forecast => forecast.year))];
    const twelveNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
    const monthsAndYears = years
      .map(year => {
        return twelveNumbers.map(num => {
          return { year: year, month: num };
        });
      })
      .flat();
    return monthsAndYears;
  }

  function cutInto12MonthsArrays(arr) {
    const result = [];
    let i = 0;
    while (i < arr.length) {
      result.push(arr.slice(i, i + 12));
      i += 12;
    }
    return result;
  }

  function definePredictionLines(productId) {
    const lines = [
      {
        label: "Forecast IA",
        name: "officialAidronPrediction",
        color: "#f58b4d",
      },
      {
        label: "Plano Atual",
        name: "officialClientPrediction",
        color: "#BFBFBF",
      },
    ];

    return lines.map(line => {
      let values = lastTwelveMonths.map(month => {
        const prediction = getProductPredictionFromOfficialPastForecast(month, productId);
        const value = prediction?.[line.name];
        return value ? value : null;
      });

      return {
        ...line,
        values: values,
        marker: false,
      };
    });
  }

  function getProductPredictionFromOfficialPastForecast(month, productId) {
    const officialPastForecast = historicForecasts.find(
      forecast => forecast.month === month.month && forecast.year === month.year
    );

    if (officialPastForecast) {
      const forecastPredictions = getForecastPredictions(officialPastForecast.forecastId, productPredictions);
      return getProductPrediction(month, productId, forecastPredictions);
    } else {
      // Cai aqui quando não há predição oficial anterior, portanto não importa de qual predição pega pq vai dar N/A
      return {};
    }
  }
  return (
    <>
      <ProductHierarchyHeading productId={productId} />
      <AreaRangeChart
        xLabels={defineXLabels()}
        ranges={defineRanges(productId)}
        lines={defineLines(productId)}
        colors={colors}
        unit={unit}
        style={{ width: "80%" }}
      />
    </>
  );
}
