import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
`;

export { Container };
