import React from "react";
import { getProductPrediction, getAllPredictionsFromMonth } from "../../utils/utils";
import PercentBodyCell from "../PercentBodyCell/PercentBodyCell";
import BodyCell from "../BodyCell/BodyCell";

export default function HistoricBodyCells({
  individualProduct,
  month,
  productId,
  productIds,
  cellLeftBorder,
  headerHeight,
  unit,
  allHistoricPredictions,
  predictionData,
}) {
  const realizedCellValue = individualProduct ? getRealizedValue() : sumMonthRealizedValues(productIds);

  function getRealizedValue() {
    return getProductPrediction(month, productId, allHistoricPredictions)?.realized;
  }

  function sumMonthRealizedValues(ids) {
    let allPredictionsFromMonth = getAllPredictionsFromMonth(month, allHistoricPredictions);
    // Pegar só uma predição de cada produto em productIds
    allPredictionsFromMonth = ids.map(id => {
      return allPredictionsFromMonth.find(prediction => prediction.productId === id);
    });
    const pred = allPredictionsFromMonth.filter(prediction => prediction && prediction.realized);
    return pred.reduce((total, currentObject) => {
      if (!isNaN(currentObject?.realized)) return total + currentObject.realized;
      else return total;
    }, 0);
  }

  function getProductParticipation() {
    const allProductsOfCurrentForecast = predictionData.map(prediction => prediction.productId);
    const uniqueAllProductsId = [...new Set(allProductsOfCurrentForecast)];

    const monthRealizedSum = sumMonthRealizedValues(uniqueAllProductsId);
    return (getRealizedValue() / monthRealizedSum) * 100;
  }

  function getTotalParticipation() {
    const allProductsOfCurrentForecast = predictionData.map(prediction => prediction.productId);
    const uniqueAllProductsId = [...new Set(allProductsOfCurrentForecast)];

    const monthProductsRealizedSum = sumMonthRealizedValues(productIds);
    const allProductsRealizedSum = sumMonthRealizedValues(uniqueAllProductsId);
    return (monthProductsRealizedSum / allProductsRealizedSum) * 100;
  }

  function defineRealizedCellContent() {
    if (!isNaN(realizedCellValue)) {
      return realizedCellValue;
    } else return "N/A";
  }

  function defineParticipationCellContent() {
    if (!isNaN(realizedCellValue)) {
      return individualProduct ? getProductParticipation() : getTotalParticipation();
    } else return "N/A";
  }

  return (
    <>
      <td
        className={`background-color-white ${individualProduct ? "" : "position-sticky cell-bottom-border z-1"} ${
          cellLeftBorder ? "cell-left-border" : ""
        }`}
        style={{ top: individualProduct ? "" : `${headerHeight}px` }}
      >
        <BodyCell prediction={defineRealizedCellContent()} format={unit} />
      </td>
      <td
        className={`background-color-white ${individualProduct ? "" : "position-sticky cell-bottom-border z-1"}`}
        style={{ top: individualProduct ? "" : `${headerHeight}px` }}
      >
        <PercentBodyCell prediction={defineParticipationCellContent()} />
      </td>
    </>
  );
}
