import styled from "styled-components";

const HelpButton = styled.button`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: #f26f21;
  margin-left: 5px;
  vertical-align: text-top;
`;

const IndexContainer = styled.div`
  margin: 0 20px 20px 20px;
  text-align: start;
`;

export { HelpButton, IndexContainer };
