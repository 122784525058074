import React, { useState } from "react";
import { Check, Text, Button } from "@aidron/aidron-ds";

import * as S from "./OpportunityTypeFilter.style";

export default function OpportunityTypeFilter({ selectedTypes, setSelectedTypes, closeModal, typeOptions }) {
  const [types, setTypes] = useState(selectedTypes);

  function handleTypes(selected) {
    if (types.includes(selected)) {
      const filteredTypes = types.filter(type => type !== selected);
      setTypes(filteredTypes);
    } else {
      setTypes([...types, selected]);
    }
  }

  function onConcludedClick() {
    setSelectedTypes(types);
    closeModal();
  }

  return (
    <>
      {typeOptions.map(option => (
        <S.checkWrapper key={option.value}>
          <Check two handleChange={() => handleTypes(option.value)} value={types.includes(option.value) ? "1" : "0"} />
          <Text weight="bold">{option.displayName}</Text>
        </S.checkWrapper>
      ))}

      <S.buttonWrapper>
        <Button label="Concluído" onClick={onConcludedClick} />
      </S.buttonWrapper>
    </>
  );
}
