import styled from "styled-components";
import { Heading } from "@aidron/aidron-ds";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 45%;
  min-width: 240px;
`;

const CustomHeading = styled(Heading)`
  color: #585656;
`;

const InnerBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export { Wrapper, CustomHeading, InnerBoxWrapper };
