import React, { useState } from "react";
import { Card, Icon } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./ListItem.style";

import TextAndLabelView from "./TextAndLabelView/TextAndLabelView";
import ProductHierarchyHeading from "../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import OpportunityAccordion from "./OpportunityAccordion/OpportunityAccordion";
import CheckItem from "../../../../components/CheckItem/CheckItem";

export default function ListItem({ opportunity }) {
  const { productId, type1, value, percent } = opportunity;

  const [openAccordion, setOpenAccordion] = useState();

  const typeDetails = new Map([
    [
      "Aumento da Meta",
      {
        title: "Aumento da Meta",
        icon: "trending-up",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro esta abaixo do centro da demanda do cliente (a demanda do cliente por este produto pode ser observada em verde no gráfico). Também traremos casos, em que seu histórico recente de vendas ficou abaixo ou próximo do limite mínimo da demanda dos clientes nesta loja.",
        percentLabel: "Aumento Esperado da Venda",
        icon: <S.SaleIcon />,
      },
    ],
    [
      "Otimização de Sortimento",
      {
        title: "Otimização de Sortimento",
        icon: "layers",
        summary:
          "Esta oportunidade é apresentada sempre que, ao cruzarmos dados internos e externos em nossos modelos preditivos, eles indicarem uma grande probabilidade de vender produtos que não estão sendo vendidos nesta loja.",
        percentLabel: "Redução da Compra Futura",
        icon: <S.StockIcon />,
      },
    ],
    [
      "Ajuste na Meta",
      {
        title: "Ajuste na Meta",
        icon: "trending-up",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro esta abaixo do centro da demanda do cliente (a demanda do cliente por este produto pode ser observada em verde no gráfico). Também traremos casos, em que seu histórico recente de vendas ficou abaixo ou próximo do limite mínimo da demanda dos clientes nesta loja.",
        percentLabel: "Aumento Esperado da Venda",
        icon: <S.SaleIcon />,
      },
    ],
  ]);

  const opportunityDetails = typeDetails.get(type1);

  return (
    <>
      <Card>
        <S.Container>
          <S.ItemImage border>
            {opportunityDetails.icon}
            <ProductHierarchyHeading productId={productId} useMockedStores />
          </S.ItemImage>

          <S.Item textAlign border>
            <TextAndLabelView label="Oportunidade" text={opportunityDetails.title} />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView
              label="Percentual"
              text={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
              textSize="md"
            />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView label="Tamanho da Oportunidade" text={`R$ ${toNDecimalsString(value, 0)}`} textSize="md" />
          </S.Item>
          <CheckItem />
        </S.Container>
        <S.ButtonAccordion onClick={() => setOpenAccordion(!openAccordion)} label="">
          <Icon size="16" icon={openAccordion === type1 ? "chevron-up" : "chevron-down"} />
        </S.ButtonAccordion>
      </Card>
      {openAccordion && <OpportunityAccordion opportunity={opportunity} opportunityDetails={opportunityDetails} />}
    </>
  );
}
