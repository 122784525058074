import React from "react";
import { Check, Text, Button } from "@aidron/aidron-ds";

import * as S from "./OpportunityTypeFilter.style";

export default function OpportunityTypeFilter({
  selectedTypes,
  setSelectedTypes,
  onConcludedClick,
  typeOptions,
}) {
  function handleTypes(selected) {
    if (selectedTypes.includes(selected)) {
      const filteredTypes = selectedTypes.filter((type) => type !== selected);
      setSelectedTypes(filteredTypes);
    } else {
      setSelectedTypes([...selectedTypes, selected]);
    }
  }
  return (
    <>
      {typeOptions.map((option) => (
        <S.checkWrapper key={option.value}>
          <Check
            two
            handleChange={() => handleTypes(option.value)}
            value={selectedTypes.includes(option.value) ? "1" : "0"}
          />
          <Text weight="bold">{option.displayName}</Text>
        </S.checkWrapper>
      ))}

      <S.buttonWrapper>
        <Button label="Concluído" onClick={onConcludedClick} />
      </S.buttonWrapper>
    </>
  );
}
