import React, { useContext, useRef, useEffect } from "react";
import { Text } from "@aidron/aidron-ds";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { capitalizeFirst } from "../../utils/utils";

function HierarchyBodyCells({
  freezeColumns,
  productId,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  productRows,
  defineHierarchyToDisplay,
  selectedHierarchyIndexes,
  storesTable,
  unit,
  selectedProductIds,
}) {
  const { hierarchy, allProducts } = useContext(CompanyContext);
  const hierarchyRefs = useRef(null);
  const seasonColumnRef = useRef(null);
  const indexOfSeasonColumn = hierarchy.levels.length;

  const product = allProducts.find(
    (product) => product.productId === productId
  );

  function defineHierarchyRefs(node, level) {
    const map = getHierarchyRefs();
    if (node) {
      map.set(level, node);
    } else {
      map.delete(level);
    }
  }

  function getHierarchyRefs() {
    if (!hierarchyRefs.current) {
      hierarchyRefs.current = new Map();
    }
    return hierarchyRefs.current;
  }

  useEffect(() => {
    const hierarchyWidths = [];
    for (let value of hierarchyRefs.current.values()) {
      hierarchyWidths.push(value.getBoundingClientRect().width);
    }
    if (seasonColumnRef.current) {
      hierarchyWidths.push(
        seasonColumnRef.current.getBoundingClientRect().width
      );
    }
    setHierarchyColumnsWidth(hierarchyWidths);
  }, [productRows, selectedHierarchyIndexes, unit, selectedProductIds]);

  function cellRightBorder(index) {
    return !freezeColumns && index === defineHierarchyToDisplay().length - 1
      ? "cell-right-border"
      : "";
  }

  return (
    <>
      {defineHierarchyToDisplay().map((level, index) => (
        <td
          key={index}
          ref={(node) => defineHierarchyRefs(node, level)}
          className={`background-color-white padding-sides-040rem ${evaluateLastCategoryColumn(
            index
          )} ${cellRightBorder(index)}`}
          style={{
            position: freezeColumns ? "sticky" : "",
            left: freezeColumns ? `${sumPreviousColumnsWidth(index)}px` : "",
          }}
        >
          <div className={storesTable && index === 0 ? "ps-4" : ""}>
            <Text>{capitalizeFirst(product[level])}</Text>
          </div>
        </td>
      ))}
      {showSeasonColumn && (
        <td
          ref={seasonColumnRef}
          className={`background-color-white ${
            freezeColumns ? "right-border-fade" : ""
          }`}
          style={{
            position: freezeColumns ? "sticky" : "",
            left: freezeColumns
              ? `${sumPreviousColumnsWidth(indexOfSeasonColumn)}px`
              : "",
          }}
        >
          <Text>{capitalizeFirst(product.season?.name ?? "N/A")}</Text>
        </td>
      )}
    </>
  );
}

export default HierarchyBodyCells;
