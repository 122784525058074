import React from "react";
import { Text } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./CardContent.style";

import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";

export default function CardContent({ label, number, legendNumber }) {
  return (
    <S.Wrapper>
      <Text allcaps weight="bold">
        {label}
      </Text>
      <CustomHeading size="sm" color="orange">
        R$ {toNDecimalsString(number, 0)}
      </CustomHeading>
      <S.Legend>
        <CustomText color="orange" weight="bold">
          {legendNumber}
        </CustomText>
        <Text size="xs">oportunidades</Text>
      </S.Legend>
    </S.Wrapper>
  );
}
