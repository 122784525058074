import styled from "styled-components";

const ItemCheck = styled.div`
  padding-top: 10px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  .check.check-outer {
    height: auto;
    padding: 10px;
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
  }
`;

export { ItemCheck };
