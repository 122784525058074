import React from "react";
import { Button } from "@aidron/aidron-ds";
import DisabledFilledButton from "../DisabledFilledButton/DisabledFilledButton";

function PaginationButtons({ currentPage, setCurrentPage, pagination }) {
  function onButtonClick(chosenPage) {
    setCurrentPage(chosenPage);
  }

  function isTherePreviousPage() {
    return pagination.lastPage > 1 && currentPage > 1;
  }

  function isThereNextPage() {
    return currentPage !== pagination.lastPage;
  }

  return (
    <div className="d-flex justify-content-end my-3 me-3">
      <div className="d-flex">
        {currentPage !== 1 && (
          <Button
            label=""
            type="outlined"
            onClick={() => onButtonClick(1)}
            className="me-3"
            icon="chevrons-left"
          />
        )}
        {isTherePreviousPage() && (
          <Button
            label={(currentPage - 1).toString()}
            type="outlined"
            onClick={() => onButtonClick(currentPage - 1)}
            className="me-3"
          />
        )}
        <DisabledFilledButton
          label={currentPage.toString()}
          type="filled"
          onClick={() => {}}
          className="me-3"
        />
        {isThereNextPage() && (
          <Button
            label={(currentPage + 1).toString()}
            type="outlined"
            onClick={() => onButtonClick(currentPage + 1)}
            className="me-3"
          />
        )}
        {currentPage !== pagination.lastPage && (
          <Button
            label=""
            type="outlined"
            onClick={() => onButtonClick(pagination.lastPage)}
            className="me-3"
            icon="chevrons-right"
          />
        )}
      </div>
    </div>
  );
}

export default PaginationButtons;
