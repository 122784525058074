import React, { useState, useContext } from "react";
import { forecastColumns } from "../../../utils/tableColumns";
import { getMonthName } from "../../../utils/utils";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import PredictionsColumnLabelsRow from "./PredictionsColumnLabelsRow/PredictionsColumnLabelsRow";
import PredictionRows from "./PredictionRows/PredictionRows";
import ColumnTitlesRow from "../../../components/ColumnTitlesRow/ColumnTitlesRow";
import TableControls from "../../../components/TableControls/TableControls";
import TableColumnTitle from "../../../components/TableColumnTitle/TableColumnTitle";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";

function PredictionsTable({
  freezeColumns,
  monthColumns,
  checkedProducts,
  setCheckedProducts,
  sortRowsBy,
  setSortRowsBy,
  showSeasonColumn,
  historicMonthsToDisplay,
  selectedHierarchyIndexes,
  allHistoricPredictions,
  selectedProductIds,
  predictionData,
}) {
  const { hierarchy } = useContext(CompanyContext);
  const [checkColumnWidth, setCheckColumnWidth] = useState(0);
  const [hierarchyColumnsWidth, setHierarchyColumnsWidth] = useState([]);
  const [historicColumnsWidth, setHistoricColumnsWidth] = useState([]);
  const [monthColumnsWidth, setMonthColumnsWidth] = useState([]);
  const [monthHeaderCellHeight, setMonthHeaderCellHeight] = useState(0);
  const [hierarchyCellHeight, setHierarchyCellHeight] = useState(0);

  const headerHeight = monthHeaderCellHeight + hierarchyCellHeight;

  const allColumns = [0, ...historicColumnsWidth].concat(monthColumnsWidth);
  const monthColumnsPosition = allColumns.map((month, index) => sumPreviousColumnsWidth(index, allColumns));

  const initialScrollIndex = monthColumnsPosition[historicMonthsToDisplay.length];

  function evaluateLastCategoryColumn(index) {
    return freezeColumns && !showSeasonColumn && index === defineHierarchyToDisplay().length - 1 ? "right-border-fade" : "";
  }

  function sumPreviousColumnsWidth(currentColumnIndex, columnsWidth) {
    const previousColumnsWidth = columnsWidth ? columnsWidth : [checkColumnWidth].concat(hierarchyColumnsWidth);
    let sum = 0;
    for (let i = 0; i <= currentColumnIndex; i++) {
      sum += previousColumnsWidth[i];
    }
    return sum;
  }

  function defineHierarchyToDisplay() {
    return hierarchy.levels.filter((level, index) => selectedHierarchyIndexes.includes(index));
  }

  function defineLeftCellBorder(monthIndex, columnIndex) {
    if (columnIndex !== 0) return false;
    if (historicMonthsToDisplay.length === 0) {
      if (monthIndex === 0) return false;
      else return true;
    } else return true;
  }

  return (
    <TableWrapper resultCondition={() => selectedProductIds.length} loadingCondition={() => predictionData.length}>
      <TableControls
        freezeColumns={freezeColumns}
        columns={monthColumns}
        columnsWidth={monthColumnsWidth}
        columnsPosition={monthColumnsPosition}
        initialScrollIndex={initialScrollIndex}
      >
        <table id="download-table" className="table table-striped table-hover padding-070rem-on-th-and-td">
          <thead>
            <ColumnTitlesRow
              freezeColumns={freezeColumns}
              showSeasonColumn={showSeasonColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              setMonthHeaderCellHeight={setMonthHeaderCellHeight}
              setMonthColumnsWidth={setMonthColumnsWidth}
              historicMonthsToDisplay={historicMonthsToDisplay}
              monthHeaderCellsSpan={forecastColumns().length}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              defineLeftCellBorder={defineLeftCellBorder}
              renderColumnTitleLabel={({ month }) => (
                <TableColumnTitle label={`${getMonthName(month.month.toString())} ${month.year}`} />
              )}
              title="Produtos"
            />
            <PredictionsColumnLabelsRow
              freezeColumns={freezeColumns}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              showSeasonColumn={showSeasonColumn}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              sortRowsBy={sortRowsBy}
              setSortRowsBy={setSortRowsBy}
              historicMonthsToDisplay={historicMonthsToDisplay}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              defineLeftCellBorder={defineLeftCellBorder}
              setHierarchyCellHeight={setHierarchyCellHeight}
              setHistoricColumnsWidth={setHistoricColumnsWidth}
              futureColumns={forecastColumns}
              selectedProductIds={selectedProductIds}
            />
          </thead>
          <tbody>
            <PredictionRows
              selectedProductIds={selectedProductIds}
              freezeColumns={freezeColumns}
              monthColumns={monthColumns}
              setCheckColumnWidth={setCheckColumnWidth}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              setHierarchyColumnsWidth={setHierarchyColumnsWidth}
              checkedProducts={checkedProducts}
              setCheckedProducts={setCheckedProducts}
              showSeasonColumn={showSeasonColumn}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              historicMonthsToDisplay={historicMonthsToDisplay}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              selectedHierarchyIndexes={selectedHierarchyIndexes}
              defineLeftCellBorder={defineLeftCellBorder}
              headerHeight={headerHeight}
              allHistoricPredictions={allHistoricPredictions}
              predictionData={predictionData}
            />
          </tbody>
        </table>
      </TableControls>
    </TableWrapper>
  );
}

export default PredictionsTable;
