import React, { useContext, useState, useMemo, useEffect } from "react";
import { Text, Button } from "@aidron/aidron-ds";
import {
  storesFutureColumns,
  historicColumns,
} from "../../../utils/tableColumns";
import {
  defineMonthColumns,
  getMonthName,
  defineWeekColumns,
  sortByWeekAndYear,
  capitalizeFirst,
} from "../../../utils/utils";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import PercentBodyCell from "../../../components/PercentBodyCell/PercentBodyCell";
import ColumnTitlesRow from "../../../components/ColumnTitlesRow/ColumnTitlesRow";
import TableControls from "../../../components/TableControls/TableControls";
import TableColumnTitle from "../../../components/TableColumnTitle/TableColumnTitle";
import HierarchyHeaderCells from "../../../components/HierarchyHeaderCells/HierarchyHeaderCells";
import HistoricMonthHeaderCells from "../../../components/HistoricMonthHeaderCells/HistoricMonthHeaderCells";
import WeekSortByButton from "./WeekSortByButton/WeekSortByButton";
import MonthSortByButton from "../../../components/MonthSortByButton/MonthSortByButton";
import ColumnLabelCell from "../../../components/ColumnLabelCell/ColumnLabelCell";
import BodyCell from "../../../components/BodyCell/BodyCell";
import ProductRows from "./ProductRows/ProductRows";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";

export default function ChannelTable({
  freezeColumns,
  sortRowsBy,
  setSortRowsBy,
  showSeasonColumn,
  selectedHierarchyIndexes,
  selectedHistoricWeekIds,
  predictionData,
  historicPredictions,
  selectedForecast,
  selectedProductIds,
  currentPage,
  allForecasts,
  storesResults,
}) {
  const { hierarchy } = useContext(CompanyContext);
  const [hierarchyColumnsWidth, setHierarchyColumnsWidth] = useState([]);
  const [monthColumnsWidth, setMonthColumnsWidth] = useState([]);
  const [monthHeaderCellHeight, setMonthHeaderCellHeight] = useState(0);
  const [historicLabelCellHeight, setHistoricLabelCellHeight] = useState(0);
  const [historicColumnsWidth, setHistoricColumnsWidth] = useState([]);
  const [openStores, setOpenStores] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("items");

  const unitOptions = [
    {
      value: "items",
      displayName: "Em peças",
    },
    { value: "currency", displayName: "Em R$" },
  ];

  const unitSuffix = capitalizeFirst(selectedUnit);
  const realizedByUnit = `realized${unitSuffix}`;
  const predictionByUnit = `aidronPrediction${unitSuffix}`;

  const weekColumns = useMemo(() => {
    const existingWeekPredictions = predictionData.filter(
      (prediction) => prediction.week
    );
    const columns = defineWeekColumns(existingWeekPredictions);
    return sortByWeekAndYear(columns);
  }, [predictionData]);

  const historicColumnsToDisplay = allForecasts.filter((forecast) =>
    selectedHistoricWeekIds.includes(forecast.forecastId)
  );

  const allColumns = [0, ...historicColumnsWidth].concat(monthColumnsWidth);

  const monthColumnsPosition = allColumns.map((month, index) =>
    sumPreviousColumnsWidth(index, allColumns)
  );

  const monthlyPredictions = useMemo(() => {
    return predictionData.filter((prediction) => !prediction.week);
  }, [predictionData]);

  const monthColumns = useMemo(() => {
    return defineMonthColumns(monthlyPredictions);
  }, [monthlyPredictions]);

  const storesWithDuplicates = predictionData.map(
    (prediction) => prediction.store
  );
  const stores = [...new Set(storesWithDuplicates)];

  useEffect(() => {
    setOpenStores([]);
  }, [sortRowsBy, currentPage]);

  function handleOpenStores(store) {
    if (openStores.includes(store)) {
      const filteredOpenStores = openStores.filter(
        (openStore) => openStore !== store
      );
      setOpenStores(filteredOpenStores);
    } else {
      setOpenStores([...openStores, store]);
    }
  }

  function determineIcon(store) {
    return openStores.includes(store) ? "chevron-down" : "chevron-right";
  }

  function sumPreviousColumnsWidth(currentColumnIndex, columnsWidth) {
    const previousColumnsWidth = columnsWidth
      ? columnsWidth
      : [0, ...hierarchyColumnsWidth];
    let sum = 0;
    for (let i = 0; i <= currentColumnIndex; i++) {
      sum += previousColumnsWidth[i];
    }
    return sum;
  }

  function evaluateLastCategoryColumn(index) {
    return freezeColumns &&
      !showSeasonColumn &&
      index === defineHierarchyToDisplay().length - 1
      ? "right-border-fade"
      : "";
  }

  function defineHierarchyToDisplay() {
    return hierarchy.levels.filter((level, index) =>
      selectedHierarchyIndexes.includes(index)
    );
  }

  function defineLeftCellBorder(monthIndex, columnIndex) {
    if (columnIndex !== 0) return false;
    if (historicColumnsToDisplay.length === 0) {
      if (monthIndex === 0) return false;
      else return true;
    } else return true;
  }

  function defineProductsTitleSpan() {
    let columnsSpan = defineHierarchyToDisplay().length;
    if (showSeasonColumn) columnsSpan += 1;
    return columnsSpan;
  }

  function getWeekPredictions(week, predictions, forecastId) {
    return predictions.filter(
      (prediction) =>
        prediction.week === week.week &&
        prediction.year === week.year &&
        prediction.forecastId === forecastId
    );
  }

  function getStoreParticipation(column, store) {
    const unitSuffix = capitalizeFirst(selectedUnit);
    const indexName = `participationInRealized${unitSuffix}`;
    if (storesResults.length > 0) {
      const forecastIdObj = storesResults.find(
        (obj) => obj.forecastId === column.forecastId
      );
      const index = forecastIdObj?.storesIndexes.find(
        (s) => s.store === store
      )?.[indexName];
      if (index) {
        return index;
      } else return 0;
    } else return 0;
  }

  function getStoreRealized(column, store) {
    const weekPredictions = getWeekPredictions(
      column,
      historicPredictions,
      column.forecastId
    );
    const storeWeekPredictions = weekPredictions.filter(
      (prediction) => store === prediction.store
    );
    return storeWeekPredictions.reduce((acc, curr) => {
      if (!isNaN(Number(curr[realizedByUnit])))
        return acc + Number(curr[realizedByUnit]);
      else return acc;
    }, 0);
  }

  function getStorePredictedSum(column, store) {
    const weekPredictions = getWeekPredictions(
      column,
      predictionData,
      selectedForecast.forecastId
    );
    const storeWeekPredictions = weekPredictions.filter(
      (prediction) => store === prediction.store
    );
    return storeWeekPredictions.reduce((acc, curr) => {
      if (!isNaN(Number(curr[predictionByUnit])))
        return acc + Number(curr[predictionByUnit]);
      else return acc;
    }, 0);
  }

  function getMonthStorePredictedSum(column, store) {
    const weekPredictions = getMonthPredictions(column, predictionData);
    const storeMonthPredictions = weekPredictions.filter(
      (prediction) => store === prediction.store
    );
    return storeMonthPredictions.reduce((acc, curr) => {
      if (!isNaN(Number(curr[predictionByUnit])))
        return acc + Number(curr[predictionByUnit]);
      else return acc;
    }, 0);
  }

  function getMonthPredictions(month, predictions) {
    return predictions.filter(
      (prediction) =>
        prediction.month === month.month && prediction.year === month.year
    );
  }

  return (
    <TableWrapper
      resultCondition={() => selectedProductIds.length}
      loadingCondition={() => predictionData.length}
    >
      <TableControls
        freezeColumns={freezeColumns}
        columns={weekColumns}
        columnsWidth={monthColumnsWidth}
        columnsPosition={monthColumnsPosition}
        selectedUnit={selectedUnit}
        setSelectedUnit={setSelectedUnit}
        unitOptions={unitOptions}
      >
        <table
          id="download-table"
          className="table table-striped table-hover padding-070rem-on-th-and-td"
        >
          <thead>
            <ColumnTitlesRow
              freezeColumns={freezeColumns}
              showSeasonColumn={showSeasonColumn}
              monthColumns={weekColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              setMonthHeaderCellHeight={setMonthHeaderCellHeight}
              setMonthColumnsWidth={setMonthColumnsWidth}
              historicMonthsToDisplay={historicColumnsToDisplay}
              monthHeaderCellsSpan={1}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              defineLeftCellBorder={defineLeftCellBorder}
              renderColumnTitleLabel={({ month }) => (
                <TableColumnTitle
                  label={`Semana ${month.week} ${month.year}`}
                />
              )}
              thirdSectionColumns={monthColumns}
              renderThirdColumnTitleLabel={({ column }) => (
                <TableColumnTitle
                  label={`${getMonthName(column.month)} ${column.year}`}
                  breakWords
                />
              )}
              productCellRowSpan={openStores.length > 0 ? "1" : "3"}
              title="Produtos por Loja"
            />
            <tr>
              {openStores.length > 0 && (
                <HierarchyHeaderCells
                  rowSpan="2"
                  freezeColumns={freezeColumns}
                  sumPreviousColumnsWidth={sumPreviousColumnsWidth}
                  sortRowsBy={sortRowsBy}
                  setSortRowsBy={setSortRowsBy}
                  showSeasonColumn={showSeasonColumn}
                  evaluateLastCategoryColumn={evaluateLastCategoryColumn}
                  monthHeaderCellHeight={monthHeaderCellHeight}
                  defineHierarchyToDisplay={defineHierarchyToDisplay}
                  columns={historicColumnsToDisplay}
                  storesTable
                />
              )}
              {historicColumnsToDisplay.length > 0 && (
                <HistoricMonthHeaderCells
                  historicMonthsToDisplay={historicColumnsToDisplay}
                  upperCellsHeight={monthHeaderCellHeight}
                  setHistoricLabelCellHeight={setHistoricLabelCellHeight}
                  setHistoricColumnsWidth={setHistoricColumnsWidth}
                  storesTable
                />
              )}
              {weekColumns.map((week, index) =>
                storesFutureColumns({ predictionByUnit }).map((column, i) => (
                  <ColumnLabelCell
                    linesSpan="2"
                    column={column}
                    upperCellsHeight={monthHeaderCellHeight}
                    cellLeftBorder={defineLeftCellBorder(index, i)}
                    cellBottomBorder
                    renderSortByButton={
                      <WeekSortByButton
                        column={column}
                        weekObj={{ ...week, weeks: [week.week] }}
                        sortRowsBy={sortRowsBy}
                        setSortRowsBy={setSortRowsBy}
                      />
                    }
                    key={i}
                  />
                ))
              )}
              {monthColumns.map((month, index) =>
                storesFutureColumns({ predictionByUnit }).map((column, i) => (
                  <ColumnLabelCell
                    linesSpan="2"
                    column={column}
                    upperCellsHeight={monthHeaderCellHeight}
                    cellLeftBorder={defineLeftCellBorder(index, i)}
                    cellBottomBorder
                    renderSortByButton={
                      <MonthSortByButton
                        column={column}
                        monthObj={{ ...month, months: [month.month] }}
                        sortRowsBy={sortRowsBy}
                        setSortRowsBy={setSortRowsBy}
                      />
                    }
                    key={i}
                  />
                ))
              )}
            </tr>
            <tr>
              {historicColumnsToDisplay.map((week, index) => (
                <React.Fragment key={index}>
                  {historicColumns({ unit: selectedUnit }).map((column, i) => (
                    <ColumnLabelCell
                      column={column}
                      upperCellsHeight={
                        monthHeaderCellHeight + historicLabelCellHeight
                      }
                      cellLeftBorder={index !== 0 && i === 0}
                      cellTopBorder
                      cellBottomBorder
                      renderSortByButton={
                        <WeekSortByButton
                          column={column}
                          weekObj={{ ...week, weeks: [week.week] }}
                          sortRowsBy={sortRowsBy}
                          setSortRowsBy={setSortRowsBy}
                        />
                      }
                      key={i}
                    />
                  ))}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {stores.map((store, storeIndex) => (
              <React.Fragment key={storeIndex}>
                <tr>
                  <td
                    colSpan={defineProductsTitleSpan()}
                    className={`background-color-white
                    ${
                      freezeColumns ? "right-border-fade" : "cell-right-border"
                    }`}
                    style={{
                      position: freezeColumns ? "sticky" : "",
                      left: freezeColumns ? "0" : "",
                    }}
                  >
                    <div className="d-flex small-arrow-button">
                      <Button
                        icon={determineIcon(store)}
                        label=""
                        onClick={() => handleOpenStores(store)}
                        className="me-2"
                        type="ghost"
                      />
                      <Text>{store}</Text>
                    </div>
                  </td>
                  {historicColumnsToDisplay.map((column, index) => (
                    <React.Fragment key={index}>
                      <td className="text-center">
                        <BodyCell
                          prediction={getStoreRealized(column, store)}
                          format={selectedUnit}
                        />
                      </td>
                      <td className="text-center cell-right-border">
                        <PercentBodyCell
                          prediction={getStoreParticipation(column, store)}
                        />
                      </td>
                    </React.Fragment>
                  ))}
                  {weekColumns.map((week, index) => (
                    <td className={`text-center cell-right-border`} key={index}>
                      <BodyCell
                        prediction={getStorePredictedSum(week, store)}
                        format={selectedUnit}
                      />
                    </td>
                  ))}
                  {monthColumns.map((month, index) => (
                    <td
                      className={`text-center ${
                        index !== monthColumns.length - 1
                          ? "cell-right-border"
                          : ""
                      }`}
                      key={index}
                    >
                      <BodyCell
                        prediction={getMonthStorePredictedSum(month, store)}
                        format={selectedUnit}
                      />
                    </td>
                  ))}
                </tr>
                {openStores.includes(store) && (
                  <ProductRows
                    freezeColumns={freezeColumns}
                    sumPreviousColumnsWidth={sumPreviousColumnsWidth}
                    setHierarchyColumnsWidth={setHierarchyColumnsWidth}
                    showSeasonColumn={showSeasonColumn}
                    evaluateLastCategoryColumn={evaluateLastCategoryColumn}
                    predictionData={predictionData}
                    defineHierarchyToDisplay={defineHierarchyToDisplay}
                    selectedHierarchyIndexes={selectedHierarchyIndexes}
                    store={store}
                    sortRowsBy={sortRowsBy}
                    predictionByUnit={predictionByUnit}
                    historicPredictions={historicPredictions}
                    weekColumns={weekColumns}
                    monthColumns={monthColumns}
                    monthlyPredictions={monthlyPredictions}
                    historicColumnsToDisplay={historicColumnsToDisplay}
                    selectedUnit={selectedUnit}
                    selectedProductIds={selectedProductIds}
                  />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableControls>
    </TableWrapper>
  );
}
