import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const PREDICTIONS_URL = `${API_URL}/storePredictions`;

export async function getPredictions(
  token,
  forecastIds,
  productIds,
  sortBy,
  page,
  resultsPerPage
) {
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("limit", resultsPerPage);
  params.append("forecastIds", forecastIds.join(","));
  params.append("orderForecastId", sortBy.orderForecastId);
  params.append("column", sortBy.columnLabel);
  params.append("order", sortBy.order);

  if (productIds.length > 0) {
    params.append("productIds", productIds.join(","));
  }
  if (sortBy.weeks) {
    params.append("weeks", sortBy.weeks);
  }
  if (sortBy.months) {
    params.append("months", sortBy.months);
  }

  const predictionsUrl = `${PREDICTIONS_URL}/?${params.toString()}`;

  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getProductIdsFromStore(
  token,
  store,
  selectedProductIds,
  sortBy,
  page,
  resultsPerPage,
  onlyWeeklyPredictions
) {
  const params = new URLSearchParams();
  params.append("store", store);
  params.append("selectedProductIds", selectedProductIds);
  params.append("forecastId", sortBy.orderForecastId);
  params.append("column", sortBy.columnLabel);
  params.append("page", page);
  params.append("limit", resultsPerPage);

  if (sortBy.weeks) {
    params.append("weeks", sortBy.weeks);
  }
  if (sortBy.months) {
    params.append("months", sortBy.months);
  }
  if (onlyWeeklyPredictions) {
    params.append("onlyWeeklyPredictions", onlyWeeklyPredictions);
  }

  const predictionsUrl = `${PREDICTIONS_URL}/productIdsByStore/?${params.toString()}`;

  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function getConsolidatedPredictions(token, forecastId) {
  const predictionsUrl = `${PREDICTIONS_URL}/consolidated/?forecastId=${forecastId}`;
  const headers = {
    authorization: token,
  };
  const response = await axios.get(predictionsUrl, { headers });
  return response.data;
}

export async function updatePredictions(newPredictions, token) {
  const headers = { authorization: token };
  let response = await axios.patch(PREDICTIONS_URL, newPredictions, {
    headers,
  });
  return response.data;
}

export async function deletePredictions(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${PREDICTIONS_URL}/${id}`, { headers });
  return response.data;
}
