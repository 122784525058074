import styled from "styled-components";

const CardOutFill = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 16px;
  border: 1px solid #bbbcbd;
`;

export { CardOutFill };
