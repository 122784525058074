import styled from "styled-components";
import { Heading } from "@aidron/aidron-ds";

const CustomHeading = styled(Heading)`
  color: ${({ color }) => (color === "mediumGrey" ? "#5f5e5e" : color === "orange" ? "#e75d0d" : "")};
  font-size: ${({ size }) => (size === "xs" ? "18px" : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  margin: ${({ margin }) => (margin ? `${margin} !important` : "")};
`;

export default CustomHeading;
