import React from "react";
import { Button, Text } from "@aidron/aidron-ds";

import { capitalizeFirst, defineMonthColumns } from "../../../../../utils/utils";

import * as S from "./SecondaryCharts.style";

import ContentLoader from "../../../../../components/ContentLoader/ContentLoader";
import MultiSlideCarousel from "./MultiSlideCarousel/MultiSlideCarousel";
import MultipleProductsChart from "./MultipleProductsChart/MultipleProductsChart";

export default function SecondaryCharts({ selected, predictions, products, defineProductFilter }) {
  const monthColumns = defineMonthColumns(predictions);

  const selectedLevelNumber = selected.level[selected.level.length - 1];
  const nextLevel = `level${Number(selectedLevelNumber) + 1}`;

  const filteredProducts = getProducts();
  const allNextLevelOptions = filteredProducts.map(product => product[nextLevel]);
  const nextLevelOptions = [...new Set(allNextLevelOptions)];

  const result = nextLevelOptions.map(option => {
    const optionProducts = filteredProducts.filter(product => product[nextLevel] === option);
    const productIds = optionProducts.map(product => product.productId);
    return { name: option, productIds: productIds };
  });

  function getProductPredictions(result) {
    return predictions.filter(prediction => result.productIds.includes(prediction.productId));
  }

  function getProducts() {
    if (selected.level === "level0") {
      return products;
    } else {
      const filteredProducts = products.filter(product => product[selected.level] === selected.displayName.toUpperCase());
      return filteredProducts;
    }
  }

  return (
    <ContentLoader
      resultCondition={selected}
      contentCondition={result.length}
      renderError={() => <Text>Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.</Text>}
    >
      <MultiSlideCarousel slideCount={result.length} key={result}>
        {result.map((res, index) => (
          <S.ChartContainer key={index}>
            <MultipleProductsChart
              predictions={getProductPredictions(res)}
              monthColumns={monthColumns}
              heading={`Total ${capitalizeFirst(res.name)}`}
            />
            <S.ButtonContainer>
              <Button
                label="Analisar produtos"
                onClick={() => defineProductFilter({ [nextLevel]: [res.name] })}
                type="outlined"
              />
            </S.ButtonContainer>
          </S.ChartContainer>
        ))}
      </MultiSlideCarousel>
    </ContentLoader>
  );
}
