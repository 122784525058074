import React from "react";

import ButtonWithModal from "../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductDriversChart from "./ProductDriversChart/ProductDriversChart";

export default function ProductDriversButton({ productId }) {
  return (
    <ButtonWithModal
      label="Impacto na Venda"
      icon="arrow-up-right"
      type="outlined"
      modalHeading="Fatores que Impactam a Venda deste Produto"
      modalWidth="700px"
      renderModalContent={() => <ProductDriversChart productId={productId} />}
    />
  );
}
