import styled from "styled-components";

const p = styled.p`
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
`;

export { p };
