import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const SEASONS_URL = `${API_URL}/seasons`;

export async function getSeasons(token) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(SEASONS_URL, { headers });
  return response.data;
}

export async function saveSeason(id, newSeason, token) {
  const headers = { authorization: token };
  let response;
  if (id)
    response = await axios.patch(`${SEASONS_URL}/${id}`, newSeason, {
      headers,
    });
  else response = await axios.post(SEASONS_URL, newSeason, { headers });
  return response.data;
}

export async function deleteSeasons(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${SEASONS_URL}/${id}`, { headers });
  return response.data;
}

export async function getSeasonMonths(seasonId, token) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(`${SEASONS_URL}/months/${seasonId}`, {
    headers,
  });
  return response.data;
}

export async function deleteSeasonMonths(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${SEASONS_URL}/months/${id}`, {
    headers,
  });
  return response.data;
}
