import React, { useState, useEffect } from "react";

import { getProductIdsFromStore } from "../../../../services/StorePredictionsService";

import HierarchyBodyCells from "../../../../components/HierarchyBodyCells/HierarchyBodyCells";
import BodyCell from "../../../../components/BodyCell/BodyCell";
import PercentBodyCell from "../../../../components/PercentBodyCell/PercentBodyCell";
import PaginationButtons from "../../../../components/PaginationButtons/PaginationButtons";
import { historicColumns } from "../../../../utils/tableColumns";

function ProductRows({
  freezeColumns,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  defineHierarchyToDisplay,
  selectedHierarchyIndexes,
  selectedUnit,
  store,
  sortRowsBy,
  historicColumnsToDisplay,
  predictionByUnit,
  historicPredictions,
  weekColumns,
  predictionData,
  monthColumns,
  monthlyPredictions,
  selectedProductIds,
}) {
  const [productIdsToDisplay, setProductIdsToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const productResultsPerPage = 10;

  const columnsSpan =
    defineHierarchyToDisplay().length +
    historicColumnsToDisplay.length * 2 +
    weekColumns.length +
    monthColumns.length;

  useEffect(() => {
    fetchProductsData();
  }, [currentPage, sortRowsBy, selectedProductIds]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedProductIds]);

  async function fetchProductsData() {
    const token = localStorage.getItem("token");
    const onlyWeeklyPredictions = true;

    const result = await getProductIdsFromStore(
      token,
      store,
      selectedProductIds,
      sortRowsBy,
      currentPage,
      productResultsPerPage,
      onlyWeeklyPredictions
    );

    setProductIdsToDisplay(result.results);
    if (!pagination.lastPage) {
      setPagination({ lastPage: result.lastPage });
    }
  }

  function getProductValue(productId, columnName, column, predictions) {
    const prediction = predictions.find(
      (prediction) =>
        prediction.store === store &&
        prediction.productId === productId &&
        prediction.forecastId === column.forecastId &&
        prediction.week === column.week
    );

    return Number(prediction?.[columnName]);
  }

  return (
    <>
      {productIdsToDisplay.map((productId, index) => (
        <tr key={index}>
          <HierarchyBodyCells
            freezeColumns={freezeColumns}
            productId={productId}
            sumPreviousColumnsWidth={sumPreviousColumnsWidth}
            setHierarchyColumnsWidth={setHierarchyColumnsWidth}
            showSeasonColumn={showSeasonColumn}
            evaluateLastCategoryColumn={evaluateLastCategoryColumn}
            productRows={predictionData}
            defineHierarchyToDisplay={defineHierarchyToDisplay}
            selectedHierarchyIndexes={selectedHierarchyIndexes}
            storesTable
            selectedProductIds={productIdsToDisplay}
          />
          {historicColumnsToDisplay.map((column, index) => (
            <React.Fragment key={index}>
              {historicColumns({ unit: selectedUnit }).map((col, index) => (
                <td
                  className={`text-center ${
                    index === 1 ? "cell-right-border" : ""
                  }`}
                  key={index}
                >
                  {col.type === "prediction" && (
                    <BodyCell
                      prediction={getProductValue(
                        productId,
                        col.name,
                        column,
                        historicPredictions
                      )}
                      format={selectedUnit}
                    />
                  )}
                  {col.type === "%" && (
                    <PercentBodyCell
                      prediction={getProductValue(
                        productId,
                        col.name,
                        column,
                        historicPredictions
                      )}
                    />
                  )}
                </td>
              ))}
            </React.Fragment>
          ))}
          {weekColumns.map((column, index) => (
            <td key={index} className={`text-center cell-right-border`}>
              <BodyCell
                prediction={getProductValue(
                  productId,
                  predictionByUnit,
                  column,
                  predictionData
                )}
                format={selectedUnit}
              />
            </td>
          ))}
          {monthColumns.map((column, index) => (
            <td
              key={index}
              className={`text-center ${
                index !== monthColumns.length - 1 ? "cell-right-border" : ""
              }`}
            >
              <BodyCell
                prediction={getProductValue(
                  productId,
                  predictionByUnit,
                  column,
                  monthlyPredictions
                )}
                format={selectedUnit}
              />
            </td>
          ))}
        </tr>
      ))}
      <tr>
        <td colSpan={columnsSpan.toString()}>
          <div className="d-flex justify-content-end">
            <PaginationButtons
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pagination={pagination}
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export default ProductRows;
