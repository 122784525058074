import React from "react";

import * as S from "./Default.style";

import Header from "./Header/Header";

function Default({ children }) {
  return (
    <>
      <Header />
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </>
  );
}

export default Default;
