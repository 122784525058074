import React, { memo } from "react";
import { Text } from "@aidron/aidron-ds";
import { i18n } from "../../../../../translate/i18n";
import { getMonthName, capitalizeFirst } from "../../../../../utils/utils";

function SeasonsTable({ seasons }) {
  function writeMonthNames(months) {
    const monthNames = months.map((month) => getMonthName(month));
    if (monthNames.length === 1) return monthNames[0];
    else {
      const lastTwoElements = monthNames.splice(-2);
      const twoMonthsString = lastTwoElements.join(" " + i18n.t("and") + " ");
      if (monthNames.length > 0) {
        const arrayWithCommas = monthNames.map((monthName) => monthName + ", ");
        return arrayWithCommas.join("") + twoMonthsString;
      } else return twoMonthsString;
    }
  }

  return (
    <div className="max-height-600px overflow-scroll width-fit-content max-width-100percent">
      <table className="table table-striped table-hover table-inner-div">
        <thead>
          <tr>
            <th>
              <Text>{i18n.t("season")}</Text>
            </th>
            <th>
              <Text>{i18n.t("months")}</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {seasons.map((season, index) => (
            <tr key={index}>
              <td>
                <Text>{capitalizeFirst(season.name)}</Text>
              </td>
              <td>{writeMonthNames(season.months)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default memo(SeasonsTable);
