import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const USERS_URL = `${API_URL}/users`;

export async function updateUser(token, newUser) {
  const headers = { authorization: token };
  let response = await axios.patch(USERS_URL, newUser, {
    headers,
  });
  return response.data;
}
