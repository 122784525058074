import styled from "styled-components";

const Header = styled.div`
  margin-bottom: 1.5rem;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export { Header, Section, ChartContainer };
