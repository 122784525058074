import React, { useContext, useEffect, useState } from "react";
import { ForecastContext } from "../../../templates/context/ForecastContext";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import { to2DecimalsNumber } from "../../../utils/utils";
import { getConsolidatedPredictions } from "../../../services/PredictionsService";

import UpperCardModel from "../UpperCardModel/UpperCardModel";

export default function PlanOuterCard() {
  const { userInfo } = useContext(CompanyContext);
  const { selectedForecast } = useContext(ForecastContext);

  const [consolidatedPredictions, setConsolidatedPredictions] = useState([]);

  const planVsForecastValue = calculatePlanVsForecast().value;

  const planVsForecast = Math.abs(planVsForecastValue).toLocaleString("pt-BR");

  const percentDifference = to2DecimalsNumber(Math.abs(calculatePlanVsForecast().percentDifference)).toLocaleString("pt-BR");

  const difference =
    calculatePlanVsForecast().percentDifference > 0 ? { arrow: "↑", word: "acima" } : { arrow: "↓", word: "abaixo" };

  const companyId = userInfo.company.id;
  const unit = userInfo.company.unit;

  useEffect(() => {
    const token = localStorage.getItem("token");

    getConsolidatedPredictions(token, [selectedForecast.forecastId])
      .then(res => setConsolidatedPredictions(res[0]))
      .catch(err => console.log(err));
  }, []);

  function calculatePlanVsForecast() {
    let monthsCount = 0;
    let sumClientPrediction = 0;
    let sumAidronPrediction = 0;

    consolidatedPredictions.forEach(month => {
      if (month.clientPrediction && month.aidronPrediction) {
        monthsCount += 1;
        sumClientPrediction += month.clientPrediction;
        sumAidronPrediction += month.aidronPrediction;
      }
    });

    const differenceAidronVsClient = sumClientPrediction - sumAidronPrediction;
    return {
      monthsCount: monthsCount,
      value: differenceAidronVsClient,
      percentDifference: (differenceAidronVsClient / sumAidronPrediction) * 100,
    };
  }

  const productCardData = {
    title: "Produto",
    data: [
      {
        heading: "PLANO DE PRODUTO",
        number: `${difference.arrow} ${unit === "items" ? `${planVsForecast} pçs` : `R$ ${planVsForecast}`}`,
        text: `${percentDifference}% ${difference.word} do Forecast`,
        link: "",
      },
      {
        heading: "ANÁLISE DE PARETO",
        number: companyId === 4 || companyId === 3 ? 5 : "Em breve",
        numberText: companyId === 4 || companyId === 3 ? "(3,7% do total)" : "",
        text: "produtos somam 80% do gap",
        link: "",
      },
    ],
  };

  const channelCardData = {
    title: "Canais",
    data: [
      {
        heading: "META DOS CANAIS",
        number: companyId === 4 ? "↓ R$ 723.421 " : companyId === 3 || companyId === 2 ? "Aguardando dados" : "Em breve",
        text: "abaixo do Forecast",
        link: "",
      },
      {
        heading: "ANÁLISE DE PARETO",
        number: companyId === 4 ? "12" : companyId === 3 || companyId === 2 ? "Aguardando dados" : "Em breve",
        numberText: companyId === 4 ? "(7% do total)" : "",
        text: "produtos somam 80% do gap",
        link: "",
      },
    ],
  };

  return (
    <UpperCardModel
      title="PLANO ATUAL X FORECAST"
      text="Diferença em relação à soma de todos os meses"
      leftCardData={productCardData}
      rightCardData={channelCardData}
    />
  );
}
