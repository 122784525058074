import React, { useEffect, useRef } from "react";
import { Check } from "@aidron/aidron-ds";

function CheckCell({
  individualProductCheck,
  productId,
  freezeColumns,
  setCheckColumnWidth,
  checkedProducts,
  setCheckedProducts,
  productIds,
  headerHeight,
  defineHierarchyToDisplay,
}) {
  const checkColumn = useRef(null);
  const value = defineCheckValue();

  useEffect(() => {
    if (individualProductCheck)
      setCheckColumnWidth(checkColumn.current.getBoundingClientRect().width);
  }, []);

  function defineCheckValue() {
    if (individualProductCheck) {
      return checkedProducts.includes(productId) ? "1" : "0";
    } else {
      return areAllIdsInCheckedProducts() ? "1" : "0";
    }
  }

  function areAllIdsInCheckedProducts() {
    if (checkedProducts.length !== productIds.length) return false;
    return checkedProducts.every((productId) => productIds.includes(productId));
  }

  function handleAllChecks(e) {
    if (e === "0") {
      setCheckedProducts(productIds);
    } else {
      setCheckedProducts([]);
    }
  }

  function handleIndividualCheck(e) {
    if (e === "0") {
      setCheckedProducts((prevState) => [...prevState, productId]);
    } else {
      const index = checkedProducts.indexOf(productId);
      checkedProducts.splice(index, 1);
      setCheckedProducts([...checkedProducts]);
    }
  }

  function defineRightBorderFade() {
    if (defineHierarchyToDisplay().length === 0 && freezeColumns) {
      if (individualProductCheck) return "right-border-fade";
      else return "right-border-fade-with-background-color";
    }
    return "";
  }

  return (
    <th
      ref={checkColumn}
      className={`background-color-white vertical-align-middle padding-sides-030rem z-4 ${
        freezeColumns ? "position-sticky start-0" : ""
      } ${defineRightBorderFade()} ${
        individualProductCheck ? "" : "z-5 cell-bottom-border"
      }`}
      style={{
        top: individualProductCheck ? "" : `${headerHeight}px`,
      }}
    >
      <Check
        two
        handleChange={(e) => {
          if (individualProductCheck) handleIndividualCheck(e);
          else handleAllChecks(e);
        }}
        value={value}
      />
    </th>
  );
}

export default CheckCell;
