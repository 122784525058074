import React, { useContext } from "react";
import { ForecastContext } from "../../templates/context/ForecastContext";
// import {getMonthName, getLatestForecastFromMonth,getPreviousMonth,} from "../../utils/utils";

import SectionHeader from "../SectionHeader/SectionHeader";

export default function ConsolidatedViewHeader() {
  const {
    selectedForecast,
    // allForecasts
  } = useContext(ForecastContext);

  // const lastForecastOfPreviousMonth = getLatestForecastFromMonth(getPreviousMonth(selectedForecast), allForecasts);

  // const firstMonthOfPrediction = getMonthName(selectedForecast.month);

  // const pastDay = lastForecastOfPreviousMonth.day;
  // const pastMonth = lastForecastOfPreviousMonth.month;
  // const pastYear = lastForecastOfPreviousMonth.year;

  const { day, month, year } = selectedForecast;

  const subtitles = [
    // `Forecast de ${firstMonthOfPrediction} gerado em: ${pastDay}/${pastMonth}/${pastYear}`,
    `Forecast gerado em ${day}/${month}/${year}`,
  ];

  return <SectionHeader heading="Visão Consolidada" subtitles={subtitles} />;
}
