import styled from "styled-components";

const checkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const buttonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export { checkWrapper, buttonWrapper }