import { createContext, useState, useEffect } from "react";
import { Progress } from "@aidron/aidron-ds";
import { sortByMonthAndYear } from "../../utils/utils";
import { getForecasts } from "../../services/ForecastService";

export const ForecastContext = createContext();

export default function ForecastProvider({ children }) {
  const [allForecasts, setAllForecasts] = useState([]);
  const [selectedForecast, setSelectedForecast] = useState({});
  const [historicForecasts, setHistoricForecasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    try {
      const token = localStorage.getItem("token");
      const forecasts = await getForecasts(token, "predictions");

      let latestForecast = forecasts[forecasts.length - 1];

      setAllForecasts(forecasts);

      setSelectedForecast(latestForecast);

      // Descobrir qual forecast será considerado o oficial para meses passados
      const sortedForecasts = sortByMonthAndYear(forecasts);

      const officialForecasts = [];

      sortedForecasts.forEach((forecast) => {
        const lastOfficialForecastFound =
          officialForecasts[officialForecasts.length - 1];
        if (
          lastOfficialForecastFound &&
          forecast.month === lastOfficialForecastFound.month
        ) {
          if (forecast.day > lastOfficialForecastFound.day) {
            officialForecasts[officialForecasts.length - 1] = forecast;
          }
        } else {
          officialForecasts.push(forecast);
        }
      });

      const officialHistoricForecasts = officialForecasts.map((forecast) => {
        const date = new Date(forecast.year, forecast.month - 1, 1);
        date.setMonth(date.getMonth() + 1);

        return {
          forecastId: forecast.forecastId,
          officialPastForecastDay: forecast.day,
          officialPastForecastMonth: forecast.month,
          officialPastForecastYear: forecast.year,
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
      });

      const officialPastForecasts = officialHistoricForecasts.slice(0, -2);
      setHistoricForecasts(officialPastForecasts);

      setIsLoading(false);
    } catch (err) {
      console.log("ForecastProvider > fetchData ", err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ForecastContext.Provider
      value={{
        selectedForecast,
        allForecasts,
        historicForecasts,
      }}
    >
      {!isLoading ? (
        children
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100 width-40px-on-progress">
          <Progress circular />
        </div>
      )}
    </ForecastContext.Provider>
  );
}
