import styled from "styled-components";

const Card = styled.div`
  position: relative;
  display: ${({ display }) => (display ? display : "flex")};
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0.0625rem solid rgba(17, 24, 39, 0.125);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : "16px")};
  background-color: ${({ backgroundColor }) => (backgroundColor ? `${backgroundColor} !important` : "#fff")};
  margin: ${({ margin }) => (margin ? `${margin} !important` : "")};
  cursor: ${({ cursor }) => (cursor ? cursor : "")};

  &:hover {
    background-color: ${({ greyBackgroundOnHover }) => (greyBackgroundOnHover ? "rgba(17, 24, 39, 0.07)" : "")};
  }
`;

export default Card;
