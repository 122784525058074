import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  min-width: 340px;
  min-height: 400px;
  height: auto;

  @media (min-width: 800px) {
    width: 48%;
  }
`;

const InnerCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  height: 100%;
`;

export { Wrapper, InnerCardsWrapper };
