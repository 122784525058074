import React, { useContext, useEffect, useState } from "react";
import { Text, Card } from "@aidron/aidron-ds";
import { CompanyContext } from "../../../templates/context/CompanyContext";

import SearchInput from "../../SearchInput/SearchInput";
import HierarchyFilterBox from "./HierarchyFilterBox/HierarchyFilterBox";

function HierarchyFilters({ productIds, selectedProductIds, setSelectedProductIds }) {
  const { hierarchy, allProducts } = useContext(CompanyContext);

  const [inputtedExpressions, setInputtedExpressions] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState({});

  useEffect(() => {
    let filteredProductIds = productIds;

    if (isAnyHierarchySelected()) {
      filteredProductIds = filterProductsByHierarchy().map(product => product.productId);
    }

    if (inputtedExpressions.length) {
      const productsIdsByExpression = getProductIdsByExpression();
      filteredProductIds = filteredProductIds.filter(productId => productsIdsByExpression.includes(productId));
    }

    setSelectedProductIds(filteredProductIds);
  }, [selectedLevels, inputtedExpressions, productIds]);

  function isAnyHierarchySelected() {
    const selectedHierarchies = Object.values(selectedLevels);
    if (selectedHierarchies.some(selected => selected.length)) {
      return true;
    }
    return false;
  }

  function filterProductsByHierarchy() {
    let filteredProducts = productIds.map(productId => allProducts.find(product => product.productId === productId));
    filteredProducts = filteredProducts.filter(product => product);
    for (const level in selectedLevels) {
      if (selectedLevels[level].length) {
        filteredProducts = filteredProducts.filter(product => {
          return selectedLevels[level].includes(product[level]);
        });
      }
    }
    return filteredProducts;
  }

  // Hoje estamos permitindo que o usuário só digite uma expressão por vez, então uso aqui inputtedExpressions[0]
  function getProductIdsByExpression() {
    const expression = inputtedExpressions[0].toUpperCase();
    const filteredByExpression = allProducts.filter(product => {
      return hierarchy.levels.some(level => product[level].toUpperCase().includes(expression));
    });
    return filteredByExpression.map(product => product.productId);
  }

  return (
    <Card
      style={{
        margin: "10px 0 10px 0",
        padding: "10px 20px 10px 30px",
        backgroundColor: "rgb(252, 252, 252)",
      }}
    >
      <SearchInput inputtedExpressions={inputtedExpressions} setInputtedExpressions={setInputtedExpressions} />
      <Text size="sm" weight="bold" className="mb-2">
        Filtrar por hierarquia
      </Text>
      <div className="d-flex">
        {hierarchy.levels.map((level, index) => (
          <HierarchyFilterBox
            productIds={productIds}
            level={level}
            selectedProductIds={selectedProductIds}
            selectedLevels={selectedLevels}
            setSelectedLevels={setSelectedLevels}
            key={index}
          />
        ))}
      </div>
    </Card>
  );
}

export default HierarchyFilters;
