import styled from "styled-components";
import { Button } from "@aidron/aidron-ds";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextField = styled.div`
  div.textfield-outer div.ragnarok.textfield-wrapper {
    border: 1px solid rgba(17, 24, 39, 0.125);
    background-color: white;
  }
`;

const SearchButton = styled(Button)`
  margin-left: 0.5rem;
`;

const InputtedWordWrapper = styled.div`
  border: #f26f21 solid 2px;
  border-radius: 500px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0px 8px;
  margin-top: 5px;
  width: fit-content;
`;

const IconWrapper = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
`;

export { OuterContainer, InnerContainer, StyledTextField, SearchButton, InputtedWordWrapper, IconWrapper };
