import styled from "styled-components";

const LogoContainer = styled.div`
  margin-left: 2rem;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-left: 10%;
  overflow: visible;
  height: inherit;
`;

const CalendarButton = styled.button`
  color: white;
  background-color: transparent;
  margin-right: 1%.5;
  border-width: 0;
  box-shadow: none;
`;

export { LogoContainer, MenuContainer, CalendarButton };
