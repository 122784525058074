import React from "react";
import { Heading, Text } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./CardContent.style";

export default function CardContent({ label, number, legendNumber }) {
  return (
    <S.Wrapper>
      <Heading allcaps size="sm">
        {label}
      </Heading>
      <S.OrangeHeading size="xl">
        R$ {toNDecimalsString(number, 0)}
      </S.OrangeHeading>
      <S.Legend>
        <S.OrangeHeading size="md" marginRight>
          {legendNumber}
        </S.OrangeHeading>
        <Text>oportunidades</Text>
      </S.Legend>
    </S.Wrapper>
  );
}
