import React from "react";
import { Dialog, Heading, Button } from "@aidron/aidron-ds";

export default function ModalTemplate({ children, width, heading, closingFunction }) {
  return (
    <Dialog open overlay width={width}>
      <div className="d-flex justify-content-between">
        <Heading className="mt-3 mb-4" size="md">
          {heading}
        </Heading>
        <Button label="" onClick={closingFunction} type="ghost" icon="x" className="close-modal-button" />
      </div>
      {children}
    </Dialog>
  );
}
