import React, { memo, useState } from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { getMonthAbbreviation, sortByMonthAndYear } from "../../utils/utils";

function MonthColumnFilter({
  historicForecasts,
  onConcludedClick,
  selectedHistoricForecastIds,
  setSelectedHistoricForecastIds,
}) {
  const [selectedIds, setSelectedIds] = useState(selectedHistoricForecastIds);

  const historicForecastsCopy = [...historicForecasts];

  const orderedHistoricMonths = sortByMonthAndYear(
    historicForecastsCopy
  ).reverse();

  const historicYears = [
    ...new Set(orderedHistoricMonths.map((month) => month.year)),
  ];

  function getMonthsFromYear(year) {
    return orderedHistoricMonths
      .filter((month) => month.year === year)
      .reverse();
  }

  function onMonthClick(monthObj) {
    if (selectedIds.includes(monthObj.forecastId)) {
      const indexOfForecast = selectedIds.indexOf(monthObj.forecastId);
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(indexOfForecast, 1);
      setSelectedIds(newSelectedIds);
    } else {
      setSelectedIds([...selectedIds, monthObj.forecastId]);
    }
  }

  function handleSelectedHistoricMonths() {
    setSelectedHistoricForecastIds(sortByMonthAndYear(selectedIds));
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="max-height-600px overflow-scroll width-fit-content max-width-100percent mt-4">
          <table className="table table-inner-div">
            <tbody>
              {historicYears.map((year, index) => (
                <tr key={index}>
                  <td className="vertical-align-middle">
                    <Text weight="bold" className="font-size-17px">
                      {year}
                    </Text>
                  </td>
                  {getMonthsFromYear(year).map((monthObj, i) => (
                    <td className="padding-sides-030rem" key={i}>
                      <Button
                        label={getMonthAbbreviation(monthObj.month)}
                        onClick={() => onMonthClick(monthObj)}
                        type={
                          selectedIds.includes(monthObj.forecastId)
                            ? "outlined"
                            : "ghost"
                        }
                        className="box-shadow-unset box-shadow-unset-on-button"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          label="Concluído"
          onClick={() => {
            handleSelectedHistoricMonths();
            onConcludedClick();
          }}
        />
      </div>
    </>
  );
}

export default memo(MonthColumnFilter);
