import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const NumberBadge = styled.div`
  background-color: #e75d0d;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -4px;
  margin-right: -7px;
`;

export { Container, NumberBadge };
