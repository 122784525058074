import styled from "styled-components";

const OuterContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

export { OuterContainer, InnerContainer };
