import React, { memo } from "react";
import { Card, Text, Select, SelectItem, Heading } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";

function UsersSettingsTab() {
  const data = [
    {
      id: 1,
      name: "Luiza Fialho",
      role: "Admin",
    },
    {
      id: 2,
      name: "Vinicius Santos",
      role: "Editor",
    },
    {
      id: 3,
      name: "Rafa",
      role: "Viewer",
    },
    {
      id: 4,
      name: "Lucas",
      role: "Viewer",
    },
    {
      id: 5,
      name: "Johann",
      role: "Viewer",
    },
  ];

  const roleOptions = ["Admin", "Editor", "Viewer"];
  return (
    <Card style={{ margin: "20px 0 10px 0", padding: "20px 20px 20px 30px" }}>
      <Heading size="sm">{i18n.t("permissions")}</Heading>
      <div className="ms-3 mt-3">
        <table className="table table-striped table-hover overflow-scroll max-height-400px width-fit-content table-inner-div">
          <thead>
            <tr>
              <th>
                <Text>{i18n.t("user")}</Text>
              </th>
              <th>
                <Text>{i18n.t("role")}</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={index}>
                <td className="vertical-align-middle pe-5">
                  <Text>{user.name}</Text>
                </td>
                <td className="width-170px-on-select-component height-30px-on-select-component margin-top-11px-on-select-component gray-border-on-select-component white-background-on-select-component font-size-14px-on-select-options">
                  <Select
                    handleChange={() => {}}
                    label=""
                    readOnly
                    selected={user.role}
                  >
                    {roleOptions.map((role, index) => (
                      <SelectItem value={role} key={index}>
                        {role}
                      </SelectItem>
                    ))}
                  </Select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default memo(UsersSettingsTab);
