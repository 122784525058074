import React from "react";
import { Text } from "@aidron/aidron-ds";

export default function BodyCell({ prediction, format }) {
  const prefix = format === "items" ? "" : "R$ ";
  return (
    <Text className={format === "items" ? "text-center" : ""}>
      {!isNaN(prediction) && prediction !== null
        ? prefix + Math.ceil(prediction).toLocaleString("pt-BR")
        : "N/A"}
    </Text>
  );
}
