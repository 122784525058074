import React, { useState } from "react";
import { Button } from "@aidron/aidron-ds";

import * as S from "./ArrowModalButton.style";

import ModalTemplate from "../ModalTemplate/ModalTemplate";
import CustomText from "../../components/StyledComponents/CustomText/CustomText.style";

export default function ArrowModalButton({ label, renderModalContent, size }) {
  const [showModal, setShowModal] = useState(false);

  function onCloseModal() {
    setShowModal(false);
  }
  return (
    <>
      <S.OuterContainer>
        <S.InnerContainer>
          <CustomText weight="bold" margin="0 0.5rem 0 0">
            {label}
          </CustomText>
          <Button label="" onClick={() => setShowModal(true)} icon="arrow-up-right" type="outlined" size={size ? size : "sm"} />
        </S.InnerContainer>
      </S.OuterContainer>
      {showModal ? (
        <ModalTemplate width="900px" heading={label} closingFunction={onCloseModal}>
          {renderModalContent({ closeModal: onCloseModal })}
        </ModalTemplate>
      ) : (
        <></>
      )}
    </>
  );
}
