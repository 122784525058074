import React, { memo, useContext, useState } from "react";
import { Prompt } from "react-router-dom/cjs/react-router-dom";
import { Card, Text, Button, Icon, Heading } from "@aidron/aidron-ds";
import { i18n } from "../../../translate/i18n";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { removeExcessiveSpaces } from "../../../utils/utils";

import { getProducts, saveProducts } from "../../../services/ProductsService";
import { getSeasons, saveSeason } from "../../../services/SeasonsService";

import ModalTemplate from "../../../components/ModalTemplate/ModalTemplate";
import ProductRegistration from "./ProductRegistration/ProductRegistration";
import SeasonRegistration from "./SeasonRegistration/SeasonRegistration";
import HierarchyRegistration from "./HierarchyRegistration/HierarchyRegistration";

function OrganizationSettingsTab() {
  const { hierarchy, setNewHierarchy, setAllProducts, setAllSeasons } =
    useContext(CompanyContext);

  const [newLevelNames, setNewLevelNames] = useState(hierarchy.labels);
  const [newProducts, setNewProducts] = useState([createBlankNewProduct()]);
  const [newSeasons, setNewSeasons] = useState([createBlankNewSeason()]);
  const [savingStatus, setSavingStatus] = useState({ saved: [], notSaved: [] });

  function createBlankNewProduct() {
    const blankNewProduct = { ...hierarchy.labels };
    for (const key in blankNewProduct) {
      blankNewProduct[key] = "";
    }
    blankNewProduct.seasonId = "";
    return blankNewProduct;
  }

  function createBlankNewSeason() {
    return { name: "", months: [] };
  }

  async function onSave() {
    try {
      if (areNewLevelNamesModified()) await onSaveLevels();

      if (areNewProductsModified()) await onSaveProducts(newProducts);

      if (areNewSeasonsModified()) await onSaveSeasons(newSeasons);
    } catch (error) {
      console.log(error);
    }
  }

  async function onSaveLevels() {
    try {
      if (areNewLevelNamesValid()) {
        const formattedNewLevelNames = formatNewLevelNames();
        await saveNewLevelNames(formattedNewLevelNames);
        editSavingStatus("saved", "levelNames");
      }
    } catch (error) {
      handleError(error, "levelNames");
    }
  }

  async function onSaveProducts(inputtedNewProducts) {
    try {
      if (areNewProductsValid(inputtedNewProducts)) {
        const formattedNewProducts = formatNewProducts(inputtedNewProducts);
        const newProductsWithoutUnsetSeasons =
          transformUnsetSeasonsToNull(formattedNewProducts);
        await saveNewProducts(newProductsWithoutUnsetSeasons);
        editSavingStatus("saved", "products");
      }
    } catch (error) {
      handleError(error, "products");
    }
  }

  async function onSaveSeasons(inputtedNewSeasons) {
    try {
      if (areNewSeasonsValid(inputtedNewSeasons)) {
        const formattedNewSeasons = formatNewSeasons(inputtedNewSeasons);
        await saveNewSeasons(formattedNewSeasons);
        editSavingStatus("saved", "seasons");
      }
    } catch (error) {
      handleError(error, "seasons");
    }
  }

  function editSavingStatus(savedOrError, property) {
    setSavingStatus((prevState) =>
      editSavingStatusState(prevState, savedOrError, property)
    );
  }

  function editSavingStatusState(prevState, savedOrError, property) {
    const newSaved = [...prevState.saved];
    const newNotSaved = [...prevState.notSaved];
    if (savedOrError === "saved") newSaved.push(property);
    if (savedOrError === "notSaved") newNotSaved.push(property);
    return { saved: newSaved, notSaved: newNotSaved };
  }

  function handleError(error, property) {
    editSavingStatus("notSaved", property);
    console.error(error);
  }

  function formatNewLevelNames() {
    const modifiedNewLevelNames = { ...newLevelNames };
    Object.keys(modifiedNewLevelNames).forEach(
      (level) =>
        (modifiedNewLevelNames[level] = removeExcessiveSpaces(
          modifiedNewLevelNames[level]
        ))
    );
    return modifiedNewLevelNames;
  }

  function formatNewProducts(inputtedNewProducts) {
    return inputtedNewProducts.map((newProduct) => {
      const modifiedNewProduct = { ...newProduct };
      hierarchy.levels.forEach((level) => {
        modifiedNewProduct[level] = removeExcessiveSpaces(
          modifiedNewProduct[level]
        );
      });
      return modifiedNewProduct;
    });
  }

  function formatNewSeasons(inputtedNewSeasons) {
    return inputtedNewSeasons.map((newSeason) => ({
      ...newSeason,
      name: removeExcessiveSpaces(newSeason.name),
    }));
  }

  function areNewProductsModified() {
    return newProducts.some((newProduct) =>
      hierarchy.levels.some((level) => newProduct[level] !== "")
    );
  }

  function areNewProductsValid(inputtedNewProducts) {
    return inputtedNewProducts.every((newProduct) =>
      hierarchy.levels.every((level) => newProduct[level] !== "")
    );
  }

  async function saveNewProducts(transformedNewProducts) {
    try {
      const token = localStorage.getItem("token");
      for (const newProduct of transformedNewProducts) {
        await saveProducts(null, newProduct, token);
      }
      const updatedProducts = await getProducts(token);
      setAllProducts(updatedProducts);
      setNewProducts([createBlankNewProduct()]);
    } catch (error) {
      throw error;
    }
  }

  function transformUnsetSeasonsToNull(inputtedNewProducts) {
    return inputtedNewProducts.map((newProduct) => {
      return {
        ...newProduct,
        seasonId: newProduct.seasonId === "" ? null : newProduct.seasonId,
      };
    });
  }

  function areNewLevelNamesModified() {
    return Object.keys(newLevelNames).some(
      (level) => newLevelNames[level] !== hierarchy.labels[level]
    );
  }

  function areNewLevelNamesValid() {
    return Object.keys(newLevelNames).every(
      (level) => newLevelNames[level] !== ""
    );
  }

  async function saveNewLevelNames(inputtedNewLevelNames) {
    try {
      // const token = localStorage.getItem("token");
      // await saveHierarchies(hierarchyId, inputtedNewLevelNames, token);
      // Ao reativar esta funcionalidade, reescrever linha acima para salvar as hierarquias atualizadas
      const updatedHierarchy = "";
      // Ao reativar esta funcionalidade, reescrever linha acima para retornar as hierarquias atualizadas
      setNewHierarchy(updatedHierarchy);
    } catch (error) {
      throw error;
    }
  }

  function areNewSeasonsModified() {
    return newSeasons.some((newSeason) => newSeason.name !== "");
  }

  function areNewSeasonsValid(inputtedNewSeasons) {
    return inputtedNewSeasons.every(
      (newSeason) => newSeason.months.length !== 0
    );
  }

  async function saveNewSeasons(inputtedNewSeasons) {
    try {
      const token = localStorage.getItem("token");
      for (const newSeason of inputtedNewSeasons) {
        await saveSeason(null, newSeason, token);
      }
      const updatedSeasons = await getSeasons(token);
      setAllSeasons(updatedSeasons);
      setNewSeasons([createBlankNewSeason()]);
    } catch (error) {
      throw error;
    }
  }

  function areInputsModified() {
    return areNewLevelNamesModified() ||
      areNewProductsModified() ||
      areNewSeasonsModified()
      ? true
      : false;
  }

  function disableSaveButton() {
    if (areInputsModified()) {
      if (!areNewLevelNamesValid()) return true;

      if (areNewProductsModified()) {
        if (!areNewProductsValid(newProducts)) return true;
      }

      if (areNewSeasonsModified()) {
        if (!areNewSeasonsValid(newSeasons)) return true;
      }
      return false;
    }
    return true;
  }

  function onImportModalClick(showModalSetter) {
    if (areInputsModified()) {
      const confirmExit = window.confirm(
        i18n.t("unsavedChangesModal.promptBody")
      );

      if (confirmExit) {
        clearAllInputFields();
        showModalSetter(true);
      }
    } else showModalSetter(true);
  }

  function clearAllInputFields() {
    setNewLevelNames(hierarchy.labels);
    setNewProducts([createBlankNewProduct()]);
    setNewSeasons([createBlankNewSeason()]);
  }

  return (
    <>
      <Prompt
        when={areInputsModified() ? true : false}
        message={i18n.t("unsavedChangesModal.promptBody")}
      />
      <Card style={{ margin: "20px 0 10px 0", padding: "20px 20px 20px 30px" }}>
        <div className="row">
          <div className="col-10">
            <HierarchyRegistration
              newLevelNames={newLevelNames}
              setNewLevelNames={setNewLevelNames}
            />
            <ProductRegistration
              newProducts={newProducts}
              setNewProducts={setNewProducts}
              createBlankNewProduct={createBlankNewProduct}
              onImportModalClick={onImportModalClick}
              onSaveProducts={onSaveProducts}
              savingStatus={savingStatus}
            />
            <SeasonRegistration
              newSeasons={newSeasons}
              setNewSeasons={setNewSeasons}
              createBlankNewSeason={createBlankNewSeason}
              onImportModalClick={onImportModalClick}
              onSaveSeasons={onSaveSeasons}
              savingStatus={savingStatus}
            />
          </div>
          <div className="col-2">
            <div className="position-absolute top-0 end-0 me-4 z-2 pt-3">
              <Button
                label={i18n.t("saveModifications")}
                onClick={() => onSave(newSeasons)}
                size="lg"
                disabled={disableSaveButton()}
                className="mt-2 mb-2"
              />
            </div>
          </div>
        </div>
      </Card>
      {savingStatus.saved.length || savingStatus.notSaved.length ? (
        <ModalTemplate
          width="500px"
          heading=" "
          closingFunction={() => setSavingStatus({ saved: [], notSaved: [] })}
        >
          <div className="my-3">
            {savingStatus.saved.length ? (
              savingStatus.saved.map((property, index) => (
                <div className="d-flex mb-3" key={index}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Icon
                      icon="check"
                      className="icon-with-round-border background-color-green color-white me-2"
                    />
                  </div>
                  <Heading size="sm" className="mb-0">{`${i18n.t("new")} ${i18n
                    .t(property)
                    .toLowerCase()} ${i18n.t("wereSaved")}!`}</Heading>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {savingStatus.notSaved.length ? (
            <>
              {savingStatus.notSaved.map((property, index, arr) => (
                <div
                  className={`d-flex ${index === arr.length ? "" : "mb-0"}`}
                  key={index}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Icon
                      icon="x"
                      className="icon-with-round-border background-color-red color-white me-2"
                    />
                  </div>
                  <Heading size="sm" className="mb-0">{`${i18n.t(
                    property
                  )} ${i18n.t("wereNotSaved")}.`}</Heading>
                </div>
              ))}
              <Text className="verify-data-paragraph-margin">
                {i18n.t("verifyData")}
              </Text>
            </>
          ) : (
            <></>
          )}
        </ModalTemplate>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(OrganizationSettingsTab);
