import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getForecasts } from "../../../services/ForecastService";
import { getOpportunitiesCount } from "../../../services/OpportunityService";
import { toNDecimalsString } from "../../../utils/utils";

import UpperCardModel from "../UpperCardModel/UpperCardModel";

export default function OpportunityOuterCard() {
  const { userInfo } = useContext(CompanyContext);
  const companyId = userInfo.company.id;

  const [counter, setCounter] = useState({});

  const opportunitiesTotal = isNumber(counter.totalValue) ? toNDecimalsString(counter.totalValue, 0) : 0;

  const stockOpportunity = counter.counter?.find(obj => obj.type === "stock");
  const stockTotal = isNumber(stockOpportunity?.totalValue) ? toNDecimalsString(stockOpportunity?.totalValue, 0) : "0";

  useEffect(() => {
    fetchInitialData();
  }, []);

  async function fetchInitialData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "opportunities");
    const latestForecast = forecasts[forecasts.length - 1];

    const status = ["active", "reactivated", "checkout"];
    const result = await getOpportunitiesCount(token, latestForecast.forecastId, status);
    setCounter(result);
  }

  function isNumber(number) {
    return /\d/.test(number);
  }

  const productCardData = {
    title: "Produto",
    data: [
      {
        heading: "TOTAL DE OPORTUNIDADES",
        number: companyId === 4 ? "R$ 28.555.792" : opportunitiesTotal ? `R$ ${opportunitiesTotal}` : "Em breve",
        text: "somando estoque e vendas",
        link: "",
      },
      {
        heading: "EM OTIMIZAÇÃO DE ESTOQUE",
        number: companyId === 4 ? "R$ 3.742.526" : stockTotal ? `R$ ${stockTotal}` : "Em breve",
        text: "redução de custo",
        link: "",
      },
    ],
  };

  const channelCardData = {
    title: "Canais",
    data: [
      {
        heading: "TOTAL DE OPORTUNIDADES",
        number: companyId === 4 ? "R$ 12.123.421" : companyId === 3 ? "Em breve" : "Em breve",
        text: "ajustando plano e estoque",
        link: "",
      },
      {
        heading: "AJUSTE DE SORTIMENTO",
        number: companyId === 4 ? "R$ 8.952.012" : companyId === 3 ? "Em breve" : "Em breve",
        text: "venda adicional",
        link: "",
      },
    ],
  };

  return (
    <UpperCardModel
      title="OPORTUNIDADES"
      text="Otimização de estoque ou aumento de vendas"
      leftCardData={productCardData}
      rightCardData={channelCardData}
    />
  );
}
