import React, { useEffect, useState } from "react";
import { storesConsolidatedColumns } from "../../../utils/tableColumns";
import { getLatestForecastFromMonth, getPreviousMonth } from "../../../utils/utils";

import { getConsolidatedPredictions } from "../../../services/PredictionsService";
import { getForecasts } from "../../../services/ForecastService";

import ConsolidatedPredictionsTable from "../../../components/ConsolidatedPredictionsTable/ConsolidatedPredictionsTable";
import TableDownloadButton from "../../../components/TableDownloadButton/TableDownloadButton";
import ChannelSectionHeader from "../ChannelSectionHeader/ChannelSectionHeader";
import ChannelChart from "./ChannelChart/ChannelChart";

export default function ChannelCurrentPlan({ sortBy }) {
  const [consolidatedPredictions, setConsolidatedPredictions] = useState([]);
  const [selectedForecast, setSelectedForecast] = useState({});
  const [lastForecastOfPreviousMonth, setLastForecastOfPreviousMonth] = useState({ day: 0, month: 0, year: 0 });

  useEffect(async () => {
    const token = localStorage.getItem("token");
    const forecasts = await getForecasts(token, "predictions");
    let latestForecast = forecasts[forecasts.length - 1];

    setSelectedForecast(latestForecast);

    const firstPredictedMonthGenerationDate = getLatestForecastFromMonth(getPreviousMonth(latestForecast), forecasts);

    setLastForecastOfPreviousMonth(firstPredictedMonthGenerationDate);

    getConsolidatedPredictions(token, [latestForecast.forecastId])
      .then(res => {
        setConsolidatedPredictions(res[0]);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <ChannelSectionHeader selectedForecast={selectedForecast} lastForecastOfPreviousMonth={lastForecastOfPreviousMonth} />
        <div className="d-flex justify-content-end mb-3 box-shadow-unset-on-select background-color-unset-on-select padding-left-0-on-select">
          <TableDownloadButton fileName="Lojas" />
        </div>
      </div>
      <div className="d-flex justify-content-around mb-5">
        <ConsolidatedPredictionsTable
          consolidatedPredictions={consolidatedPredictions}
          columns={storesConsolidatedColumns()}
          title="Total Lojas"
          selectedForecast={selectedForecast}
        />
        {consolidatedPredictions.length > 0 && (
          <ChannelChart selectedForecast={selectedForecast} sortBy={sortBy} consolidatedPredictions={consolidatedPredictions} />
        )}
      </div>
    </>
  );
}
