import React, { useEffect, useState } from "react";
import { getForecasts } from "../../services/ForecastService";

import * as S from "./ChannelOpportunity.style";

import Default from "../../components/Default/Default";
import OpportunityOptions from "./OpportunityOptions/OpportunityOptions";
import ProductListItems from "./ProductListItems/ProductListItems";
import TableWrapper from "../../components/TableWrapper/TableWrapper";
import OpportunityCounter from "./OpportunityCounter/OpportunityCounter";

import Opportunities from "../../services/opportunities.json";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function Opportunity() {
  const [forecast, setForecast] = useState({});
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [types, setTypes] = useState([]);
  const [order, setOrder] = useState("descending");

  const token = localStorage.getItem("token");

  const opportunities = Opportunities.filter(opportunity => opportunity.store);

  const allTypeOptions = [
    {
      value: "sale",
      displayName: "Venda Adicional",
    },
    {
      value: "stock",
      displayName: "Otimização de Estoque",
    },
  ];

  const typeOptions = allTypeOptions.filter(type => types.includes(type.value));

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedProductIds, selectedTypes, order]);

  async function fetchInitialData() {
    const latestForecast = await getForecasts(token, "opportunities");
    setForecast(latestForecast[0]);
  }

  async function fetchData() {
    const newOpportunities = Opportunities;
    // setOpportunities(newOpportunities.opportunities);
    const newTypes = ["Aumento da Meta", "Otimização de Sortimento", "Ajuste na Meta"];
    const newProductIds = Array.from({ length: 6 }, (_, i) => i + 1);

    if (productIds.length === 0) {
      setProductIds(newProductIds);
      setSelectedProductIds(newProductIds);
    }

    if (types.length === 0) {
      setTypes(newTypes);
      setSelectedTypes(newTypes);
    }
  }

  function resultCondition() {
    const isAnyFilterApplied = selectedTypes.length < types.length || selectedProductIds.length < productIds.length;
    return opportunities.length || !isAnyFilterApplied;
  }

  return (
    <Default>
      <S.HeaderContent>
        <PageHeading>Oportunidades de Produto</PageHeading>
        <OpportunityCounter />
      </S.HeaderContent>

      <OpportunityOptions
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        order={order}
        setOrder={setOrder}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        typeOptions={typeOptions}
      />

      <TableWrapper loadingCondition={() => types.length} resultCondition={resultCondition}>
        <ProductListItems opportunities={opportunities} />
      </TableWrapper>
    </Default>
  );
}
