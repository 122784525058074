import React from "react";
import { Card, Heading, Progress } from "@aidron/aidron-ds";

export default function TableWrapper({
  loadingCondition,
  resultCondition,
  children,
}) {
  return loadingCondition() ? (
    resultCondition() ? (
      children
    ) : (
      <Card>
        <Heading size="sm">Sua busca não retornou resultados.</Heading>
      </Card>
    )
  ) : (
    <div className="d-flex justify-content-center align-items-center width-40px-on-progress">
      <Progress circular />
    </div>
  );
}
