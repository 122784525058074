import React, { useContext, useState } from "react";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { ForecastContext } from "../../../../templates/context/ForecastContext";

import { historicColumns } from "../../../../utils/tableColumns";

import HierarchyHeaderCells from "../../../../components/HierarchyHeaderCells/HierarchyHeaderCells";
import HistoricMonthHeaderCells from "../../../../components/HistoricMonthHeaderCells/HistoricMonthHeaderCells";
import ColumnLabelCell from "../../../../components/ColumnLabelCell/ColumnLabelCell";
import MonthSortByButton from "../../../../components/MonthSortByButton/MonthSortByButton";
import DifSortByButton from "../../../../components/DifSortByButton/DifSortByButton";

export default function PredictionsColumnLabelsRow({
  freezeColumns,
  sumPreviousColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  monthColumns,
  monthHeaderCellHeight,
  sortRowsBy,
  setSortRowsBy,
  historicMonthsToDisplay,
  defineHierarchyToDisplay,
  defineLeftCellBorder,
  setHierarchyCellHeight,
  setHistoricColumnsWidth,
  futureColumns,
  selectedProductIds,
}) {
  const { userInfo } = useContext(CompanyContext);
  const { selectedForecast } = useContext(ForecastContext);
  const [historicLabelCellHeight, setHistoricLabelCellHeight] = useState(0);

  return (
    <>
      <tr>
        <th
          rowSpan="2"
          className={`category-th cell-bottom-border subtitle-cell z-3 vertical-align-middle background-color-white padding-sides-030rem z-5 position-sticky ${
            freezeColumns ? "start-0" : ""
          }`}
          style={{
            top: `${monthHeaderCellHeight}px`,
          }}
        />
        <HierarchyHeaderCells
          rowSpan="2"
          freezeColumns={freezeColumns}
          sumPreviousColumnsWidth={sumPreviousColumnsWidth}
          sortRowsBy={sortRowsBy}
          setSortRowsBy={setSortRowsBy}
          showSeasonColumn={showSeasonColumn}
          evaluateLastCategoryColumn={evaluateLastCategoryColumn}
          monthHeaderCellHeight={monthHeaderCellHeight}
          defineHierarchyToDisplay={defineHierarchyToDisplay}
          setHierarchyCellHeight={setHierarchyCellHeight}
          columns={historicMonthsToDisplay}
          productIds={selectedProductIds}
        />
        {historicMonthsToDisplay.length > 0 && (
          <HistoricMonthHeaderCells
            historicMonthsToDisplay={historicMonthsToDisplay}
            upperCellsHeight={monthHeaderCellHeight}
            setHistoricLabelCellHeight={setHistoricLabelCellHeight}
            setHistoricColumnsWidth={setHistoricColumnsWidth}
          />
        )}
        {monthColumns.map((month, index) =>
          futureColumns({
            companyName: userInfo.company.name,
            month: month,
            forecastGenerationMonth: selectedForecast,
          }).map((column, i) => (
            <ColumnLabelCell
              column={column}
              upperCellsHeight={monthHeaderCellHeight}
              linesSpan="2"
              cellLeftBorder={defineLeftCellBorder(index, i)}
              cellBottomBorder
              renderSortByButton={
                column.type === "dif%" ? (
                  <DifSortByButton
                    column={column}
                    monthObj={{ ...month, months: [month.month] }}
                    sortRowsBy={sortRowsBy}
                    setSortRowsBy={setSortRowsBy}
                  />
                ) : (
                  <MonthSortByButton
                    column={column}
                    monthObj={{ ...month, months: [month.month] }}
                    sortRowsBy={sortRowsBy}
                    setSortRowsBy={setSortRowsBy}
                  />
                )
              }
              key={i}
            />
          ))
        )}
      </tr>
      <tr>
        {historicMonthsToDisplay.map((month, index) => (
          <React.Fragment key={index}>
            {historicColumns({}).map((column, i) => (
              <ColumnLabelCell
                column={column}
                upperCellsHeight={monthHeaderCellHeight + historicLabelCellHeight}
                cellLeftBorder={index !== 0 && i === 0}
                cellTopBorder
                cellBottomBorder
                renderSortByButton={
                  <MonthSortByButton
                    column={column}
                    monthObj={{ ...month, months: [month.month] }}
                    sortRowsBy={sortRowsBy}
                    setSortRowsBy={setSortRowsBy}
                  />
                }
                key={i}
              />
            ))}
          </React.Fragment>
        ))}
      </tr>
    </>
  );
}
