import styled from "styled-components";

import { Heading } from "@aidron/aidron-ds";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export { Wrapper, Legend };
