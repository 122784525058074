import React, { useContext, useEffect, useState } from "react";
import { Card, Text } from "@aidron/aidron-ds";

import { ForecastContext } from "../../../../templates/context/ForecastContext";
import { getConsolidatedIndexes } from "../../../../services/PredictionsService";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./IndexesCard.style";

import CardHeading from "../CardHeading/CardHeading";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";

export default function IndexesCard({ selected, products }) {
  const { selectedForecast } = useContext(ForecastContext);

  const [indexes, setIndexes] = useState({});

  const selectedProducts =
    selected.level === "level0"
      ? products
      : products.filter(product => product[selected.level] === selected.displayName.toUpperCase());
  const selectedProductIds = selectedProducts.map(product => product.productId);

  useEffect(() => {
    if (products.length > 0) {
      fetchData();
    }
  }, [selected]);

  async function fetchData() {
    const token = localStorage.getItem("token");
    const result = await getConsolidatedIndexes(token, selectedForecast.forecastId, selectedProductIds);
    setIndexes(result);
  }

  function formatIndex(value) {
    return `${toNDecimalsString(value, 0)}%`;
  }

  return (
    <Card>
      <ContentLoader
        resultCondition={indexes}
        contentCondition={!isNaN(indexes.aboveICPercent)}
        renderError={() => <Text>Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.</Text>}
      >
        <S.OuterContainer>
          <div>
            <CardHeading heading="ÓTIMA PERFORMANCE" />
            <CustomHeading color="orange" textAlign="center" size="xl">
              {formatIndex(indexes.aboveICPercent)}
            </CustomHeading>
            <CustomText size="xs" textAlign="center">
              dos produtos com venda acima da demanda
            </CustomText>
          </div>

          <div>
            <CardHeading heading="PONTOS DE ATENÇÃO" />
            <CustomHeading color="orange" textAlign="center" size="xl">
              {formatIndex(indexes.underICPercent)}
            </CustomHeading>
            <CustomText size="xs" textAlign="center">
              dos produtos com venda abaixo da demanda
            </CustomText>
          </div>
        </S.OuterContainer>
      </ContentLoader>
    </Card>
  );
}
