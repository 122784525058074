import React, { useEffect, useRef } from "react";
import { Text } from "@aidron/aidron-ds";
import { getMonthName } from "../../utils/utils";

export default function HistoricMonthHeaderCells({
  historicMonthsToDisplay,
  upperCellsHeight,
  setHistoricLabelCellHeight,
  setHistoricColumnsWidth,
  storesTable,
}) {
  const columnRefs = useRef();

  function defineColumnRefs(node, index) {
    const map = getMonthRefs();
    if (node) {
      map.set(index, node);
    } else {
      map.delete(index);
    }
  }

  function getMonthRefs() {
    if (!columnRefs.current) {
      columnRefs.current = new Map();
    }
    return columnRefs.current;
  }

  useEffect(() => {
    if (columnRefs.current) {
      const firstColumn = [...columnRefs.current.values()][0];
      setHistoricLabelCellHeight(firstColumn.getBoundingClientRect().height);
    }
  }, [upperCellsHeight]);

  useEffect(() => {
    if (columnRefs.current) {
      const columnsWidth = [];
      for (let value of columnRefs.current.values()) {
        columnsWidth.push(value.getBoundingClientRect().width);
      }
      setHistoricColumnsWidth(columnsWidth);
    }
  }, [upperCellsHeight]);

  return (
    <>
      {historicMonthsToDisplay.map((month, index) => (
        <th
          ref={(node) => defineColumnRefs(node, index)}
          colSpan="2"
          className={`subtitle-cell vertical-align-middle ${
            index ? "cell-left-border" : ""
          }`}
          style={{ top: `${upperCellsHeight}px` }}
          key={index}
        >
          <div className="d-flex align-items-center justify-content-center small-arrow-button table-text width-12px-on-icon height-12px-on-icon position-relative">
            <Text className="text-center">
              {storesTable
                ? `Semana ${month.week}`
                : getMonthName(month.month.toString())}{" "}
              {month.year}
            </Text>
          </div>
        </th>
      ))}
    </>
  );
}
