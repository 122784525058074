import styled from "styled-components";

const OuterChartContainer = styled.div`
  width: 70%;
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerChartContainer = styled.div`
  width: 90%;
`;

export { OuterChartContainer, InnerChartContainer };
