import React from "react";

import PercentBodyCell from "../PercentBodyCell/PercentBodyCell";
import BodyCell from "../BodyCell/BodyCell";

export default function BodyCells({
  individualProduct,
  columns,
  prediction,
  productIds,
  defineLeftCellBorder,
  monthIndex,
  headerHeight,
  cellTopBorder,
  unit,
}) {
  return (
    productIds.length > 0 &&
    columns.map((column, index) => (
      <td
        className={`text-center background-color-white ${defineLeftCellBorder(monthIndex, index) ? "cell-left-border" : ""} ${
          individualProduct ? "" : "position-sticky cell-bottom-border z-1"
        } ${cellTopBorder ? "cell-top-border" : ""}`}
        style={{ top: individualProduct ? "" : `${headerHeight}px` }}
        key={index}
      >
        {column.type === "prediction" && <BodyCell prediction={prediction?.[column.name]} format={unit} />}
        {column.type === "%" && <PercentBodyCell prediction={prediction?.[column.name]} />}
        {column.type === "dif%" && <PercentBodyCell prediction={prediction?.[column.name]} showFlag />}
        {column.type === "dif" && (
          <PercentBodyCell
            prediction={prediction?.[column.name]}
            showFlag
            hidePercent
            unit={unit}
            predictionForFlagColor={prediction?.[column.colorReferenceFrom]}
          />
        )}
      </td>
    ))
  );
}
