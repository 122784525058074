import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const PRODUCTS_URL = `${API_URL}/products`;

export async function getProducts(token) {
  const headers = {
    authorization: token,
  };
  const response = await axios.get(PRODUCTS_URL, { headers });
  return response.data;
}

export async function saveProducts(id, newProduct, token) {
  const headers = { authorization: token };
  let response;
  if (id)
    response = await axios.patch(`${PRODUCTS_URL}/${id}`, newProduct, {
      headers,
    });
  else response = await axios.post(PRODUCTS_URL, newProduct, { headers });
  return response.data;
}

export async function deleteProducts(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${PRODUCTS_URL}/${id}`, { headers });
  return response.data;
}
