import React from "react";
import { Card, Text } from "@aidron/aidron-ds";

import * as S from "./ActionOuterCard.style";
import SimulatorIcon from "../../../assets/image/simulacoes-icone.png";

import OuterCardHeading from "../OuterCardHeading/OuterCardHeading";

export default function ActionOuterCard() {
  return (
    <S.OuterWrapper>
      <Card style={{ height: "100%" }}>
        <OuterCardHeading title="SIMULAR AÇÕES" text="Simule diversos cenários futuros em tempo real" />
        <S.InnerWrapper>
          <S.ContentContainer>
            <S.Icon src={SimulatorIcon} />
            <Text>explore cenários internos e externos</Text>
          </S.ContentContainer>
        </S.InnerWrapper>
      </Card>
    </S.OuterWrapper>
  );
}
