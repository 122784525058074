const messages = {
  pt: {
    translations: {
      headings: {
        dashboard: "Dashboard",
        forecast: {
          title: "Forecast",
          downloads: "Downloads",
          analysis: "Análise",
        },
        automations: "Automações",
        settings: "Configurações",
      },
      sideBar: {
        editAvatarHeading: "Editar sua foto",
        addAvatar: "Adicionar foto",
        deleteAvatar: "Excluir foto",
      },
      languageSelector: "Idioma",
      company: "Empresa",
      login: {
        title: "Acesse nossa plataforma",
        emailLabel: "Seu e-mail",
        emailPlaceholder: "exemplo@empresa.com.br",
        passwordLabel: "Sua senha",
        passwordPlaceholder: "minhasenha",
        signIn: "Entrar",
        error: "Usuário e/ou senha inválidos!",
      },
      settings: "Configurações",
      myAccount: "Minha conta",
      users: "Usuários",
      organization: "Organização",
      logout: "Sair",
      profilePicture: "Foto do perfil",
      chosePicture: "Escolher foto",
      permissions: "Permissões",
      user: "Usuário",
      role: "Papel",
      productHierarchy: "Hierarquia de produtos",
      numberOfLevels: "Quantidade de níveis",
      level: "Nível",
      levelName: "Nome do nível",
      channelRegistration: "Cadastro de canais",
      // FORECAST SECTION:
      year: "Ano",
      month: "Mês",
      weeks: "Semanas",
      months: "Meses",
      filterBy: "Filtrar por",
      generatedOn: "Predição gerada em",
      generationDate: "Data de geração",
      forecastDate: "Data do forecast",
      commercialWeek: "Semana comercial",
      commercialWeeks: "Semanas comerciais",
      monthlyForecast: "Forecast mensal",
      showFilter: "Filtros",
      hideFilter: "Ocultar filtros",
      removeFilters: "Remover filtros",
      products: "Produtos",
      fileError:
        "O arquivo selecionado está indisponível. Selecione datas diferentes",
      summary: "Resumo do download",
      singleFile: "Arquivo único (.xlsx)",
      singleFileCaption: "Uma aba por semana",
      multipleFiles: "Arquivos separados (.zip)",
      multipleFilesCaption: "Um arquivo por semana",
      exportMultiple: "Exportar vários",
      advancedExport: "Exportação avançada",
      forecasts: "Forecasts",
      selectCommWeek: "Forecasts gerados nas seguintes semanas comerciais:",
      next: "Próximo",
      changeWeekModal: {
        body: "Se você alterar as semanas comerciais selecionadas, os filtros de produto aplicados serão resetados.",
      },
      attention: "Atenção!",
      options: "Opções",
      downloadSummary: "Resumo do download",
      addAnotherMale: "Adicionar outro",
      addAnotherFemale: "Adicionar outra",
      addPeriod: "Adicionar período",
      commercialCalendar: "Calendário Comercial",
      insertYear: "Insira um ano entre 1000 e 9999",
      sort: "Ordenar",
      graphicHeading: "Análise Gráfica",
      descriptiveHeading: "Análise Descritiva",
      classicHeading: "Análise Clássica",
      productFilter: "Filtrar por produto",
      brand: "Coleção",
      griffe: "Griffe",
      grife: "Grife",
      line: "Linha",
      group: "Grupo",
      subgroup: "Subgrupo",
      marca: "Marca",
      colecao: "Coleção",
      linha: "Linha",
      grupo: "Grupo",
      subgrupo: "Subgrupo",
      subgrupos: "Subgrupos",
      graphType: "Estilo de gráfico",
      lines: "Linhas",
      columns: "Colunas",
      showing: "Mostrando",
      product: "produto",
      estimated: "Previsto",
      realized: "Realizado",
      percentDiff: "Dif %",
      diff: "Diferença %",
      wape: "WAPE",
      realizado: "Realizado",
      selectOneOption: "Selecione uma opção",
      selectAtLeastOne: "Selecione ao menos uma opção",
      sortBy: "Ordenar por",
      highest: "Maior",
      lowest: "Menor",
      // Página "Análise Descritiva"
      aidronView: "Visualização Aidron",
      classicView: "Visualização Clássica",
      resultsSubtitle: "Previsão de demanda (em peças)",
      week: "Semana",
      all: "Todos",
      choseProducts: "Escolher produtos",
      checkAll: "Marcar todos",
      uncheckAll: "Desmarcar todos",
      analyseGraph: "Analisar gráfico",
      analyse: "Analisar",
      close: "Fechar",
      estActDif: "Prev  |  Real  |  Dif%",
      centerMonth: "Centralizar mês",
      centerWeek: "Centralizar semana",
      expand: "Expandir dados",
      shrink: "Reduzir dados",
      selectedProducts: "Produtos selecionados",
      // Página "Visualização Clássica"
      freezeColumns: "Fixar colunas",
      hideColumns: "Ocultar colunas",
      unhideColumns: "Reexibir colunas",
      filterByDate: "Filtrar por data",
      filterByName: "Filtrar por nome",
      searchLabel: "Buscar produto...",
      searchError:
        "Sua busca não retornou resultados. Tente mudar os filtros, a semana comercial ou o ano selecionados acima!",
      exportData: "Exportar dados",
      aidronForecast: "ForecastAidron",
      changeCommWeek: "Alterar semana comercial",
      editEstimated: "Editar previsão",
      finishRevision: "Finalizar revisão",
      revision: "Revisão",
      revised: "revised",
      send: "Enviar",
      reviewAndSend: "Revisar e enviar",
      typeYear: "Digite o ano...",
      forecastPeriod: "Período de predição",
      showHistory: "Exibir histórico",
      analyseAndIntegratePredictions: "Analisar e integrar as predições",
      analysePredictions: "Analisar as predições",
      saveRevision: "Salvar revisão",
      closeRevision: "Fechar revisão",
      cancel: "Cancelar",
      dontSave: "Descartar alterações",
      forecastRevision: "Revisão de predições",
      unsavedChangesModal: {
        heading: "Salvar alterações?",
        body: "Existem alterações não salvas. Deseja salvar antes de continuar?",
        promptBody:
          "Existem alterações não salvas. Tem certeza que deseja sair?",
      },
      dataSent: "Dados enviados com sucesso!",
      sent: "Enviado",
      submittedOn: "Enviado em",
      sendingPreviewModal: {
        heading: "Resumo do envio",
        alreadySentProducst:
          "Você selecionou itens que já foram enviados anteriormente, portanto estes foram retirados da lista abaixo.",
        body: "Revise os dados a serem enviados. Para editar, volte à página anterior.",
      },
      noSendingIntegrationModal: {
        heading: "Não há integração com seu sistema de planejamento!",
        body: "Você não possui integração com seu sistema de planejamento configurada. Geramos um novo arquivo de exportação para você com esta versão do Forecast.",
        downloadFile: "Baixar arquivo",
      },
      back: "Voltar",
      formerWeekModal: {
        heading: "Confirme a semana comercial",
        bodyEditing: "Você está analisando as predições geradas na semana ",
        bodySending:
          "As predições que você está prestes a enviar para o seu sistema foram geradas na semana ",
        body2: " de ",
        body3: ". Esta não é a predição mais recente.",
        question: "Como deseja continuar?",
        stay: "Continuar na semana ",
        goToCurrent: "Ir para a semana mais recente",
      },
      savedUnsent: "Salvo e não enviado",
      seeSendingStatus: "Ver status envio",
      sendingStatus: "Status de envio",
      productsAmount: "Nº de produtos",
      seeDetails: "Ver detalhes",
      hideDetails: "Ocultar detalhes",
      noCheckedProductsModal: {
        heading: "Nenhum produto selecionado!",
        body: "Para continuar, selecione um ou mais produtos da tabela.",
      },
      productRegistration: "Cadastro de produtos",
      remove: "Remover",
      hideProducts: "Ocultar produtos",
      showRegisteredProducts: "Ver produtos cadastrados",
      saveModifications: "Salvar alterações",
      errorModal: {
        heading: "Ops! Algo deu errado",
        checkFields: "Corrija os campos destacados e tente novamente.",
        changesNotSaved:
          "Não foi possível salvar suas alterações. Contate o administrador do sistema.",
      },
      typeValidName: "Insira um nome válido",
      savedModal: "As alterações foram salvas!",
      season: "Estação",
      seasonsRegistration: "Cadastro de estações",
      hideSeasons: "Ocultar estações",
      showRegisteredSeasons: "Ver estações cadastradas",
      and: "e",
      filterBySeason: "Filtrar por estação",
      predictions: "Predições",
      showSeasons: "Exibir estações",
      bulkImport: "Importar em lote",
      seasonsBulkImport: "Importar estações em lote",
      step: "Passo",
      importModal: {
        step1Instruction:
          "Baixe o template a ser preenchido conforme o exemplo nele contido.",
        step2Instruction:
          "Faça upload do template preenchido com os dados que você deseja cadastrar.",
        step3Instruction:
          "Verifique os dados a serem importados e confirme a importação.",
        error:
          "Houve um problema na importação dos dados. Verifique os dados contidos no arquivo e, se o problema persistir, contate o administrador do sistema.",
      },
      choseFile: "Escolher arquivo",
      fileUploaded: "Arquivo carregado!",
      confirm: "Confirmar",
      seasonNumber: "Número da estação",
      seasonNumbers: "Números das estações",
      seasonName: "Nome da estação",
      seasonNames: "Nomes das estações",
      seasonMonths: "Meses das estações",
      importSeasons: "Importar estações",
      importProducts: "Importar produtos",
      percentDifference: "Diferença percentual",
      productNumber: "Número do produto",
      example: "Exemplo",
      optional: "Opcional",
      consultOnSeasonsSheet:
        'Consultar número na aba "Números das estações" deste arquivo',
      new: "Novos(as)",
      seasons: "Estações",
      wereSaved: "foram salvos(as)",
      wereNotSaved: "NÃO foram salvos(as)",
      verifyData:
        "Verifique os dados inseridos e tente novamente. Se o problema persistir, contate o administrador do sistema.",
    },
  },
};

export { messages };
