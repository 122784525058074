import { Button } from "@aidron/aidron-ds";
import React, { useState } from "react";
import ModalTemplate from "../ModalTemplate/ModalTemplate";

function ButtonWithModal({
  label,
  icon,
  iconPosition,
  type,
  size,
  disabled,
  modalHeading,
  modalWidth,
  renderModalContent,
  className,
}) {
  const [showModal, setShowModal] = useState(false);

  function onCloseModal() {
    setShowModal(false);
  }
  return (
    <>
      <Button
        label={label}
        onClick={() => setShowModal(true)}
        icon={icon}
        position={iconPosition ? iconPosition : "right"}
        type={type}
        size={size}
        disabled={disabled}
        className={className}
      />
      {showModal ? (
        <ModalTemplate
          width={modalWidth ? modalWidth : "900px"}
          heading={modalHeading ? modalHeading : " "}
          closingFunction={onCloseModal}
        >
          {renderModalContent({ closeModal: onCloseModal })}
        </ModalTemplate>
      ) : (
        <></>
      )}
    </>
  );
}

export default ButtonWithModal;
