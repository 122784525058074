import React, { useState } from "react";
import { Card, Button } from "@aidron/aidron-ds";

import HierarchyColumnsFilter from "../HierarchyColumnsFilter/HierarchyColumnsFilter";
import ArrowModalButton from "../ArrowModalButton/ArrowModalButton";
import ButtonWithModal from "../ButtonWithModal/ButtonWithModal";
import HierarchyFilters from "./HierarchyFilters/HierarchyFilters";

export default function TableOptions({
  checkedProducts,
  selectedHierarchyIndexes,
  setSelectedHierarchyIndexes,
  freezeColumns,
  setFreezeColumns,
  productIds,
  selectedProductIds,
  setSelectedProductIds,
  renderHistoricFilter,
  renderGraphicAnalysis,
}) {
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <ArrowModalButton
            label="Histórico de Vendas"
            renderModalContent={renderHistoricFilter}
          />
          <div className="d-flex align-items-center">
            {renderGraphicAnalysis && (
              <ButtonWithModal
                label="Analisar produto"
                type="outlined"
                size="lg"
                disabled={!checkedProducts.length}
                renderModalContent={renderGraphicAnalysis}
                className="me-3"
              />
            )}
            <Button
              onClick={() => setShowColumnFilters(!showColumnFilters)}
              label=""
              icon="layout"
              type={showColumnFilters ? "filled" : "outlined"}
              size="lg"
              className="me-3"
            />
            <Button
              onClick={() => setShowFilters(!showFilters)}
              label=""
              icon="filter"
              type={showFilters ? "filled" : "outlined"}
              size="lg"
              className="me-3"
            />
          </div>
        </div>
      </Card>
      {showColumnFilters && (
        <HierarchyColumnsFilter
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
          freezeColumns={freezeColumns}
          setFreezeColumns={setFreezeColumns}
        />
      )}
      {showFilters && (
        <HierarchyFilters
          productIds={productIds}
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
        />
      )}
    </>
  );
}
