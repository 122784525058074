import React from "react";
import { Text, Icon } from "@aidron/aidron-ds";

export default function PasswordValidation({
  newPassword,
  hasCapitalLetter,
  hasLowercaseLetter,
  hasNumber,
  hasSpecialCharacter,
  hasMinimumLength,
  arePasswordsEqual,
}) {
  const passwordRequirements = [
    {
      name: "Uma letra maiúscula",
      testFunction: hasCapitalLetter,
    },
    {
      name: "Uma letra minúscula",
      testFunction: hasLowercaseLetter,
    },
    {
      name: "Um número",
      testFunction: hasNumber,
    },
    {
      name: "Um caracter especial",
      testFunction: hasSpecialCharacter,
    },
    {
      name: "6 dígitos",
      testFunction: hasMinimumLength,
    },
  ];

  function defineIcon(testFunction) {
    return testFunction(newPassword.first) ? "check-circle" : "x-circle";
  }

  function defineIconColor(testFunction) {
    return testFunction(newPassword.first)
      ? "color-green-dark"
      : "color-red-medium";
  }

  function defineTextColor(testFunction) {
    return testFunction(newPassword.first) ? "green-color" : "color-red";
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        <Icon
          icon={defineIcon(arePasswordsEqual)}
          color={defineIconColor(arePasswordsEqual)}
          className="me-2"
        />
        <Text className={defineTextColor(arePasswordsEqual)}>
          As senhas devem ser idênticas
        </Text>
      </div>
      <Text size="xs" className="mt-2">
        A senha deve conter ao menos:
      </Text>
      <div className="d-flex flex-column">
        {passwordRequirements.map((requirement, index) => (
          <div className="d-flex align-items-center" key={index}>
            <Icon
              icon={defineIcon(requirement.testFunction)}
              color={defineIconColor(requirement.testFunction)}
              className="me-2"
            />
            <Text className={defineTextColor(requirement.testFunction)}>
              {requirement.name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}
