import styled from "styled-components";

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const ContentTableAndTotais = styled.div`
  display: grid;
  grid-template-rows: 1fr repeat(2, auto);

  .contentText {
    display: grid;
    grid-template-columns: 350px auto;
    gap: 10px;
  };

  th {
    font-size: 16px !important;
  }
`;



export {
  Section,
  ContentTableAndTotais,
};
