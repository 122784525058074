import React from "react";
import { Button } from "@aidron/aidron-ds";

import * as S from "./FilterButton.style";

import CustomText from "../../../StyledComponents/CustomText/CustomText.style";

export default function FilterButton({ showFilters, setShowFilters, appliedFiltersCount }) {
  return (
    <S.Container>
      <Button
        onClick={() => setShowFilters(!showFilters)}
        label=""
        icon="filter"
        type={showFilters ? "filled" : "outlined"}
        size="lg"
      />
      {appliedFiltersCount > 0 ? (
        <S.NumberBadge>
          <CustomText color="white" weight="bold" textAlign="center">
            {appliedFiltersCount}
          </CustomText>
        </S.NumberBadge>
      ) : (
        <></>
      )}
    </S.Container>
  );
}
