import React, { useState, useContext, useEffect } from "react";
import { Card } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getForecasts } from "../../../services/ForecastService";
import { getOpportunitiesCount } from "../../../services/OpportunityService";

import * as S from "./CheckoutOuterCard.style";

import OuterCardHeading from "../OuterCardHeading/OuterCardHeading";
import InnerCheckoutCard from "./InnerCheckoutCard/InnerCheckoutCard";

export default function CheckoutOuterCard() {
  const { userInfo } = useContext(CompanyContext);
  const companyId = userInfo.company.id;

  const [counter, setCounter] = useState({});

  useEffect(() => {
    fetchOpportunities();
  }, []);

  async function fetchOpportunities() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "opportunities");
    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const status = ["checkout"];
    const result = await getOpportunitiesCount(token, latestForecastId, status);
    setCounter(result);
  }

  function getCount(type) {
    const count = counter.counter?.find(obj => obj.type === type)?.count;
    return count ? count : 0;
  }

  return (
    <S.OuterWrapper>
      <Card style={{ height: "100%" }}>
        <OuterCardHeading title="CHECKOUT DE AÇÕES" text="Ações marcadas para ajuste" />
        <S.InnerWrapper>
          <InnerCheckoutCard heading="VENDA ADICIONAL" number={companyId === 4 ? 12 : getCount("sale")} link="" />
          <InnerCheckoutCard heading="OTIMIZAÇÃO DE ESTOQUE" number={companyId === 4 ? 8 : getCount("stock")} link="" />
          <InnerCheckoutCard heading="OPORTUNIDADES DE LOJAS" number={companyId === 4 ? 42 : "Em breve"} link="" />
        </S.InnerWrapper>
      </Card>
    </S.OuterWrapper>
  );
}
