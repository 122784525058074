import React from "react";

import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function CardHeading({ heading }) {
  return (
    <CustomHeading size="sm" textAlign="center" color="mediumGrey" margin="0 0 1.5rem 0">
      {heading}
    </CustomHeading>
  );
}
