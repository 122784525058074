import React from "react";
import { Text } from "@aidron/aidron-ds";

import { toNDecimalsString } from "../../../../../../utils/utils";

import * as S from "./InformationsSection.style";

import TextAndLabelView from "../../TextAndLabelView/TextAndLabelView";
import ButtonWithModal from "../../../../../../components/ButtonWithModal/ButtonWithModal";
import OpportunityCarousel from "./OpportunityCarousel/OpportunityCarousel";
import ProductAnalysisButton from "./ProductAnalysisButton/ProductAnalysisButton";

export default function InformationsSection({ opportunity, opportunities, getOpportunityDetails }) {
  const { id, productId, value, percent, text, type1 } = opportunity;
  const percentLabel = getOpportunityDetails(type1).title;

  const otherOpportunities = opportunities?.filter(opportunity => opportunity.productId === productId && opportunity.id !== id);

  const otherOpportunitiesText =
    otherOpportunities?.length === 1 ? "Este produto possui outra oportunidade" : "Este produto possui outras oportunidades";

  const buttonLabel = otherOpportunities?.length === 1 ? "Analisar Oportunidade" : "Analisar Oportunidades";

  return (
    <S.OuterContainer>
      <S.IndexesSection>
        <TextAndLabelView
          label={percentLabel}
          heading={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
        />
        <TextAndLabelView label="Tamanho da Oportunidade" heading={`R$ ${toNDecimalsString(value, 0)}`} textSize="lg" />
      </S.IndexesSection>
      <S.TextWrapper>
        <S.ExplanationText>{text.replace(/([.!?])\s/g, "$1\n")}</S.ExplanationText>
      </S.TextWrapper>

      <ProductAnalysisButton productId={productId} />

      {opportunities && otherOpportunities.length > 0 && (
        <S.OuterButtonContainer>
          <S.InnerButtonContainer>
            <Text>{otherOpportunitiesText}</Text>
            <ButtonWithModal
              label={buttonLabel}
              type="outlined"
              modalWidth="90%"
              icon="arrow-up-right"
              renderModalContent={() => (
                <OpportunityCarousel otherOpportunities={otherOpportunities} getOpportunityDetails={getOpportunityDetails} />
              )}
            />
          </S.InnerButtonContainer>
        </S.OuterButtonContainer>
      )}
    </S.OuterContainer>
  );
}
