import React from "react";
import { Button } from "@aidron/aidron-ds";

export default function Carousel({ slideNumber, children }) {
  const slideNumberArray = Array.from({ length: slideNumber }, (_, i) => i);

  return (
    <div id="chartModalCarousel" className="carousel carousel-dark" data-bs-interval="false">
      <div className="carousel-indicators">
        {slideNumberArray.map((product, index) => (
          <button
            type="button"
            data-bs-target="#chartModalCarousel"
            data-bs-slide-to={index}
            className={`${index === 0 ? "active disabled-carousel-indicators" : "disabled-carousel-indicators"} mx-1`}
            key={index}
          />
        ))}
      </div>
      <div className="carousel-inner mt-3 mt-lg-0 overflow-visible">{children}</div>
      <div
        className="carousel-control-prev border-0 controls-style"
        type="button"
        data-bs-target="#chartModalCarousel"
        data-bs-slide="prev"
      >
        <Button label="" onClick={() => {}} type="outlined" icon="chevron-left" size="lg" disabled={slideNumber === 1} />
        <span className="visually-hidden">Previous</span>
      </div>
      <div
        className="carousel-control-next border-0 controls-style"
        type="button"
        data-bs-target="#chartModalCarousel"
        data-bs-slide="next"
      >
        <Button label="" onClick={() => {}} type="outlined" icon="chevron-right" size="lg" disabled={slideNumber === 1} />
        <span className="visually-hidden">Next</span>
      </div>
    </div>
  );
}
