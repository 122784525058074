import { Button } from "@aidron/aidron-ds";
import { ReactComponent as Stock } from "../../../../assets/image/in-stock.svg";
import { ReactComponent as Sale } from "../../../../assets/image/profits.svg";

import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 30% repeat(3, 1fr) 10%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Item = styled.div`
  padding: 0 20px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  height: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ItemCheck = styled.div`
  padding-top: 10px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  .check.check-outer {
    height: auto;
    padding: 10px;
  }
  span {
    color: #919191;
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
  }
`;

const ItemImage = styled.div`
  padding: 0 20px;
  text-align: ${({ textAlign }) => (textAlign ? "center" : "default")};
  border-right: ${({ border }) => (border ? "solid 1px #919191" : "none")};
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  align-items: center;
`;

const ButtonAccordion = styled(Button)`
  position: absolute;
  bottom: -10px;
  left: 41%;
  width: 40px;
  height: 24px;
`;

const IconStyling = `
  width: 50px;
  height: 50px;
  fill: #f26f21;
  @media (max-width: 980px) {
    display: none;
  }
`;

const StockIcon = styled(Stock)`
  ${IconStyling}
`;

const SaleIcon = styled(Sale)`
  ${IconStyling}
`;

export { Container, Item, ItemCheck, ItemImage, ButtonAccordion, StockIcon, SaleIcon };
