import React, { useContext } from "react";
import { Card } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../../../../templates/context/CompanyContext";
import { getMonthAbbreviation, sumColumn } from "../../../../../../utils/utils";

import AreaRangeChart from "../../../../../../components/AreaRangeChart/AreaRangeChart";
import CardHeading from "../../../CardHeading/CardHeading";

export default function MultipleProductsChart({ predictions, monthColumns, heading }) {
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  const xLabels = monthColumns.map(
    monthColumn => `${getMonthAbbreviation(monthColumn.month)}/${monthColumn.year.toString().slice(-2)}`
  );

  function defineAllRanges() {
    const values = monthColumns.map(month => {
      const monthPredictions = predictions.filter(
        prediction => prediction.month === month.month && prediction.year === month.year
      );
      const sumICMax = sumColumn(monthPredictions, "confidenceIntervalMax");
      const sumICMin = sumColumn(monthPredictions, "confidenceIntervalMin");
      return [Number(sumICMin), Number(sumICMax)];
    });
    return values;
  }

  function defineFutureMonthsData() {
    const columnLabels = [
      { label: "Forecast IA", name: "aidronPrediction" },
      { label: "Plano Atual", name: "clientPrediction" },
    ];
    const columnColors = ["#f58b4d", "#BFBFBF"];

    return columnLabels.map((columnLabel, i) => {
      const values = monthColumns.map(month => {
        let columnLabelName = columnLabel.name;
        const monthPredictions = predictions.filter(
          prediction => prediction.month === month.month && prediction.year === month.year
        );
        const value = sumColumn(monthPredictions, columnLabelName);
        return value ? value : null;
      });
      return {
        label: columnLabel.label,
        color: columnColors[i],
        values: values,
        marker: true,
        lineStyle: "shortDash",
      };
    });
  }

  return (
    <Card style={{ padding: "10px 0px" }}>
      <CardHeading heading={heading} />
      <AreaRangeChart
        xLabels={xLabels}
        ranges={defineAllRanges()}
        lines={defineFutureMonthsData()}
        colors={["#f58b4d", "#BFBFBF", "#00BECB"]}
        unit={unit}
      />
    </Card>
  );
}
