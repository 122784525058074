import React from "react";

import * as S from "./CheckItem.style";

import ModalCheck from "../ModalCheck/ModalCheck";
import ActionRadio from "./ActionRadio/ActionRadio";

export default function CheckItem() {
  return (
    <S.ItemCheck textAlign>
      <span>Ações...</span>
      <ModalCheck renderModalContent={({ closeModal }) => <ActionRadio onClose={closeModal} />} />
    </S.ItemCheck>
  );
}
