import styled from "styled-components";

const OuterWrapper = styled.div`
  width: 100%;
  min-width: 239px;
  margin-bottom: 20px;

  @media (min-width: 905px) {
    width: 30%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Icon = styled.img`
  width: 115px;
`;

export { OuterWrapper, InnerWrapper, ContentContainer, Icon };
