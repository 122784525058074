import React from "react";
import { Heading, Text } from "@aidron/aidron-ds";
import * as S from "./TextAndLabelView.style";

export default function TextAndLabelView({ label, labelSize, text, textSize }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <Text className={labelSize ? labelSize : ""}>{label}</Text>
      {textSize ? <Heading size={textSize}>{text}</Heading> : <S.p>{text}</S.p>}
    </div>
  );
}
