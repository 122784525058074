import React, { useContext } from "react";
import { CompanyContext } from "../../templates/context/CompanyContext";

import * as S from "./Home.style";

import Default from "../../components/Default/Default";
import OpportunityOuterCard from "./OpportunityOuterCard/OpportunityOuterCard";
import PlanOuterCard from "./PlanOuterCard/PlanOuterCard";
import CheckoutOuterCard from "./CheckoutOuterCard/CheckoutOuterCard";
import ActionOuterCard from "./ActionOuterCard/ActionOuterCard";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function Home() {
  const { userInfo } = useContext(CompanyContext);

  return (
    <Default>
      <PageHeading size="lg">{userInfo.company.name}</PageHeading>
      <S.Wrapper>
        <OpportunityOuterCard />
        <PlanOuterCard />
        <ActionOuterCard />
        <CheckoutOuterCard />
      </S.Wrapper>
    </Default>
  );
}
