import React, { useContext } from "react";
import { CompanyContext } from "../../../../templates/context/CompanyContext";
import { accuracyColumnsCurrentMonth } from "../../../../utils/tableColumns";

import HierarchyHeaderCells from "../../../../components/HierarchyHeaderCells/HierarchyHeaderCells";
import MonthSortByButton from "../../../../components/MonthSortByButton/MonthSortByButton";
import DifSortByButton from "../../../../components/DifSortByButton/DifSortByButton";
import ColumnLabelCell from "../../../../components/ColumnLabelCell/ColumnLabelCell";

export default function AccuracyColumnLabelsRow({
  freezeColumns,
  sumPreviousColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  monthColumns,
  monthHeaderCellHeight,
  sortRowsBy,
  setSortRowsBy,
  defineHierarchyToDisplay,
  setHierarchyCellHeight,
  currentMonth,
  pastPeriodColumns,
}) {
  const { userInfo } = useContext(CompanyContext);
  return (
    <>
      <tr>
        <td
          className={`background-color-white padding-sides-030rem z-5 position-sticky cell-bottom-border ${
            freezeColumns ? "start-0" : ""
          }`}
          style={{
            top: `${monthHeaderCellHeight}px`,
          }}
        />
        <HierarchyHeaderCells
          rowSpan="1"
          freezeColumns={freezeColumns}
          sumPreviousColumnsWidth={sumPreviousColumnsWidth}
          sortRowsBy={sortRowsBy}
          setSortRowsBy={setSortRowsBy}
          showSeasonColumn={showSeasonColumn}
          evaluateLastCategoryColumn={evaluateLastCategoryColumn}
          monthHeaderCellHeight={monthHeaderCellHeight}
          setHierarchyCellHeight={setHierarchyCellHeight}
          defineHierarchyToDisplay={defineHierarchyToDisplay}
          columns={monthColumns}
        />
        {monthColumns.map((month, index) => (
          <React.Fragment key={index}>
            {pastPeriodColumns.map((column, i) => (
              <ColumnLabelCell
                column={column}
                upperCellsHeight={monthHeaderCellHeight}
                cellLeftBorder={index !== 0 && i === 0}
                renderSortByButton={
                  column.type === "dif%" ? (
                    <DifSortByButton
                      column={column}
                      monthObj={{ ...month, months: [month.month] }}
                      sortRowsBy={sortRowsBy}
                      setSortRowsBy={setSortRowsBy}
                    />
                  ) : (
                    <MonthSortByButton
                      column={column}
                      monthObj={{ ...month, months: [month.month] }}
                      sortRowsBy={sortRowsBy}
                      setSortRowsBy={setSortRowsBy}
                    />
                  )
                }
                key={i}
              />
            ))}
          </React.Fragment>
        ))}
        {currentMonth ? (
          accuracyColumnsCurrentMonth({
            companyName: userInfo.company.name,
          }).map((column, index) => (
            <ColumnLabelCell
              column={column}
              upperCellsHeight={monthHeaderCellHeight}
              cellLeftBorder={index === 0}
              renderSortByButton={
                column.type === "dif%" ? (
                  <DifSortByButton
                    column={column}
                    monthObj={{ ...currentMonth, months: [currentMonth.month] }}
                    sortRowsBy={sortRowsBy}
                    setSortRowsBy={setSortRowsBy}
                  />
                ) : (
                  <MonthSortByButton
                    column={column}
                    monthObj={{ ...currentMonth, months: [currentMonth.month] }}
                    sortRowsBy={sortRowsBy}
                    setSortRowsBy={setSortRowsBy}
                  />
                )
              }
              key={index}
            />
          ))
        ) : (
          <></>
        )}
      </tr>
    </>
  );
}
